import i18n from '../i18n/index'

export default {

  data: () => ({
    dictionary: {

      unitType: {
        PIECE: {
          _deprecated: 'шт',
          code: '796',
        },
        KILOGRAM: {
          _deprecated: 'кг',
          code: '166',
        },
        GRAM: {
          _deprecated: 'г',
          code: '163',
        },
        TON: {
          _deprecated: 'тонн.',
          code: '168',
        },
        DECITONNE: {
          _deprecated: 'центнер',
          code: '206',
        },
        TON_KILOMETRE: {
          _deprecated: 'тоннокилометр',
          code: '449',
        },
        LITER: {
          _deprecated: 'литр',
          code: '112',
        },
        MILLILITER: {
          _deprecated: 'мл',
          code: '111',
        },
        METER: {
          _deprecated: 'м',
          code: '006',
        },
        SQUARE_METER: {
          _deprecated: 'м2',
          code: '055',
        },
        CUBIC_METER: {
          _deprecated: 'м3',
          code: '113',
        },
        HECTARE: {
          _deprecated: 'га',
          code: '059',
        },
        RUNNING_METER: {
          _deprecated: 'м.пог.',
          code: '018',
        },
        KILOMETRE: {
          _deprecated: 'км',
          code: '008',
        },
        BOTTLE: {
          _deprecated: 'бутылка',
          code: '868',
        },
        PACK: {
          _deprecated: 'пачка',
          code: '728',
        },
        PACKAGE: {
          _deprecated: 'упак.',
          code: '778',
        },
        KIT: {
          _deprecated: 'набор',
          code: '704',
        },
        SET: {
          _deprecated: 'компл.',
          code: '839',
        },
        PAIR: {
          _deprecated: 'пара',
          code: '715',
        },
        ROLL: {
          _deprecated: 'рулон',
          code: '736',
        },
        ONE_SERVICE: {
          _deprecated: 'одн.усл.',
          code: '5114',
        },
        MINUTE: {
          _deprecated: 'мин.',
          code: '355',
        },
        HOUR: {
          _deprecated: 'час',
          code: '356',
        },
        DAY: {
          _deprecated: 'сутки',
          code: '5208',
        },
        MONTH: {
          _deprecated: 'месяц',
          code: '362',
        },
        YEAR: {
          _deprecated: 'год',
          code: '366',
        },
        KILOWATT_HOUR: {
          _deprecated: 'кВт/ч',
          code: '245',
        },
        DOSE: {
          _deprecated: 'доза',
          code: '639',
        },
        SOWING_UNIT: {
          _deprecated: 'посевная единица',
          code: '6023',
        },
      },

      operationType: {
        OPERATION_SELL: {
          _deprecated: 'Продажа',
          icon: 'mdi-account-cash',
          negative: false,
          order: 1,
        },
        OPERATION_SELL_RETURN: {
          _deprecated: 'Возврат',
          icon: 'mdi-cash-refund',
          negative: true,
          order: 2,
        },
        OPERATION_BUY: {
          _deprecated: 'Покупка',
          icon: 'mdi-cash-multiple',
          negative: true,
          order: 3,
        },
        OPERATION_BUY_RETURN: {
          _deprecated: 'Возврат покупки',
          icon: 'mdi-cash-multiple',
          negative: false,
          order: 4,
        },
        MONEY_PLACEMENT_DEPOSIT: {
          _deprecated: 'Внесение денег',
          icon: 'mdi-cash-plus',
          negative: false,
          order: 5,
        },
        MONEY_PLACEMENT_WITHDRAWAL: {
          _deprecated: 'Изъятие денег',
          icon: 'mdi-cash-minus',
          negative: true,
          order: 6,
        },
      },

      paymentType: {
        PAYMENT_CASH: {
          _deprecated: 'Наличные',
          _deprecated_short: 'нал.',
          order: 1,
        },
        PAYMENT_CARD: {
          _deprecated: 'Карта',
          _deprecated_short: 'карт.',
          order: 2,
        },
        PAYMENT_MOBILE: {
          _deprecated: 'Мобильные',
          _deprecated_short: 'моб.',
          order: 3,
        },
        PAYMENT_CREDIT: {
          _deprecated: 'Кредит',
          _deprecated_short: 'кред.',
          order: 4,
        },
        PAYMENT_TARE: {
          _deprecated: 'Тара',
          _deprecated_short: 'тар.',
          order: 5,
        },
      },

      discountMarkupType: {
        DISCOUNT_PERCENTAGE: {
          _deprecated: 'Скидка в процентах',
          _deprecated_short: 'Скидка',
          suffix: '%',
        },
        DISCOUNT_CURRENCY: {
          _deprecated: 'Скидка в тенге',
          _deprecated_short: 'Скидка',
          suffix: '₸',
        },
        MARKUP_PERCENTAGE: {
          _deprecated: 'Наценка в процентах',
          _deprecated_short: 'Наценка',
          suffix: '%',
        },
        MARKUP_CURRENCY: {
          _deprecated: 'Наценка в тенге',
          _deprecated_short: 'Наценка',
          suffix: '₸',
        },
      },

      domainType: {
        DOMAIN_TRADING: {
          _deprecated: 'Торговая сфера',
          code: 0,
        },
        DOMAIN_SERVICES: {
          _deprecated: 'Сфера услуг',
          code: 1,
        },
        DOMAIN_GASOIL: {
          _deprecated: 'Сфера обеспечения нефтепродуктами',
          code: 2,
        },
        DOMAIN_HOTELS: {
          _deprecated: 'Отели и рестораны',
          code: 3,
        },
        DOMAIN_TAXI: {
          _deprecated: 'Такси',
          code: 4,
        },
        DOMAIN_PARKING: {
          _deprecated: 'Стоянки',
          code: 5,
        },
      },

      taxType: {
        TAX_12: {
          rate: 12,
        },
        TAX_8: {
          rate: 8,
        },
        TAX_0: {
          rate: 0,
        },
        TAX_NONE: {
          rate: -1,
        },
      },

      taxationType: {
        STS: {
          _deprecated: 'Специальный налоговый режим на основе упрощенной декларации',
          code: 100,
        },
        RTS: {
          _deprecated: 'Общеустановленный режим налогообложения',
          code: 101,
        },
        TRFF: {
          _deprecated: 'Специальный налоговый режим для крестьянских или фермерских хозяйств',
          code: 102,
        },
        TRBP: {
          _deprecated: 'Специальный налоговый режим на основе патента',
          code: 103,
        },
      },

      localeType: {
        ru: {
          title: 'Русский',
        },
        kk: {
          title: 'Қазақша',
        },
      },

      exciseStampType: {
        DISABLED: {
          _deprecated: '-',
        },
        CIGARETTES: {
          _deprecated: 'Сигареты',
        },
        GROUP_A: {
          _deprecated: 'Обувь, лекарства',
        },
      },

      roleType: {
        OWNER: {
          _deprecated: 'Владелец',
        },
        CASHIER: {
          _deprecated: 'Кассир',
        },
      },

    },
  }),

  computed: {
    unitTypes() {
      return Object.entries(this.dictionary.unitType).map(item => ({ title: i18n.t(`unitType.${item[0]}`), value: item[0], code: item[1].code }))
    },

    operationTypes() {
      return Object.entries(this.dictionary.operationType).map(item => ({
        title: i18n.t(`operationType.${item[0]}`), icon: item[1].icon, negative: item[1].negative, value: item[0],
      }))
    },

    discountMarkupTypes() {
      return Object.entries(this.dictionary.discountMarkupType).map(item => ({
        title: i18n.t(`discountMarkupType.${item[0]}`), suffix: item[1].suffix, value: item[0],
      }))
    },

    domainTypes() {
      return Object.entries(this.dictionary.domainType).map(item => ({
        title: i18n.t(`domainType.${item[0]}`), value: item[0],
      }))
    },

    taxationTypes() {
      return Object.entries(this.dictionary.taxationType).map(item => ({
        title: i18n.t(`taxationType.${item[0]}`), value: item[0],
      }))
    },

    taxTypes() {
      return Object.entries(this.dictionary.taxType).map(item => ({
        title: i18n.t(`taxType.${item[0]}`), rate: item[1].rate, value: item[0],
      }))
    },

    localeTypes() {
      return Object.entries(this.dictionary.localeType).map(item => ({
        title: item[1].title, value: item[0],
      }))
    },

    exciseStampTypes() {
      return Object.entries(this.dictionary.exciseStampType).map(item => ({ title: i18n.t(`exciseStampType.${item[0]}`), value: item[0] }))
    },

    roleTypes() {
      return Object.entries(this.dictionary.roleType).map(item => ({ title: i18n.t(`role.${item[0]}`), value: item[0] }))
    },
  },
}
