<template lang="pug">
div.mt-5.mb-3.d-flex.justify-center(v-if="show")
  span.d-flex.align-center.mr-2(v-if="$vuetify.breakpoint.smAndUp")
    v-btn(icon :disabled="leftScrollDisabled" @click="scrollLeft()")
      v-icon mdi-menu-left
  div(id="stories")
  span.d-flex.align-center.ml-2(v-if="$vuetify.breakpoint.smAndUp")
    v-btn(icon :disabled="rightScrollDisabled" @click="scrollRight()")
      v-icon mdi-menu-right
div.mt-4(v-else)
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../../i18n'
import { Zuck } from '../../utils/lib/stories/index'
import '../../utils/lib/stories/style.css'
// import defaultStories from '../../utils/lib/stories/default-stories.json'

export default {
  data: () => ({
    leftScrollDisabled: true,
    rightScrollDisabled: false,
    show: true,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
    }),

    locale() {
      return getLocale()
    },
  },

  mounted() {
    const options = {
      skin: 'Snapgram',
      backNative: false,
      previousTap: true,
      autoFullScreen: false,
      avatars: true,
      paginationArrows: false,
      list: false,
      cubeEffect: true,
      localStorage: true,
      callbacks: {
        onOpen(storyId, callback) {
          callback()
        },
        onView: (story) => {
          const items = document.querySelectorAll('.viewing .zuck-link')
          items.forEach(item => {
            item.addEventListener('click', (evt) => this.handleLink(story, evt))
          }, this)

          this.analyticsLogEvent({ eventName: 're_dash_story_viewed', eventProperties: { story_name: story.id, story_date_publication: this.$moment(story.lastUpdated).format('DD-MM-YYYY') } })
        },
        onEnd(storyId, callback) {
          callback()
        },
        onClose(storyId, callback) {
          callback()
        },
        onNextItem(storyId, nextStoryId, callback) {
          callback()
        },
        onNavigateItem(storyId, nextStoryId, callback) {
          callback()
        },
      },
    }

    // const element = document.querySelector('#stories')
    // const zuckjs = Zuck(element, options)
    // this.parseStories(zuckjs, defaultStories[this.locale])

    this.firebaseRemoteConfigGetString('STORIES').then((result) => {
      try {
        const storiesJSON = JSON.parse(result)[this.locale]
        this.show = true
        const element = document.querySelector('#stories')
        const zuckjs = Zuck(element, options)
        this.parseStories(zuckjs, storiesJSON)
      } catch (error) {
        this.show = false
      }
    })
  },

  methods: {
    ...mapActions({
      firebaseRemoteConfigGetString: 'tools/firebaseRemoteConfigGetString',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    parseStories(zuckjs, storiesJSON) {
      storiesJSON.reverse().forEach((story) => {
        zuckjs.add(story)
      })
      const storiesElement = document.getElementById('stories')
      storiesElement.addEventListener('scroll', this.onScroll)
    },

    handleLink(story, event) {
      const link = event.target.getAttribute('data-link')
      this.$openLink(link, '_system')
      document.querySelector('#zuck-modal').modal.close()

      this.analyticsLogEvent({ eventName: 're_dash_story_link_clicked', eventProperties: { story_name: story.id, story_date_publication: this.$moment(story.lastUpdated).format('DD-MM-YYYY'), story_link: link } })
    },

    scrollRight() {
      const storiesElement = document.getElementById('stories')
      storiesElement.scrollLeft += 97
    },

    scrollLeft() {
      const storiesElement = document.getElementById('stories')
      storiesElement.scrollLeft -= 97
    },

    onScroll(event) {
      if (event.target.scrollLeft === 0) {
        this.leftScrollDisabled = true
        this.rightScrollDisabled = false
      } else if (event.target.scrollLeft === event.target.scrollWidth - event.target.clientWidth) {
        this.leftScrollDisabled = false
        this.rightScrollDisabled = true
      } else {
        this.leftScrollDisabled = false
        this.rightScrollDisabled = false
      }
    },
  },
}
</script>
<style lang="stylus">
</style>
