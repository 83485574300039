import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line import/no-cycle
import auth from './modules/auth'
import cashRegisters from './modules/cashRegisters'
import positions from './modules/positions'
import ffbPos from './modules/ffbPos'
import kaspiShop from './modules/kaspiShop'
import m4bank from './modules/m4bank'
import moyUchet from './modules/moyUchet'
import printer from './modules/printer'
import tools from './modules/tools'
import analytics from './modules/analytics'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    cashRegisters,
    positions,
    ffbPos,
    kaspiShop,
    m4bank,
    moyUchet,
    printer,
    tools,
    analytics,
  },
})
