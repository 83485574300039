<template lang="pug">
v-app
  v-app-bar(app dark dense flat)
    v-btn(icon @click="$router.push('/services')")
      v-icon mdi-arrow-left
    v-toolbar-title {{ $t("ТИС") }}

  v-main(flat)
    v-container.pt-0(style="max-width: 450px")
      v-row
        v-col.pa-0.pt-sm-3(align="center")
          v-img(:src="`/static/tcis-business-banner-${locale}.png`" width="100%" eager)

      v-row
        v-col
          .display-1.my-2 {{ $t("трёхкомпонентная_интегрированная_система_тис") }}
          .subtitle-1.my-2 {{ $t("увеличивайте_порог_по_ндс_для_вашего_бизнеса") }}

          .headline.mb-2.mt-8 {{ $t("что_такое_тис") }}
          .subtitle-1.my-2 {{ $t("тис_это_трехкомпонентная_интегрированная_система_включающая_в_себя_pos_терминал_freedom_dotdotdot") }}

          .headline.mb-2.mt-8 {{ $t("что_дает_тис") }}
          .subtitle-1.my-2 {{ $t("повышение_порога_по_доходу_и_НДС_по_безналичным_платежам_для_ИП_dotdotdot") }}

          .headline.mb-2.mt-8 {{ $t("стоимость") }}
          .subtitle-1.my-2 {{ $t("годовая_стоимость_тарифа__139_000_в_год_в_эту_стоимость_входит_учеттис_онлайн_касса_и_услуга_офд_регистрация_под_ключ") }}

          .headline.mb-2.mt-8 {{ $t("преимущества_тис_от_rekassa") }}
          ul
            li.subtitle-1 {{ $t("бесплатное_использование_офд_навсегда") }}
            li.subtitle-1 {{ $t("бесплатное_использование_кассы_навсегда") }}
            li.subtitle-1 {{ $t("бесплатное_использование_учетной_системы_навсегда") }}
            li.subtitle-1 {{ $t("единый_платеж_за_все_компоненты_офд_тис_учетная_система") }}
            li.subtitle-1 {{ $t("единая_техническая_поддержка_для_кассы_офд_и_тис") }}

          .headline.mb-2.mt-8 {{ $t("как_подключить_тис") }}
          .subtitle-1.my-2 {{ $t("проверьте_корректность_номера_телефона_в_форме_ниже_и_нажмите_на_кнопку") }}

      v-row
        v-col
          v-form(ref="form")
            v-text-field(:label="$t('имя')" v-model="name" @input="nameError = null" :error-messages="nameError" filled clearable)
            v-text-field(:label="$t('номер_телефона')" v-model="phoneNumber" @input="phoneNumberError = null" :error-messages="phoneNumberError" v-mask="'+7 (###) ###-##-##'" placeholder="+7 (###) ###-##-##" type="tel" filled clearable)

            v-btn.mt-4(color="primary" block @click="submitApplication()") {{ $t("оставить_заявку") }}

</template>
<script>
import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import { getLocale } from '../../i18n'

export default {

  data: () => ({
    name: null,
    nameError: null,
    phoneNumber: null,
    phoneNumberError: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
    }),

    locale() {
      return getLocale()
    },
  },

  created() {
    this.phoneNumber = this.user.phone
  },

  methods: {
    ...mapActions({
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    submitApplication() {
      if (this.name === null || this.name.length < 3) {
        this.nameError = this.$t('введите_имя')
        return
      }
      if (this.phoneNumber?.length !== 18) {
        this.phoneNumberError = this.$t('введите_номер_телефона')
        return
      }

      const url = 'https://api.sheetson.com/v2/sheets/tcis'
      const data = {
        name: this.name,
        phone: this.phoneNumber.replace(/\D/g, ''),
        date: this.$moment().format('DD-MM-YYYY HH:mm:ss'),
      }
      if (this.cashRegister.status !== 'TRIAL') {
        data.iin = this.organization.businessId
        data.znm = this.cashRegister.serialNumber
        data.city = this.cashRegister.pos.address
      }
      const config = {
        headers: {
          Authorization: 'Bearer WL6r-WEd2IPRoiBnA80pMaQYA1UwLPOtJlKx_hOYOGs7UOK2kgVd_v2ErE0',
          'X-Spreadsheet-Id': process.env.VUE_APP_TEST === 'true' ? '1qy8t9qIDP9LC0FSdjUdE362jQBrFJNJCMaPpmcnaEpc' : '1p8nYxM7xv5jEvDxNQ4L39hjsINvyjqxGO6qBIQFUc0w',
          'Content-Type': 'application/json',
        },
      }

      axios.post(url, data, config)
        .then((response) => {
          console.debug(response.data)
        })
        .catch((error) => {
          console.error(error)
        })

      this.$router.push('/services')
      this.showConfirm({
        title: this.$t('ваша_заявка_успешна_принята'),
        resolveText: this.$t('ok'),
      }).then(() => {})
        .catch(() => {})
      this.analyticsLogEvent({ eventName: 'tcis_form_submitted' })
    },
  },
}
</script>

<style lang="stylus">
</style>
