<template lang="pug">
section#image-carousel.splide
  .splide__track
    ul.splide__list
      li.splide__slide(v-for="(item, $index) in promotionsJSON" :key="'p_' + $index")
        img(:src='item.src' @click="$openLink(item.link, '_system')")
</template>
<script>
import '@splidejs/splide/dist/css/splide.min.css'
import Splide from '@splidejs/splide'
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../../i18n'

export default {
  data: () => ({
    promotionsJSON: null,
    splide: null,
    interval: null,
  }),

  computed: {
    ...mapState({

    }),

    locale() {
      return getLocale()
    },
  },

  mounted() {
    this.firebaseRemoteConfigGetString('PROMOTIONS').then((result) => {
      try {
        this.promotionsJSON = JSON.parse(result)[this.locale]
        this.$nextTick(() => {
          this.splide = new Splide('.splide').mount()
          if (this.interval === null) {
            this.interval = setInterval(() => this.nextSplide(), 10000)
          }
        })
      } catch (error) {
        console.log(error)
      }
    })
  },

  methods: {
    ...mapActions({
      firebaseRemoteConfigGetString: 'tools/firebaseRemoteConfigGetString',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    nextSplide() {
      if (this.splide.length - 1 === this.splide.index) {
        this.splide.go(0)
      } else {
        this.splide.go('>')
      }
    },
  },
}
</script>
<style lang="stylus">
.splide__slide img
  width 100%
  height auto
  border-radius 8px
.splide__arrows
  display none!important
.v-application ul.splide__pagination
  padding 0 6px
.splide__pagination
  left 18px!important
  right: unset!important
  bottom 14px!important
  background #fff
  padding-left 0
  border-radius 8px
  height 24px
@media(min-width 600px)
  .splide
    max-width 330px
.splide__pagination__page.is-active
  background #035D56!important
  transform none!important
.splide__pagination li
  line-height 0!important
</style>
