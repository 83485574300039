<template lang="pug">
v-app
  v-app-bar(app dark dense flat)
    v-btn(icon @click="$router.push('/')")
      v-icon mdi-arrow-left
    v-toolbar-title {{ $t("сервисы") }}

  v-main(flat)
    v-container.pt-0(style="max-width: 450px")
      v-row
        v-col.pt-1
          .subtitle-2.mt-2(v-if="toggle === 0 || toggle === 2") {{ $t("подключайте_дополнительные_инструменты_для_развития_вашего_бизнеса") }}
          .subtitle-2.mt-2(v-if="toggle === 1") {{ $t("вы_можете_подключить_автоматизированный_процесс_фискализации__чеков_в_предложенных_маркетплейсах") }}

      v-row
        v-col.pb-4.pt-0
          v-btn-toggle.re-services-toggle(v-model="toggle" mandatory dense borderless)
            v-btn {{ $t("сервисы") }}
            v-btn {{ $t("маркетплейсы") }}
            v-btn(:loading="loading") {{ $t("подключенные") }}

      v-row
        // -------------------------------------------- Services -------------------------------------------- //

        v-col.pt-0(:style="toggle === 1 || toggle === 2 && !freedomBoxConnected ? 'display: none;' : ''")
          span.re-service-tile(@click="$router.push('/kkm/freedom/box'); analyticsLogEvent({ eventName: 're_services_tile_clicked', eventProperties: { tile_name: 'freedomBox' } })")
            v-container.rounded-lg.cursor-pointer(style="background: linear-gradient(97deg, #4996AE 0%, #2389A8 100%);")
              v-row(style="min-height: 130px;")
                v-col.py-0.px-4(cols="7")
                  div.subtitle-1.font-weight-bold {{ $t("продавайте_в_рассрочку_и_принимайте_платежи_через_терминал") }}
                  div.body-2.font-weight-medium.pt-2 {{ $t("прием_платежей_картами_комиссия_овердрафат_на_оборотные_средства") }}
                v-col.pa-0.pr-3(cols="5")
                  v-img(eager src="/static/freedom-box-services.png" max-height="130px")

        v-col.pt-0(:style="toggle === 1 || toggle === 2 ? 'display: none;' : ''")
          span.re-service-tile(@click="$router.push('/kkm/uchet/tcis'); analyticsLogEvent({ eventName: 're_services_tile_clicked', eventProperties: { tile_name: 'tcis' } })")
            v-container.rounded-lg.cursor-pointer(style="background: #71B665;")
              v-row(style="min-height: 130px;")
                v-col.py-0.px-4(cols="7")
                  div.subtitle-1.font-weight-bold {{ $t("трёхкомпонентная_интегрированная_система_тис") }}
                  div.body-2.font-weight-medium.pt-2 {{ $t("увеличивайте_порог_по_ндс_для_вашего_бизнеса") }}
                v-col.pa-0.pr-3(cols="5")
                  v-img(eager src="/static/tcis-services.png" max-height="130px")

        v-col.pt-0(:style="toggle === 1 || toggle === 2 ? 'display: none;' : ''")
          span.re-service-tile(@click="$router.push('/kkm/yandex/business'); analyticsLogEvent({ eventName: 're_services_tile_clicked', eventProperties: { tile_name: 'yandexBusiness' } })")
            v-container.rounded-lg.cursor-pointer(style="background: #DA9B00;")
              v-row(style="min-height: 130px;")
                v-col.py-0.px-4(cols="7")
                  div.subtitle-1.font-weight-bold {{ $t("реклама_для_предпринимателей") }}
                  div.body-2.font-weight-medium.pt-2 {{ $t("приведет_к_вам_клиентов_пока_вы_занимаетесь_другими_делами") }}
                v-col.pa-0.pr-3(cols="5")
                  v-img(eager src="/static/yandex-business-services.png" max-height="130px")

        v-col.pt-0(:style="toggle === 1 || toggle === 2 ? 'display: none;' : ''")
          span.re-service-tile(@click="$router.push('/kkm/freedom/credit'); analyticsLogEvent({ eventName: 're_services_tile_clicked', eventProperties: { tile_name: 'freedomCredit' } })")
            v-container.rounded-lg.cursor-pointer(style="background: linear-gradient(97deg, #FF6859 0%, #A2180B 100%);")
              v-row(style="min-height: 130px;")
                v-col.py-0.px-4(cols="7")
                  div.subtitle-1.font-weight-bold {{ $t("микрозаймы_для_малого_бизнеса") }}
                  div.body-2.font-weight-medium.pt-2 {{ $t("быстрое_и_простое_онлайн_оформление_займа_до_млн_тенге_на_карту") }}
                v-col.pa-0.pr-3(cols="5")
                  v-img(eager src="/static/freedom-credit-services.png" max-height="130px")

        v-col.pt-0(:style="toggle === 1 || toggle === 2 ? 'display: none;' : ''")
          span.re-service-tile(@click="$router.push('/kkm/freedom/life'); analyticsLogEvent({ eventName: 're_services_tile_clicked', eventProperties: { tile_name: 'freedomLife' } })")
            v-container.rounded-lg.cursor-pointer(style="background: linear-gradient(97deg, #FF0266 0%, #8C0037 100%);")
              v-row(style="min-height: 130px;")
                v-col.py-0.px-4(cols="7")
                  div.subtitle-1.font-weight-bold {{ $t("обязательное_страхование_сотрудников") }}
                  div.body-2.font-weight-medium.pt-2 {{ $t("защита_сотрудников_от_несчастных_случаев_на_работе") }}
                v-col.pa-0.pr-3(cols="5")
                  v-img(eager src="/static/freedom-life-services.png" max-height="130px")

        v-col.pt-0(:style="toggle === 1 || toggle === 2 ? 'display: none;' : ''")
          span.re-service-tile(@click="$router.push('/kkm/freedom/insurance/casco'); analyticsLogEvent({ eventName: 're_services_tile_clicked', eventProperties: { tile_name: 'freedomInsuranceCasco' } })")
            v-container.rounded-lg.cursor-pointer(style="background: linear-gradient(97deg, #1EB980 0%, #046C46 100%);")
              v-row(style="min-height: 130px;")
                v-col.py-0.px-4(cols="7")
                  div.subtitle-1.font-weight-bold {{ $t("оформи_страховку_каско_со_скидкой") }}
                  div.body-2.font-weight-medium.pt-2 {{ $t("быстрое_оформление_помощь_консультанта_выгодные_условия") }}
                v-col.pa-0.pr-3(cols="5")
                  v-img(eager src="/static/freedom-insurance-casco-services.png" max-height="130px")

        v-col.pt-0(:style="toggle === 1 || toggle === 2 && !halykPosConnected ? 'display: none;' : ''")
          span.re-service-tile(@click="$router.push('/kkm/halyk/pos'); analyticsLogEvent({ eventName: 're_services_tile_clicked', eventProperties: { tile_name: 'halykPos' } })")
            v-container.rounded-lg.cursor-pointer(style="background: linear-gradient(97deg, #9752D8 0%, #8C2FE2 100%);")
              v-row(style="min-height: 130px;")
                v-col.py-0.px-4(cols="7")
                  div.subtitle-1.font-weight-bold {{ $t("прием_карточных_платежей_через_смартфон") }}
                  div.body-2.font-weight-medium.pt-2 {{ $t("подключается_в_ваш_смартфон_принимает_любые_карты") }}
                v-col.pa-0.pr-3(cols="5")
                  v-img(eager src="/static/halyk-pos-services.png" max-height="130px")

        v-col.pt-0(:style="toggle === 1 || toggle === 2 && !moyUchetConnected ? 'display: none;' : ''")
          span.re-service-tile(@click="$router.push('/kkm/moyuchet'); analyticsLogEvent({ eventName: 're_services_tile_clicked', eventProperties: { tile_name: 'moyUchet' } })")
            v-container.rounded-lg.cursor-pointer(style="background: linear-gradient(97deg, #FFCF44 0%, #A67D08 100%);")
              v-row(style="min-height: 130px;")
                v-col.py-0.px-4(cols="7")
                  div.subtitle-1.font-weight-bold {{ $t("онлайн_бухгалтерия_для_предпринимателей_на_упрощенке") }}
                  div.body-2.font-weight-medium.pt-2 {{ $t("рассчитывает_налоги_подтягивает_данные_из_rekassa") }}
                v-col.pa-0.pr-3(cols="5")
                  v-img(eager src="/static/moy-uchet-services.png" max-height="130px")

        v-col.pt-0(:style="toggle === 1 || toggle === 2 ? 'display: none;' : ''")
          span.re-service-tile(@click="$router.push('/kkm/atbpos'); analyticsLogEvent({ eventName: 're_services_tile_clicked', eventProperties: { tile_name: 'atbpos' } })")
            v-container.rounded-lg.cursor-pointer(style="background: #195388;")
              v-row(style="min-height: 130px;")
                v-col.py-0.px-4(cols="7")
                  div.subtitle-1.font-weight-bold {{ $t("оборудование_для_бизнеса") }}
                  div.body-2.font-weight-medium.pt-2 {{ $t("принтеры_чеков_и_сканеры_штрих_кодов_со_скидкой") }}
                v-col.pa-0.pr-3(cols="5")
                  v-img(eager src="/static/atbpos-services.png" max-height="130px")

        v-col(cols="6" :style="toggle === 1 || toggle === 2 && !oneCConnected ? 'display: none;' : ''").pt-0
          span.re-service-tile(@click="$openLink(`https://link.rekassa.kz/1c-about-${locale}`, '_system'); analyticsLogEvent({ eventName: 're_services_tile_clicked', eventProperties: { tile_name: '1C' } })")
            v-container.rounded-lg.cursor-pointer(style="background: linear-gradient(97deg, #045D56 0%, #0A3935 100%);")
              v-row(style="min-height: 130px;")
                v-col.py-0.px-4
                  div.subtitle-1.font-weight-bold {{ $t("ведение_продаж_из_1с") }}
                  div.body-2.font-weight-medium.pt-2 {{ $t("подключайте_чтобы_вести_бухгалтерию_максимально_удобно_1с_интеграция") }}

        v-col(cols="6" :style="toggle === 1 || toggle === 2 && !moySkladConnected ? 'display: none;' : ''").pt-0
          span.re-service-tile(@click="$openLink(`https://link.rekassa.kz/moi-sklad-about-${locale}`, '_system'); analyticsLogEvent({ eventName: 're_services_tile_clicked', eventProperties: { tile_name: 'moySklad' } })")
            v-container.rounded-lg.cursor-pointer(style="background: linear-gradient(97deg, #045D56 0%, #0A3935 100%);")
              v-row(style="min-height: 130px;")
                v-col.py-0.px-4
                  div.subtitle-1.font-weight-bold {{ $t("торговля_склад_CRM_в_облаке") }}
                  div.body-2.font-weight-medium.pt-4 {{ $t("автоматизированная_фискализация_продаж_мой_склад") }}

        v-col.text-center.pt-10(v-if="toggle === 2 && !halykPosConnected && !moyUchetConnected && !freedomBoxConnected && !moySkladConnected && !oneCConnected && !kaspiShopConnected")
          v-icon(color="grey" size="110") mdi-folder-cancel-outline
          div.px-10.pt-2
            .subtitle-1.font-weight-bold {{ $t("у_вас_пока_нет_подключенных_сервисов") }}
            .subtitle-2 {{ $t("доступные_сервисы_можно_подключить_на_вкладке_все_сервисы") }}

        // -------------------------------------------- Marketplaces -------------------------------------------- //

        v-col.pt-0(:style="toggle === 0 || toggle === 2 && !kaspiShopConnected ? 'display: none;' : ''")
          span.re-service-tile(@click="$router.push('/kkm/kaspi/shop'); analyticsLogEvent({ eventName: 're_services_tile_clicked', eventProperties: { tile_name: 'kaspiShop' } })")
            v-container.rounded-lg.cursor-pointer(style="background: linear-gradient(97deg, #FF6859 0%, #A2180B 100%);")
              v-row(style="min-height: 130px;")
                v-col.py-0.px-4(cols="7")
                  div.subtitle-1.font-weight-bold {{ $t("каспи_магазин") }}
                  div.body-2.font-weight-medium.pt-2 {{ $t("автоматическая_фискализация_всех_ваших_продаж_в_маркетплейсе") }}
                v-col.pa-0.pr-3(cols="5")
                  v-img(eager src="/static/kaspi-shop-services.png" max-height="130px")

        v-col.pt-0(:style="toggle === 0 || toggle === 2 ? 'display: none;' : ''")
          span.re-service-tile(@click="$router.push('/kkm/marketplace-request'); analyticsLogEvent({ eventName: 're_services_tile_clicked', eventProperties: { tile_name: 'marketplaceRequest' } })")
            v-container.rounded-lg.cursor-pointer(style="background: linear-gradient(97deg, #2E8FE9 0%, #330264 100%);")
              v-row(style="min-height: 130px;")
                v-col.py-0.px-4(cols="7")
                  div.subtitle-1.font-weight-bold {{ $t("подключи_свой_маркетплейс") }}
                  div.body-2.font-weight-medium.pt-2 {{ $t("продавай_онлайн_легально_и_легко_с_любого_маркетплейса") }}
                v-col.pa-0.pr-3(cols="5")
                  v-img(eager src="/static/marketplace-shop-services.png" max-height="130px")

</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../../i18n/index'

export default {
  data: () => ({
    loading: false,
    toggle: 0,
    freedomBoxConnected: false,
    moySkladConnected: false,
    oneCConnected: false,
  }),

  computed: {
    ...mapState({
      serviceToggleState: state => state.tools.serviceToggleState,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
    }),

    locale() {
      return getLocale()
    },

    halykPosConnected() {
      return this.configuration.halyk && this.configuration.halyk.halykPosConnected
    },

    moyUchetConnected() {
      return this.configuration.moyuchet && this.configuration.moyuchet.сonnected
    },

    kaspiShopConnected() {
      return this.configuration.kaspiShop && this.configuration.kaspiShop.connected
    },
  },

  watch: {
    toggle(value) {
      this.setServiceToggleState(value)
    },
  },

  created() {
    this.toggle = this.serviceToggleState

    this.loading = true
    this.fetchApiAccess({
      cashRegister: this.cashRegister,
    }).then((result) => {
      result.data.forEach(apiAccess => {
        if (apiAccess.partner.name === 'FFTERMINAL' && apiAccess.status === 'ENABLED') {
          this.freedomBoxConnected = true
        } else if (apiAccess.partner.name === 'MOYSKLAD' && apiAccess.status === 'ENABLED') {
          this.moySkladConnected = true
        } else if ((apiAccess.partner.name === 'MEDIANA' || apiAccess.partner.name === 'PULSSAR') && apiAccess.status === 'ENABLED') {
          this.oneCConnected = true
        }
      })
      this.loading = false
    }).catch((error) => {
      this.loading = false
      this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
    })
  },

  methods: {
    ...mapActions({
      fetchApiAccess: 'cashRegisters/fetchApiAccess',
      analyticsLogEvent: 'analytics/logEvent',
      showSnackbar: 'tools/showSnackbar',
      setServiceToggleState: 'tools/setServiceToggleState',
    }),

  },
}
</script>

<style lang="stylus">
.re-services-toggle
  background-color #41414B!important
  padding 4px!important
  border-radius 8px!important
  width 100%
  .v-btn
    width 33%
    height 32px!important
    background-color #41414B!important
    border-radius 8px!important
    text-transform none!important
    font-weight 500!important
    letter-spacing 0.5px!important
  .theme--light.v-btn
    color #fff!important
  .theme--light.v-btn--active
    color #000!important
    background-color #fff!important
  .theme--dark.v-btn--active
    color #000!important
    background-color #fff!important
.re-service-tile
  color #fff
  div
    line-height 1.2rem!important
  .subtitle-2
    font-size: 15px!important;
</style>
