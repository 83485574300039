<template lang="pug">
v-app
  v-app-bar(app dark dense flat)
    v-btn(icon @click="$router.push('/settings')")
      v-icon mdi-arrow-left
    v-toolbar-title {{ $t("пользователи") }}

  v-main(flat)
    v-container(style="max-width: 450px")
      v-row
        v-col
          v-list.v-list--fulltext.gray-background-list
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("в_данном_разделе_вы_можете_открыть_доступ_к_кассе", { serial: cashRegister.serialNumber, name: cashRegister.name, company: `${organization.businessName} - ${organization.businessId}` }) }}.
                  a(@click.prevent="$openLink('https://link.rekassa.kz/access-users', '_system')") &nbsp; {{ $t("подробнее") }}

      v-row
        v-col
          v-list.gray-background-list
            div.px-4
              v-btn.my-2(block color="primary" @click="createNewInvitation()") {{ $t("добавить_пользователя") }}

      v-row
        v-col.pt-0
          v-subheader {{ $t("отправленные_приглашения") }}
          v-progress-linear(v-if="invitationListLoading" active indeterminate color="primary" value="100")
          div(v-else style="height: 4px;")
          v-list(v-if="invitationList.length > 0")
            v-list-item(v-for="(item, $index) in invitationList" :key="'a_' + $index")
              v-list-item-content
                v-list-item-title {{ $index + 1 }}.&nbsp;
                  a(@click.prevent="$openLink(`tel:${item.phone}`)") {{ item.phone | phone }}
                v-list-item-subtitle {{ $t("роль") }}: {{ $t('role.' + item.role) }}
                v-list-item-subtitle {{ $t("действительно_до") }}: {{ item.expireDate | moment("DD.MM.YYYY HH:mm") }}
              v-list-item-action
                v-btn(icon :disabled="invitationListLoading" :loading="deleteInvitationLoading" @click="deleteInvite(item)")
                  v-icon(color="red") mdi-close
          div(v-if="invitationListLoading && invitationList.length === 0")
            v-skeleton-loader.mt-2(type="list-item-two-line" boilerplate)
          div(v-if="!invitationListLoading && invitationList.length === 0")
            v-list-item
              v-list-item-content
                v-list-item-subtitle(align="center") {{ $t("нет_приглашений") }}

      v-row
        v-col.pt-0
          v-subheader {{ $t("текущие_пользователи") }}
          v-progress-linear(v-if="userRoleListLoading" active indeterminate color="primary" value="100")
          div(v-else style="height: 4px;")
          v-list(v-if="userRoleList.length > 0")
            v-list-item(v-for="(item, $index) in userRoleList" :key="'a_' + $index")
              v-list-item-content
                v-list-item-title {{ $index + 1 }}.&nbsp;
                  a(@click.prevent="$openLink(`tel:${item.user.phone}`)") {{ item.user.phone | phone }} &nbsp;
                  span(v-if="item.user.name") {{ item.user.name }}
                v-list-item-subtitle {{ $t("роль") }}: {{ $t('role.' + item.roles[0].name) }}
              v-list-item-action
                v-btn(icon :disabled="userRoleListLoading" @click="preDeleteRole(item)")
                  v-icon(color="red") mdi-account-off-outline
          div(v-if="userRoleListLoading && userRoleList.length === 0")
            v-skeleton-loader.mt-2(type="list-item-two-line" boilerplate)
          div(v-if="!userRoleListLoading && userRoleList.length === 0")
            v-list-item
              v-list-item-content
                v-list-item-subtitle(align="center") {{ $t("нет_пользователей") }}

      v-row.mt-6
        v-col
          v-list.gray-background-list.px-4
            v-btn(block text color="error" @click="deleteMe()") {{ $t("отключиться_от_кассы") }}

  v-dialog(v-model="invitationDialog" max-width="400px" persistent)
    v-card
      v-card-title.pb-0
        span.headline {{ $t("введите_номер_телефона") }}
      v-card-text
        v-container
          v-form.mt-3(@submit.prevent="preInvite()")
            v-select(:label="$t('роль')" v-model="role" :items="roleTypes" item-value="value" item-text="title" :rules="[rules.required]")
            v-text-field(
              v-model="phoneNumber"
              v-mask="'+7 (###) ###-##-##'"
              type="tel"
              :label="$t('номер_телефона_пользователя')"
              placeholder="+7 (###) ###-##-##"
              @input="phoneNumberError = null"
              :error-messages="phoneNumberError"
              autocomplete="off")
      v-divider
      v-card-actions
        v-spacer
        v-btn(text @click="invitationDialog = false") {{ $t("отменить") }}
        v-btn(text color="primary" @click="preInvite()") {{ $t("пригласить") }}

  re-pinpad(v-model="invitationPinDialog" :title="$t('отправка_приглашения')" :subtitle="$t('введите_пин-код')" :text="$t('для_приглашения_пользователя')" :loading="loading" :errorText.sync="invitationPinErrorMessage" @action="invite")
  re-pinpad(v-model="deleteRolePinDialog" :title="$t('удаление_пользователя')" :subtitle="$t('введите_пин-код')" :text="$t('для_удаления_пользователя')" :loading="loading" :errorText.sync="deleteRolePinErrorMessage" @action="deleteRole")

</template>
<script>
import { mapState, mapActions } from 'vuex'
import store from '../../store/index'
import Pinpad from '../utils/PinpadDialog.vue'
import dictionaryMixin from '../../mixins/dictionaryMixin'

export default {
  components: {
    're-pinpad': Pinpad,
  },

  mixins: [dictionaryMixin],

  data: () => ({
    loading: false,

    invitationListLoading: false,
    invitationList: [],

    userRoleListLoading: false,
    userRoleList: [],

    invitationDialog: false,
    phoneNumber: null,
    role: 'CASHIER',
    phoneNumberError: null,

    invitationPinDialog: false,
    invitationPinErrorMessage: null,

    deleteInvitationLoading: false,

    deleteRolePinDialog: false,
    deleteRolePinErrorMessage: null,
    deleteRoleItem: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
    }),
  },

  created() {
    this.init()
  },

  beforeRouteEnter(to, from, next) {
    if (store.state.cashRegisters.cashRegister.cashRegister.status === 'REGISTERED') {
      next()
    } else {
      next('/')
    }
  },

  methods: {
    ...mapActions({
      fetchCashRegisterPendingInvitations: 'cashRegisters/fetchCashRegisterPendingInvitations',
      fetchCashRegisterUserRoles: 'cashRegisters/fetchCashRegisterUserRoles',
      inviteByPhone: 'cashRegisters/inviteByPhone',
      deleteInvitation: 'cashRegisters/deleteInvitation',
      deleteUserRole: 'cashRegisters/deleteUserRole',
      deleteMyRole: 'cashRegisters/deleteMyRole',
      sendInvitation: 'cashRegisters/sendInvitation',
      initCashRegisters: 'cashRegisters/init',
      analyticsLogEvent: 'analytics/logEvent',
      showConfirm: 'tools/showConfirm',
      showSnackbar: 'tools/showSnackbar',
    }),

    init() {
      this.fetchInvitationList()
      this.fetchUserRoleList()
    },

    fetchInvitationList() {
      this.invitationListLoading = true
      this.fetchCashRegisterPendingInvitations({
        cashRegister: this.cashRegister,
      }).then((result) => {
        this.invitationListLoading = false
        this.invitationList = result.data._embedded ? result.data._embedded.invitations : []
      }).catch((error) => {
        this.invitationListLoading = false
        this.showSnackbar({ message: this.$t('произошла_ошибка', { error }) })
      })
    },

    fetchUserRoleList() {
      this.userRoleListLoading = true
      this.fetchCashRegisterUserRoles({
        cashRegister: this.cashRegister,
      }).then((result) => {
        this.userRoleListLoading = false
        this.userRoleList = result.data.filter((item) => item.user.phone !== this.user.phone)
      }).catch((error) => {
        this.userRoleListLoading = false
        this.showSnackbar({ message: this.$t('произошла_ошибка', { error }) })
      })
    },

    createNewInvitation() {
      this.phoneNumber = null
      this.invitationDialog = true
    },

    preInvite() {
      if (!(this.phoneNumber && this.phoneNumber.length === 18)) {
        this.phoneNumberError = this.$t('введите_номер_телефона')
        return
      }

      if (!(this.phoneNumber.startsWith('+7 (7'))) {
        this.phoneNumberError = this.$t('введите_казахстанский_сотовый_номер')
        return
      }

      if (this.user.phone === `+${this.phoneNumber.replace(/[^\d]/g, '')}`) {
        this.phoneNumberError = this.$t('вы_указали_свой_номер_телефона')
        return
      }

      this.invitationDialog = false
      this.invitationPinDialog = true
    },

    invite(pincode) {
      this.loading = true
      const form = new FormData()
      form.append('phone', `+${this.phoneNumber.replace(/[^\d]/g, '')}`)
      form.append('role', this.role)
      this.inviteByPhone({
        cashRegister: this.cashRegister,
        password: pincode,
        data: form,
      }).then(() => {
        this.loading = false
        this.invitationPinDialog = false
        this.fetchInvitationList()
        this.sendSmsInvitation(this.phoneNumber, pincode, this.user.phone)
      }).catch((error) => {
        if (error && error.response && error.response.data) {
          this.loading = false
          if (error.response.data.code === 'WRONG_PASSWORD') {
            this.invitationPinErrorMessage = this.$t('backend/WRONG_PASSWORD')
          } else if (error.response.data.code === 'ROLE_ALREADY_EXISTS') {
            this.invitationPinDialog = false
            this.showSnackbar({ message: this.$t('данный_номер_уже_есть_в_списке_пользователей') })
          }
        } else {
          this.showSnackbar({ message: this.$t('произошла_ошибка', { error }) })
          this.loading = false
          this.invitationPinDialog = false
        }
      })
    },

    sendSmsInvitation(phoneNumber, pincode, sender) {
      const url = `${process.env.VUE_APP_API_ROOT_URL}/list`
      const name = `«${this.cashRegister.name}» - ${this.cashRegister.serialNumber} (${this.organization.businessName} - ${this.organization.businessId})`
      window.grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY, { action: 'invitation' })
        .then((token) => {
          this.sendInvitation({
            data: {
              phone: `+${phoneNumber.replace(/[^\d]/g, '')}`, sender, token, pincode, url, name,
            },
          }).then(() => {
            this.showSnackbar({ message: this.$t('sms_приглашение_успешно_отправлено') })
          }).catch((error) => {
            if (error.response.data && error.response.data.code) {
              this.phoneNumberError = this.$t(`sms/${error.response.data.code}`)
            } else {
              this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
            }
          })
        }).catch((error) => {
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
    },

    deleteInvite(item) {
      this.showConfirm({
        title: this.$t('удалить_приглашение', { phone: this.$options.filters.phone(item.phone) }),
        resolveText: this.$t('удалить'),
        rejectText: this.$t('отменить'),
        persistent: true,
      }).then(() => {
        this.deleteInvitationLoading = true
        this.deleteInvitation({ id: item.id }).then(() => {
          this.fetchInvitationList()
          this.deleteInvitationLoading = false
          this.showSnackbar({ message: this.$t('приглашение_успешно_удалено') })
        }).catch((error) => {
          this.deleteInvitationLoading = false
          this.showSnackbar({ message: this.$t('произошла_ошибка', { error }) })
        })
      }).catch(() => {})
    },

    preDeleteRole(item) {
      this.showConfirm({
        title: this.$t('удалить_пользователя', { phone: this.$options.filters.phone(item.user.phone) }),
        resolveText: this.$t('удалить'),
        rejectText: this.$t('отменить'),
        persistent: true,
      }).then(() => {
        this.deleteRoleItem = item
        this.deleteRolePinDialog = true
      }).catch(() => {})
    },

    deleteRole(pincode) {
      this.loading = true
      this.deleteUserRole({
        cashRegister: this.cashRegister,
        id: this.deleteRoleItem.user.id,
        role: this.deleteRoleItem.roles[0].name,
        password: pincode,
      }).then(() => {
        this.loading = false
        this.deleteRolePinDialog = false
        this.fetchUserRoleList()
        this.showSnackbar({ message: this.$t('пользователь_успешно_удален') })
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.deleteRolePinErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else {
          this.showSnackbar({ message: this.$t('произошла_ошибка', { error }) })
          this.loading = false
          this.deleteRolePinDialog = false
        }
      })
    },

    deleteMe() {
      this.showConfirm({
        title: this.$t('вы_уверены_что_хотите_отключиться_от_кассы', { serial: this.cashRegister.serialNumber, name: this.cashRegister.name, company: `${this.organization.businessName} - ${this.organization.businessId}` }),
        text: this.$t('отключение_приведёт_к_потере_доступа_к_кассе', { name: this.organization.businessName }),
        resolveText: this.$t('отключиться'),
        resolveButtonColor: 'error',
        rejectText: this.$t('отменить'),
        persistent: true,
      }).then(() => {
        this.deleteMyRole({
          cashRegister: this.cashRegister,
          id: this.user.id,
        }).then(() => {
          this.initCashRegisters().then(() => {
            this.showSnackbar({ message: this.$t('вы_успешно_отключились_от_кассы') })
            this.$router.push('/')
          })
        }).catch((error) => {
          this.showSnackbar({ message: this.$t('произошла_ошибка', { error }) })
        })
      }).catch(() => {})
    },
  },
}
</script>
