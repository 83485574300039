<template lang="pug">
v-app
  v-app-bar(app dark dense flat)
    v-btn(icon @click="$router.push('/kkm')")
      v-icon mdi-arrow-left
    v-toolbar-title {{ $t("позиции_чека") }}

  v-main.mb-8(flat)
    re-ticket-details-main(ref="ticketDetailsMainRef")

</template>
<script>
import { mapActions } from 'vuex'
import TicketDetailsMain from './parts/TicketDetailsMain.vue'

export default {
  components: {
    're-ticket-details-main': TicketDetailsMain,
  },

  methods: {
    ...mapActions({
      setTicketRequest: 'cashRegisters/setTicketRequest',
      showConfirm: 'tools/showConfirm',
    }),
  },

  beforeRouteLeave(to, from, next) {
    this.$refs.ticketDetailsMainRef.prepareToExit(to, next)
  },
}
</script>

<style lang="stylus" scoped>

</style>
