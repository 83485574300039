<template lang="pug">
v-app
  v-app-bar(app dark dense flat)
    v-btn(icon @click="$goBack()")
      v-icon mdi-arrow-left
    v-toolbar-title {{ $t("смена") }} №{{ shiftNumber }}
    v-progress-linear(:active="loading" :indeterminate="loading" absolute bottom)
    v-spacer
    template(v-if="shift")
      v-btn(:disabled="loading" :to="`/shifts/${cashRegister.id}/${shiftNumber}/zxreport`" text) {{ shift.open ? 'X' : 'Z' }}-{{ $t("отчёт") }}
      v-btn(id="zxreport_button" icon)
        v-icon mdi-dots-vertical
        v-menu(bottom left activator="#zxreport_button")
          v-list
            v-list-item(@click="toSectionsReport()")
              v-list-item-content
                v-list-item-title {{ $t("отчёт_по_секциям") }}
              v-list-item-icon
                v-icon mdi-format-list-bulleted
            v-list-item(@click="toOperatorsReport()")
              v-list-item-content
                v-list-item-title {{ $t("отчёт_по_кассирам") }}
              v-list-item-icon
                v-icon mdi-cash-register
            v-list-item(@click="downloadShiftTickets()")
              v-list-item-content
                v-list-item-title {{ $t("скачать_чеки") }}
              v-list-item-icon
                v-icon mdi-download

  v-main(flat)
    v-container(style="max-width: 600px")

      div.px-4(v-if="shift === null")
        v-skeleton-loader.mt-6(type="text" boilerplate style="width: 100px;")
        v-skeleton-loader.mt-7(type="heading" boilerplate style="width: 300px;")
        v-skeleton-loader.mt-5.mb-3(type="text" boilerplate style="width: 300px;")

      v-card(outlined v-if="shift")
        v-list-item(three-line)
          v-list-item-content
            h1.overline {{ $t("выручка_за_смену") }}
              | &nbsp;({{ shift.openTime | moment("DD.MM.YYYY HH:mm") }}
              template(v-if="shift.closeTime") &nbsp;- {{ shift.closeTime | moment("DD.MM.YYYY HH:mm") }}
              | )
            v-list-item-title.my-2.display-2.font-weight-medium {{ shift.revenue | numeral('0,0.[00]') | beautiful-minus }} ₸
            v-list-item-subtitle
              template(v-if="shift.data.totalResult")
                template(v-for="(operation, index) in shift.data.totalResult" v-if="getNumberFromBillsAndCoins(operation.sum) > 0")
                  | {{ index !== 0 ? '&nbsp;&bull;&nbsp;' : ''}} {{ $t('operationType.' + operation.operation) }}: {{ getNumberFromBillsAndCoins(operation.sum) | numeral('0,0.[00]') | beautiful-minus }} ₸

              div.mt-1(v-if="shift.data.moneyPlacements")
                template(v-for="(moneyPlacement, index) in shift.data.moneyPlacements" v-if="getNumberFromBillsAndCoins(moneyPlacement.operationsSum) > 0")
                  | {{ index !== 0 ? '&nbsp;&bull;&nbsp;' : ''}} {{ $t('operationType.' + moneyPlacement.operation) }}: {{ getNumberFromBillsAndCoins(moneyPlacement.operationsSum) | numeral('0,0.[00]') | beautiful-minus }} ₸

      v-progress-linear(height="1" color="primary" value="100")

      div.px-4(v-if="ticketList.length === 0")
        v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
        v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
        v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
        v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
        v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
        v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
        v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
        v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
        v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)
        v-skeleton-loader.mt-6(type="list-item-avatar-two-line" boilerplate)

      v-list(two-line subheader)
        v-list-item-group
          v-list-item(v-for="(ticket, $index) in ticketList" :key="'r_' + $index" :to="`/print/${cashRegister.id}/${ticket.id}`" :inactive="ticket.command === 'COMMAND_CANCEL_TICKET'")
            template(v-if="ticket.command === 'COMMAND_CANCEL_TICKET'")
              v-list-item-icon
                v-icon mdi-file-cancel-outline
              v-list-item-content(:class="ticket.command === 'COMMAND_CANCEL_TICKET' ? 'cancelled' : ''")
                v-list-item-title {{ $t("предыдущая_сумма") }}
                v-list-item-subtitle {{ $t("отмена_последнего_чека") }}
              v-list-item-action
                v-list-item-action-text
                  v-icon.mr-1(size="12" v-if="ticket.offline") mdi-cloud-off-outline
                  | {{ ticket.messageTime | moment("DD.MM.YYYY HH:mm") }}
                v-icon(color="success" v-if="ticket.status === 'OK'") mdi-check-all
                v-icon(color="success" v-if="ticket.status === 'SAVED'") mdi-check
                v-icon(color="error" v-if="ticket.status === 'ERROR'") mdi-alert-circle-outline
                v-icon(v-if="ticket.status === 'NEW'") mdi-progress-check
            template(v-if="ticket.command === 'COMMAND_TICKET'")
              v-list-item-icon(v-if="ticket.extra.attributes && ticket.extra.attributes.paymentType === 'KASPI_PAY'")
                v-icon(color="red") mdi-qrcode-plus
              v-list-item-icon(v-else-if="ticket.extra.attributes && ticket.extra.attributes.marketType === 'KASPI_SHOP'")
                v-icon(color="red") mdi-cart-outline
              v-list-item-icon(v-else-if="ticket.extra.attributes && (ticket.extra.attributes.paymentType === 'HALYK_POS' || ticket.extra.attributes.paymentType === 'ALFA_PAY' || ticket.extra.attributes.paymentType === 'FFB_POS')")
                v-icon mdi-contactless-payment
              v-list-item-icon(v-else)
                v-icon {{ dictionary.operationType[ticket.data.ticket.operation].icon }}
              v-list-item-content(:class="ticket.cancelledBy ? 'cancelled' : ''")
                v-list-item-title {{ ticket.total | numeral('0,0.[00]') | beautiful-minus }} ₸
                v-list-item-subtitle
                  | №{{ ticket.shiftDocumentNumber }} &bull; {{ dictionary.operationType[ticket.data.ticket.operation].title }} (
                  template(v-for="(payment, paymentIndex) in ticket.data.ticket.payments")
                    | {{ $t("paymentType." + payment.type + "_short") }}: {{ getNumberFromBillsAndCoins(payment.sum) | numeral('0,0.[00]') | beautiful-minus }} ₸
                    template(v-if="paymentIndex !== ticket.data.ticket.payments.length - 1") ,&nbsp;
                  | )
              v-list-item-action
                v-list-item-action-text
                  v-icon.mr-1(size="12" v-if="ticket.offline") mdi-cloud-off-outline
                  | {{ ticket.messageTime | moment("DD.MM.YYYY HH:mm") }}
                v-icon(color="success" v-if="ticket.status === 'OK'") mdi-check-all
                v-icon(color="success" v-if="ticket.status === 'SAVED'") mdi-check
                v-icon(color="error" v-if="ticket.status === 'ERROR'") mdi-alert-circle-outline
                v-icon(v-if="ticket.status === 'NEW'") mdi-progress-check
            template(v-if="ticket.command === 'COMMAND_MONEY_PLACEMENT'")
              v-list-item-icon
                v-icon {{ dictionary.operationType[ticket.data.moneyPlacement.operation].icon }}
              v-list-item-content
                v-list-item-title {{ ticket.total | numeral('0,0.[00]') | beautiful-minus }} ₸
                v-list-item-subtitle №{{ ticket.shiftDocumentNumber }} &bull; {{ dictionary.operationType[ticket.data.moneyPlacement.operation].title }}
              v-list-item-action
                v-list-item-action-text
                  v-icon.mr-1(size="12" v-if="ticket.offline") mdi-cloud-off-outline
                  | {{ ticket.messageTime | moment("DD.MM.YYYY HH:mm") }}
                v-icon(color="success" v-if="ticket.status === 'OK'") mdi-check-all
                v-icon(color="success" v-if="ticket.status === 'SAVED'") mdi-check
                v-icon(color="error" v-if="ticket.status === 'ERROR'") mdi-alert-circle-outline
                v-icon(v-if="ticket.status === 'NEW'") mdi-progress-check

      div.my-4.text-center(v-if="totalPages > 1")
        v-pagination(v-model="ticketPage.current" :length="totalPages" :total-visible="5")

</template>
<script>
/* eslint-disable no-nested-ternary */
import XLSX from 'xlsx'
import { Decimal } from 'decimal.js'
import { mapActions, mapState } from 'vuex'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import ticketMixin from '../../mixins/ticketMixin'
import billsAndCoinsMixin from '../../mixins/billsAndCoinsMixin'

export default {
  mixins: [dictionaryMixin, ticketMixin, billsAndCoinsMixin],

  data: () => ({
    shift: null,
    ticketList: [],
    loading: false,
    totalPages: 0,
    shiftNumber: null,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
      ticketPage: state => state.cashRegisters.ticketPage,
    }),
  },

  watch: {
    // eslint-disable-next-line func-names
    'ticketPage.current': function () {
      this.fetchTicketList()
    },
  },

  created() {
    this.shiftNumber = this.$route.params.shiftNumber
    this.fetchShift()
  },

  methods: {
    ...mapActions({
      fetchShiftTicketListByShiftNumber: 'cashRegisters/fetchShiftTicketListByShiftNumber',
      fetchAllShiftTicketListByShiftNumber: 'cashRegisters/fetchAllShiftTicketListByShiftNumber',
      fetchShiftByNumber: 'cashRegisters/fetchShiftByNumber',
      showSnackbar: 'tools/showSnackbar',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    toSectionsReport() {
      this.$router.push(`/shifts/${this.cashRegister.id}/${this.shiftNumber}/sections`)
      this.analyticsLogEvent({ eventName: 're_shift_unit_reports_click' })
    },

    toOperatorsReport() {
      this.$router.push(`/shifts/${this.cashRegister.id}/${this.shiftNumber}/operators`)
      this.analyticsLogEvent({ eventName: 're_shift_cashier_report_click' })
    },

    fetchShift() {
      this.loading = true
      this.fetchShiftByNumber({ cashRegister: this.cashRegister, shiftNumber: this.shiftNumber }).then((result) => {
        this.fetchTicketList(result.data)
      }).catch((error) => {
        if (error && error.response && error.response.status === 404) {
          this.showSnackbar({ message: this.$t('смена_не_существует') })
          this.$router.push('/')
        }
      })
    },

    fetchTicketList(shift) {
      this.loading = true
      this.ticketList = []
      this.fetchShiftTicketListByShiftNumber({ cashRegister: this.cashRegister, shiftNumber: this.shiftNumber }).then((result) => {
        if (shift) {
          this.shift = shift
          this.shift = { ...this.shift, revenue: this.shift.data && this.shift.data.revenue ? (this.shift.data.revenue.isNegative ? -1 : 1) * this.getNumberFromBillsAndCoins(this.shift.data.revenue.sum) : 0 }
          this.shift.data.totalResult?.sort(((a, b) => this.dictionary.operationType[a.operation].order - this.dictionary.operationType[b.operation].order))
        }
        this.totalPages = result.data && result.data.page ? result.data.page.totalPages : 0
        this.ticketList = result.data._embedded.tickets
          .map((ticket) => {
            if (ticket.command === 'COMMAND_TICKET' && ticket.data && ticket.data.ticket && ticket.data.ticket.amounts && ticket.data.ticket.amounts.total) {
              return { ...ticket, total: this.getNumberFromBillsAndCoins(ticket.data.ticket.amounts.total) * (this.dictionary.operationType[ticket.data.ticket.operation].negative ? -1 : 1) }
            }
            if (ticket.command === 'COMMAND_MONEY_PLACEMENT' && ticket.data && ticket.data.moneyPlacement && ticket.data.moneyPlacement.sum) {
              return { ...ticket, total: this.getNumberFromBillsAndCoins(ticket.data.moneyPlacement.sum) * (this.dictionary.operationType[ticket.data.moneyPlacement.operation].negative ? -1 : 1) }
            }
            return { ...ticket, total: 0 }
          })
          .sort((a, b) => b.id - a.id)
        this.loading = false
      })
    },

    downloadShiftTickets() {
      this.loading = true
      this.fetchAllShiftTicketListByShiftNumber({ cashRegister: this.cashRegister, shiftNumber: this.shiftNumber, size: this.totalPages * this.ticketPage.perPage }).then((result) => {
        try {
          const data = [
            { A: this.$t('название'), B: this.configuration.name },
            { A: this.$t('организация'), B: this.organization.businessName },
            { A: this.$t('бин_иин'), B: this.organization.businessId },
            { A: this.$t('торговая_точка'), B: `${this.cashRegister.pos.title} ${this.cashRegister.pos.address}` },
            { A: this.$t('заводской_номер'), B: this.cashRegister.serialNumber },
            { A: this.$t('модель'), B: 'reKassa 3.0' },
            { A: '' },
            { A: this.$t('смена'), B: `№ ${this.shiftNumber}` },
            { A: this.$t('начало'), B: this.$moment(this.shift.openTime).format('DD.MM.YYYY HH:mm') },
            { A: this.$t('конец'), B: this.shift.closeTime ? this.$moment(this.shift.closeTime).format('DD.MM.YYYY HH:mm') : '' },
            { A: this.$t('выручка_за_смену'), B: this.shift.revenue },
          ]

          if (this.shift.data.totalResult) {
            this.shift.data.totalResult.forEach(operation => {
              if (this.getNumberFromBillsAndCoins(operation.sum) > 0) {
                data.push({ A: this.$t(`operationType.${operation.operation}`), B: this.getNumberFromBillsAndCoins(operation.sum) })
              }
            })
          }

          if (this.shift.data.moneyPlacements) {
            this.shift.data.moneyPlacements.forEach(moneyPlacement => {
              if (this.getNumberFromBillsAndCoins(moneyPlacement.operationsSum) > 0) {
                data.push({ A: this.$t(`operationType.${moneyPlacement.operation}`), B: this.getNumberFromBillsAndCoins(moneyPlacement.operationsSum) })
              }
            })
          }

          data.push({ A: '' })

          data.push({
            A: this.$t('чек'), B: '', C: '', D: '', F: '', G: '', H: '', I: '', J: this.$t('позиции_чека'),
          })

          data.push({
            A: '№',
            B: this.$t('тип'),
            C: this.$t('дата'),
            D: this.$t('фп'),
            E: this.$t('кассир'),
            F: this.$t('иин_бин_покупателя'),
            G: this.$t('скидка_наценка'),
            H: this.$t('итого'),
            I: this.$t('в_т_ч_ндс'),
            J: 'POS',
            K: this.$t('наименование'),
            L: this.$t('штрих_код'),
            M: this.$t('количество'),
            N: this.$t('единица_измерения'),
            O: this.$t('стоимость'),
            P: this.$t('скидка_наценка'),
            Q: this.$t('итого'),
            R: this.$t('в_т_ч_ндс'),
          })

          result.data._embedded.tickets.sort((a, b) => a.id - b.id).forEach(ticket => {
            if (ticket.command === 'COMMAND_TICKET') {
              // Ticket
              const taxes = this.processTicketTaxes(ticket.data.ticket.items)

              ticket.data.ticket.items.filter(item => item.type === 'ITEM_TYPE_COMMODITY').forEach((item, index) => {
                this.processItemCommodity(item)
                this.processItemUnitMarkupDiscount(item)
                this.processItemTax(item)

                let row = {}
                if (index === 0) {
                  row = {
                    A: ticket.shiftDocumentNumber,
                    B: ticket.command === 'COMMAND_TICKET' ? this.$t(`operationType.${ticket.data.ticket.operation}`) : this.$t(`operationType.${ticket.data.moneyPlacement.operation}`),
                    C: this.$moment(ticket.messageTime).format('DD.MM.YYYY HH:mm:ss'),
                    D: ticket.offline ? ticket.offlineTicketNumber : (ticket.ticketNumber ? ticket.ticketNumber : ' '),
                    E: ticket.operator ? (ticket.operator.name ? ticket.operator.name : ticket.operator.code) : '',
                    F: ticket.data.ticket?.extensionOptions?.customerIinOrBin,
                    H: this.getNumberFromBillsAndCoins(ticket.data.ticket.amounts.total),
                  }

                  if (ticket.data.ticket.amounts.markup) {
                    row.G = `+${this.getNumberFromBillsAndCoins(ticket.data.ticket.amounts.markup.sum)}`
                  } else if (ticket.data.ticket.amounts.discount) {
                    row.G = `-${this.getNumberFromBillsAndCoins(ticket.data.ticket.amounts.discount.sum)}`
                  } else {
                    row.G = ' '
                  }

                  let taxesCell = ''
                  taxes.forEach((tax) => {
                    taxesCell += `${tax.value} (${tax.percent}%); `
                  })
                  row.I = taxesCell

                  if (ticket.extra && ticket.extra.attributes && ticket.extra.attributes.paymentType) {
                    row.J = this.$t(ticket.extra.attributes.paymentType)
                  } else {
                    row.J = ' '
                  }
                } else {
                  row = {
                    A: ' ',
                    B: ' ',
                    C: ' ',
                    D: ' ',
                    E: ' ',
                    F: ' ',
                    G: ' ',
                    H: ' ',
                    I: ' ',
                  }
                }

                row.K = `${index + 1}. ${item.commodity.name}`
                row.L = item.commodity.barcode ? item.commodity.barcode : ''
                row.M = new Decimal(item.commodity.quantity).dividedBy(1000).toNumber()

                let unitType
                if (item.commodity.auxiliary) {
                  unitType = item.commodity.auxiliary.find((aux) => aux.key === 'UNIT_TYPE')
                } else {
                  unitType = this.unitTypes.find((ut) => ut.code === item.commodity.measureUnitCode)
                }

                if (unitType) {
                  row.N = this.$t(`unitType.${unitType.value}`)
                } else {
                  row.N = `${this.$t('код_эсф')}: ${item.commodity.measureUnitCode}`
                }

                row.O = this.getNumberFromBillsAndCoins(item.commodity.price)

                if (item.commodity.discountType) {
                  row.P = `-${item.commodity.discountValue}`
                } else if (item.commodity.markupType) {
                  row.P = `+${item.commodity.markupValue}`
                } else {
                  row.P = ' '
                }

                row.Q = item.commodity.total

                if (item.commodity.taxes && item.commodity.taxes[0]) {
                  row.R = `${item.commodity.taxes[0].sum.value} (${item.commodity.taxes[0].percent / 1000}%)`
                }

                data.push(row)
              })
            } else {
              // Money placement
              data.push({
                A: ticket.shiftDocumentNumber,
                B: this.$t(`operationType.${ticket.data.moneyPlacement.operation}`),
                C: this.$moment(ticket.messageTime).format('DD.MM.YYYY HH:mm:ss'),
                D: ticket.offline ? ticket.offlineTicketNumber : (ticket.ticketNumber ? ticket.ticketNumber : ' '),
                E: ticket.operator ? (ticket.operator.name ? ticket.operator.name : ticket.operator.code) : '',
                F: ' ',
                H: this.getNumberFromBillsAndCoins(ticket.data.moneyPlacement.sum),
                I: ' ',
                J: ' ',
                K: ' ',
                L: ' ',
                M: ' ',
                N: ' ',
                O: ' ',
                P: ' ',
                Q: ' ',
                R: ' ',
              })
            }
          })

          const worksheet = XLSX.utils.json_to_sheet(data, { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R'], skipHeader: true })

          const workbook = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(workbook, worksheet, `${this.cashRegister.serialNumber} ${this.$t('смена')} №${this.shift.shiftNumber}`)

          if (this.$isCordova()) {
            const wopts = { bookType: 'xlsx', bookSST: false, type: 'base64' }
            const wbout = XLSX.write(workbook, wopts)

            window.canvas2ImagePlugin.shareFileData(
              () => {
                this.analyticsLogEvent({ eventName: 're_shift_tickets_exported' })
              },
              (error) => {
                this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
              },
              wbout,
              `${this.cashRegister.serialNumber} ${this.$t('смена')} №${this.shift.shiftNumber}.xlsx`,
            )
          } else {
            XLSX.writeFile(workbook, `${this.cashRegister.serialNumber} ${this.$t('смена')} №${this.shift.shiftNumber}.xlsx`)
            this.analyticsLogEvent({ eventName: 're_shift_tickets_exported' })
          }
        } catch (error) {
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        }
        this.loading = false
      })

      this.analyticsLogEvent({ eventName: 're_shift_download_report_click' })
    },

    localStorageGetItem(name) {
      return localStorage.getItem(name)
    },
  },
}
</script>
<style scoped>
.cancelled {
  text-decoration: line-through;
}
</style>
