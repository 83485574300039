import axios from 'axios'
import $http from '../../utils/http'
import i18n from '../../i18n/index'

const cashRegisters = {
  namespaced: true,

  state: {
    cashRegisterItemList: null,
    cashRegisterDeregisteredItemList: null,
    cashRegisterPinChecked: false,
    cashRegister: null,
    cashRegisterTreeViewState: [],
    ticket: null,
    ticketRequest: null,

    ticketPage: {
      current: 1,
      perPage: 10,
    },
    shiftPage: {
      current: 1,
      perPage: 10,
    },
  },

  getters: {
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    setCashRegisterPinChecked({ commit }, payload) {
      commit('set', { type: 'cashRegisterPinChecked', value: payload })
    },

    init({ dispatch, state, rootState }, to) {
      return dispatch('fetchUserCashRegisterList').then(() => {
        // Select non-new cashRegister from parameter
        if (to && to.params && to.params.cashRegisterId && state.cashRegisterItemList.find((el) => String(to.params.cashRegisterId) === String(el.cashRegister.id) && el.cashRegister.status !== 'NEW') !== undefined) {
          return dispatch('selectCashRegister', to.params.cashRegisterId)
        }
        // else select non-new cashRegister from sessionStorage or localStorage
        const serialNumber = sessionStorage.getItem(`rekassa.kz-user-${rootState.auth.user.id}-cashRegisters-cashRegister-serialNumber`) ? sessionStorage.getItem(`rekassa.kz-user-${rootState.auth.user.id}-cashRegisters-cashRegister-serialNumber`) : localStorage.getItem(`rekassa.kz-user-${rootState.auth.user.id}-cashRegisters-cashRegister-serialNumber`)
        const cashRegister = state.cashRegisterItemList.find((el) => serialNumber === el.cashRegister.serialNumber && el.cashRegister.status !== 'NEW')
        if (cashRegister) {
          return dispatch('selectCashRegister', cashRegister.cashRegister.id)
        }
        // else select first non-new cashRegister in list
        const firstNonNewCashRegister = state.cashRegisterItemList.find((el) => el.cashRegister.status !== 'NEW')
        return dispatch('selectCashRegister', firstNonNewCashRegister.cashRegister.id)
      })
    },

    fetchUserCashRegisterList({ commit }) {
      return $http.get('/api/crs').then((result) => {
        commit('set', { type: 'cashRegisterItemList', value: result.data._embedded.userCashRegisterRoles.sort((a, b) => b.cashRegister.id - a.cashRegister.id) })
      })
    },

    fetchUserCashRegisterDeregisteredList({ commit }) {
      return $http.get('/api/crs/archive').then((result) => {
        commit('set', { type: 'cashRegisterDeregisteredItemList', value: result.data._embedded.userCashRegisterRoles })
      })
    },

    selectCashRegister({
      commit,
      dispatch,
      state,
      rootState,
    },
    cashRegisterId) {
      return dispatch('fetchCashRegister', cashRegisterId).then((result) => {
        commit('set', { type: 'cashRegister', value: result.data })
        localStorage.setItem(`rekassa.kz-user-${rootState.auth.user.id}-cashRegisters-cashRegister-serialNumber`, state.cashRegister.cashRegister.serialNumber)
        sessionStorage.setItem(`rekassa.kz-user-${rootState.auth.user.id}-cashRegisters-cashRegister-serialNumber`, state.cashRegister.cashRegister.serialNumber)
        // Reset pages
        commit('set', {
          type: 'shiftPage',
          value: {
            current: 1,
            perPage: 10,
          },
        })
        commit('set', {
          type: 'ticketPage',
          value: {
            current: 1,
            perPage: 10,
          },
        })
      }).catch((error) => {
        if (error && error.response && error.response.status === 404) {
          dispatch('tools/showSnackbar', { message: i18n.t('касса_не_существует_либо_пользователь_не_имеет_доступа_к_кассе') }, { root: true })
          setTimeout(() => {
            window.location.href = window.location.origin
          }, 2000)
        }
      })
    },

    selectCashRegisterForShift({
      commit,
      dispatch,
    },
    cashRegisterId) {
      return dispatch('fetchCashRegister', cashRegisterId).then((result) => {
        commit('set', { type: 'cashRegister', value: result.data })
        // Reset page
        commit('set', {
          type: 'ticketPage',
          value: {
            current: 1,
            perPage: 10,
          },
        })
      })
    },

    fetchCashRegister({ }, cashRegisterId) {
      return $http.get(`/api/crs/${cashRegisterId}/with-roles`)
    },

    checkCashRegisterConnection({ }, cashRegisterId) {
      return $http.post(`/api/crs/${cashRegisterId}/connection-check`)
    },

    setTicketRequest({ commit }, payload) {
      commit('set', { type: 'ticketRequest', value: payload })
    },

    setTicket({ commit }, payload) {
      commit('set', { type: 'ticket', value: payload })
    },

    setCashRegisterTreeViewState({ commit }, payload) {
      commit('set', { type: 'cashRegisterTreeViewState', value: payload })
    },

    fetchTicket({ }, payload) {
      return $http.get(`/api/crs/${payload.cashRegisterId}/tickets/${payload.ticketId}`)
    },

    registerTicket({ }, payload) {
      const cfg = {
        headers: {
          'x-request-id': payload.uuid,
        },
      }
      return $http.post(`/api/crs/${payload.cashRegister.id}/tickets`, payload.ticket, cfg)
    },

    registerCash({ }, payload) {
      const cfg = {
        headers: {
          'x-request-id': payload.uuid,
        },
      }
      return $http.post(`/api/crs/${payload.cashRegister.id}/cash`, payload.cash, cfg)
    },

    shareTicket({ }, payload) {
      return $http.post(`/api/crs/${payload.cashRegister.id}/tickets/${payload.ticket.id}/receipts`, payload.data)
    },

    setTicketBulkAttributes({ }, payload) {
      return $http.post(`/api/crs/${payload.cashRegister.id}/tickets/${payload.ticket.id}/attributes/bulk`, null, payload.cfg)
    },

    fetchShiftList({ state }, payload) {
      return $http.get(`/api/crs/${payload.cashRegister.id}/shifts?includeOpen=false&page=${state.shiftPage.current - 1}&size=${state.shiftPage.perPage}`)
    },

    fetchShiftByNumber({ }, payload) {
      return $http.get(`/api/crs/${payload.cashRegister.id}/shifts/${payload.shiftNumber}`)
    },

    fetchShiftTicketListByShiftNumber({ state }, payload) {
      return $http.get(`/api/crs/${payload.cashRegister.id}/shifts/${payload.shiftNumber}/tickets?page=${state.ticketPage.current - 1}&size=${state.ticketPage.perPage}`)
    },

    fetchAllShiftTicketListByShiftNumber({ }, payload) {
      return $http.get(`/api/crs/${payload.cashRegister.id}/shifts/${payload.shiftNumber}/tickets?page=${0}&size=${payload.size}`)
    },

    setShift({ commit }, payload) {
      commit('set', { type: 'shift', value: payload })
    },

    closeShift({ }, payload) {
      const cfg = {
        headers: {
          'cash-register-password': payload.password,
        },
      }
      return $http.post(`/api/crs/${payload.cashRegister.id}/shifts/${payload.shiftNumber}/close`, {}, cfg)
    },

    fetchXReport({ }, payload) {
      return $http.get(`/api/crs/${payload.cashRegister.id}/shifts/${payload.shiftNumber}/reports/x`)
    },

    fetchSectionsReport({ }, payload) {
      return $http.get(`/api/crs/${payload.cashRegister.id}/shifts/${payload.shiftNumber}/reports/sections`)
    },

    fetchOperatorsReport({ }, payload) {
      return $http.get(`/api/crs/${payload.cashRegister.id}/shifts/${payload.shiftNumber}/reports/operators`)
    },

    savePreferences({ }, payload) {
      return $http.put(`/api/crs/${payload.cashRegister.id}/preferences`, payload.preferences)
    },

    saveConfiguration({ }, payload) {
      const cfg = {
        headers: {
          'cash-register-password': payload.password,
        },
      }
      return $http.put(`/api/crs/${payload.cashRegister.id}/configuration`, payload.configuration, cfg)
    },

    setToken({ }, payload) {
      const cfg = {
        headers: {
          'cash-register-password': payload.password,
        },
      }
      return $http.post(`/api/crs/${payload.cashRegister.id}/set-token`, payload.data, cfg)
    },

    setPassword({ }, payload) {
      return $http.post(`/api/crs/${payload.cashRegister.id}/restore-password`, payload.data)
    },

    checkPassword({ }, payload) {
      return $http.post(`/api/crs/${payload.cashRegister.id}/check-password`, payload.data)
    },

    deregistrateCashRegister({ }, payload) {
      const cfg = {
        headers: {
          'cash-register-password': payload.password,
        },
      }
      return $http.delete(`/api/crs/${payload.cashRegister.id}/deregister`, cfg)
    },

    createNewCashRegister({ }, payload) {
      return $http.post('/api/crs', payload.data)
    },

    deleteNewCashRegister({ }, payload) {
      return $http.delete(`/api/crs/${payload.сashRegister.id}`)
    },

    registerNewCashRegister({ }, payload) {
      return $http.post(`/api/crs/${payload.cashRegister.id}/register`, payload.data)
    },

    restoreCashRegister({ }, payload) {
      return $http.post(`/api/user/${payload.userId}/restore-cash-register-role?fdo=${payload.fdo}&id=${payload.id}&serialNumber=${payload.serialNumber}&token=${payload.token}&password=${payload.password}`)
    },

    fetchFdoProviders({ }) {
      return $http.get('/api/fdo-providers')
    },

    fetchFiscalReport({ }, payload) {
      return $http.get(`/api/crs/${payload.cashRegister.id}/reports/fiscal?fromDate=${payload.fromDate}&toDate=${payload.toDate}`, payload.data)
    },

    // Api partners
    fetchApiPartners({ }) {
      return $http.get('/api/partners')
    },

    fetchApiAccess({ }, payload) {
      return $http.get(`/api/crs/${payload.cashRegister.id}/api-access`)
    },

    grantApiAccess({ }, payload) {
      const cfg = {
        headers: {
          'cash-register-password': payload.password,
        },
      }
      return $http.post(`/api/crs/${payload.cashRegister.id}/api-access?partner=${payload.partner}`, {}, cfg)
    },

    revokeApiAccess({ }, payload) {
      const cfg = {
        headers: {
          'cash-register-password': payload.password,
        },
      }
      return $http.delete(`/api/crs/${payload.cashRegister.id}/api-access?partner=${payload.partner}`, cfg)
    },

    // Invitations
    fetchUserPendingInvitations({ }) {
      return $http.get('/api/invitations/pending')
    },

    acceptInvitation({ }, payload) {
      return $http.put(`/api/invitations/${payload.id}/accept`)
    },

    rejectInvitation({ }, payload) {
      return $http.put(`/api/invitations/${payload.id}/reject`)
    },

    fetchCashRegisterPendingInvitations({ }, payload) {
      return $http.get(`/api/crs/${payload.cashRegister.id}/invitations/pending`)
    },

    fetchCashRegisterUserRoles({ }, payload) {
      return $http.get(`/api/crs/${payload.cashRegister.id}/user-roles`)
    },

    inviteByPhone({ }, payload) {
      const cfg = {
        headers: {
          'cash-register-password': payload.password,
        },
      }
      return $http.post(`/api/crs/${payload.cashRegister.id}/invite-by-phone`, payload.data, cfg)
    },

    sendInvitation({ }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_ROOT_URL}/sms/send-invitation`, payload.data).then((result) => {
          resolve(result.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },

    deleteInvitation({ }, payload) {
      return $http.delete(`/api/invitations/${payload.id}`)
    },

    deleteUserRole({ }, payload) {
      const cfg = {
        headers: {
          'cash-register-password': payload.password,
        },
      }
      return $http.delete(`/api/crs/${payload.cashRegister.id}/user-roles/${payload.id}?role=${payload.role}`, cfg)
    },

    deleteMyRole({ }, payload) {
      return $http.delete(`/api/crs/${payload.cashRegister.id}/user-roles/${payload.id}/disconnect`)
    },
  },
}

export default cashRegisters
