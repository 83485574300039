<template lang="pug">
  v-dialog(v-model="showDialog" scrollable persistent @keydown="handleDialogKeydown($event)" width="500")
    v-card
      v-card-text
        v-form(ref="form")
          v-row
            v-col.py-1(cols="6")
              v-select(:label="$t('тип')" v-model="discountMarkup.type" :items="discountMarkupTypes" item-value="value" item-text="title")
            v-col.py-1(cols="6")
              re-numeric-input-field(:label="$t('discountMarkupType.' + discountMarkup.type)" v-model="discountMarkup.valueOrPecentage" limit-decimal="2" :suffix="discountMarkupValueTitles.suffix" :rules="[rules.numberRange(0, 100000000, discountMarkup.valueOrPecentage)]" validate-on-blur autocomplete="off" :autofocus="!$isCordova()")
            v-col.py-1(cols="6" offset="6")
              v-text-field(:label="$t('итого')" color="primary" persistent-hint :value="`${$options.filters.numeral(totalWithDiscountMarkup, '0,0.[00]')}`" :rules="[rules.numberRange(1, 1000000000, totalWithDiscountMarkup)]" suffix="₸" disabled outlined)

      v-divider.item-divider
      v-card-actions
        v-spacer
        v-btn(text @click="showDialog = false") {{ $t("отменить") }}
        v-btn(color="primary" text @click="saveDiscountMarkup()") {{ $t("сохранить") }}

</template>
<script>
import { Decimal } from 'decimal.js'
import NumericInputField from '../../utils/NumericInputField.vue'
import dictionaryMixin from '../../../mixins/dictionaryMixin'

export default {
  components: {
    're-numeric-input-field': NumericInputField,
  },

  mixins: [dictionaryMixin],

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    total: {
      type: Number,
      default: null,
      required: true,
    },
    discountMarkup: {
      type: Object,
      default: null,
      required: true,
    },
  },

  data: () => ({
    showDialog: false,
  }),

  computed: {
    totalWithDiscountMarkup() {
      let totalWithDiscountMarkup = this.total
      if (this.discountMarkup.valueOrPecentage > 0) {
        switch (this.discountMarkup.type) {
          case 'DISCOUNT_PERCENTAGE':
            totalWithDiscountMarkup = new Decimal(this.total).minus(new Decimal(this.total).dividedBy(100).times(this.discountMarkup.valueOrPecentage)).toNumber().toFixed(2)
            break
          case 'DISCOUNT_CURRENCY':
            totalWithDiscountMarkup = new Decimal(this.total).minus(this.discountMarkup.valueOrPecentage).toNumber()
            break
          case 'MARKUP_PERCENTAGE':
            totalWithDiscountMarkup = new Decimal(this.total).plus(new Decimal(this.total).dividedBy(100).times(this.discountMarkup.valueOrPecentage)).toNumber().toFixed(2)
            break
          case 'MARKUP_CURRENCY':
            totalWithDiscountMarkup = new Decimal(this.total).plus(this.discountMarkup.valueOrPecentage).toNumber()
            break
          default:
            break
        }
      }
      return totalWithDiscountMarkup
    },

    discountMarkupValueTitles() {
      return this.discountMarkupTypes.find((item) => item.value === this.discountMarkup.type)
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.showDialog = true
      }
    },

    showDialog(value) {
      if (!value) {
        this.$emit('input', false)
      }
    },
  },

  methods: {
    saveDiscountMarkup() {
      if (this.$refs.form.validate()) {
        switch (this.discountMarkup.type) {
          case 'DISCOUNT_PERCENTAGE':
            this.discountMarkup.sum = new Decimal(new Decimal(this.total).dividedBy(100).times(this.discountMarkup.valueOrPecentage)).toNumber().toFixed(2)
            this.discountMarkup.auxiliary = {
              key: 'DISCOUNT',
              value: `${this.discountMarkup.valueOrPecentage}%`,
            }
            break
          case 'DISCOUNT_CURRENCY':
            this.discountMarkup.sum = new Decimal(this.discountMarkup.valueOrPecentage).toNumber()
            this.discountMarkup.auxiliary = {
              key: 'DISCOUNT',
              value: `${this.discountMarkup.valueOrPecentage}`,
            }
            break
          case 'MARKUP_PERCENTAGE':
            this.discountMarkup.sum = new Decimal(this.total).dividedBy(100).times(this.discountMarkup.valueOrPecentage).toNumber()
              .toFixed(2)
            this.discountMarkup.auxiliary = {
              key: 'MARKUP',
              value: `${this.discountMarkup.valueOrPecentage}%`,
            }
            break
          case 'MARKUP_CURRENCY':
            this.discountMarkup.sum = new Decimal(this.discountMarkup.valueOrPecentage).toNumber()
            this.discountMarkup.auxiliary = {
              key: 'MARKUP',
              value: `${this.discountMarkup.valueOrPecentage}`,
            }
            break
          default:
            break
        }

        this.$emit('saveDiscountMarkup', this.discountMarkup)
        this.showDialog = false
      }
    },

    handleDialogKeydown(event) {
      if (event.keyCode === 13) {
        this.saveDiscountMarkup()
      }
    },
  },
}
</script>
<style lang="stylus">
</style>
