<template lang="pug">
v-app
  v-app-bar(app dark dense flat)
    v-btn(icon @click="$router.push('/services')")
      v-icon mdi-arrow-left
    v-toolbar-title {{ $t("FREEDOM_BOX") }}
    v-spacer
    template(v-if="user._links.createCashRegister && cashRegister.status === 'REGISTERED'")
      v-btn(@click="showQR()" text) {{ $t("qr_код") }}

  v-main(flat)
    v-container.pt-0(style="max-width: 450px")
      v-row
        v-col.pa-0.pt-sm-3(align="center")
          v-img(:src="`/static/freedom-box-banner-${locale}.png`" width="100%" eager)

      v-row
        v-col
          .display-1.my-2 {{ $t("FREEDOM_BOX") }}
          .subtitle-1.my-2(style="width: 290px;") {{ $t("пакет_услуг_для_ип_без_сбора_документов_и_походов_в_банк") }}

      v-row
        v-col.pt-0
          v-list.v-list--fulltext.gray-background-list.rounded-t-lg.rounded-b-lg
            v-list-item
              v-list-item-icon.ml-1.mr-6.my-0.align-self-center
                v-icon mdi-calculator
              v-list-item-content
                v-list-item-title {{ $t("работает_напрямую_с_rekassa_в_pos_терминале") }}
                v-list-item-subtitle.caption {{ $t("принимайте_платежи_со_сниженной_комиссией_05") }}
            v-divider
            v-list-item
              v-list-item-icon.ml-1.mr-6.my-0.align-self-center
                v-icon mdi-gift-outline
              v-list-item-content
                v-list-item-title {{ $t("бесплатный_pos_терминал_в_день_подключения") }}
                v-list-item-subtitle.caption {{ $t("интеграция_с_rekassa_и_подача_заявки_онлайн") }}
            v-divider
            v-list-item
              v-list-item-icon.ml-1.mr-6.my-0.align-self-center
                v-icon mdi-brightness-percent
              v-list-item-content
                v-list-item-title {{ $t("подключение_рассрочки_для_ваших_клиентов_с_терминала") }}
                v-list-item-subtitle.caption {{ $t("до_1_млн_тенге_от_7_со_сроком_от_3_до_24_месяцев") }}
            v-divider
            v-list-item
              v-list-item-icon.ml-1.mr-6.my-0.align-self-center
                v-icon mdi-storefront-outline
              v-list-item-content
                v-list-item-title {{ $t("предоставляем_кредитную_линию_для_бизнеса_овердрафт") }}
                v-list-item-subtitle.caption {{ $t("до_20_млн_тенге_до_60_дней_льготный_период_под_37") }}

      v-row
        v-col
          .subtitle-1.primary--text.text-uppercase(@click="$openLink(`https://link.rekassa.kz/freedom-box-about-${locale}`, '_system')") {{ $t("подробнее") }}...

      v-row
        v-col
          .body-2 {{ $t("при_подключении_вы_получите_карту_от_freedom_bank") }}
          .body-2.mb-2 {{ $t("выпуск_и_обслуживание_карты_бесплатно") }}

      v-row
        v-col
          v-form(ref="form")
            v-text-field(ref="iinInput" :label="$t('иин')" v-model="iin" @input="iinError = null" :error-messages="iinError" v-mask="'### ### ### ###'" placeholder="### ### ### ###" type="tel" clearable)
            v-text-field(:label="$t('номер_телефона')" v-model="phoneNumber" @input="phoneNumberError = null" :error-messages="phoneNumberError" v-mask="'+7 (###) ###-##-##'" placeholder="+7 (###) ###-##-##" type="tel" clearable)
            v-text-field(:label="$t('код_агента')" v-model="agentCode" @input="agentCodeError = null" :error-messages="agentCodeError" v-mask="'+7 (###) ###-##-##'" placeholder="+7 (###) ###-##-##" type="tel" persistent-hint :hint="$t('укажите_если_подключаетесь_через_агента')" clearable)

            v-btn.mt-8(color="primary" block @click="submitApplication()") {{ $t("продолжить") }}

      v-row.mt-2
        v-col
          .body-2 * {{ $t("услуга_доступна_для_городов_актау_актобе_алматы_астана_атырау_караганда_кокшетау_костанай_павлодар_тараз_усть_каменогорск_шымкент") }}

  re-pinpad(v-model="grantApiAccessPinDialog" :title="$t('получить_qr_код')" :subtitle="$t('введите_пин-код')" :text="$t('для_получения_qr_кода')" :loading="loading" :errorText.sync="grantApiAccessPinErrorMessage" @action="grant")

  v-dialog(v-model="showQRDialog" persistent max-width="400px")
    v-card
      v-card-title.pb-0
        span.title {{ $t("откройте_rekassa_на_pos_терминале_и_нажмите_на_кнопку_сканировать_qr_код") }}
      v-card-text.py-0
        v-container.px-0
          v-row.pt-3(row wrap justify="center")
            div.qrcode(style="background: #fffffe; background-image: linear-gradient(#fffffe, #fffffe);")
              qrcode(:value="posCredentials" size="200" level="L" renderAs="svg")

      v-card-actions
        v-spacer
        v-btn.mb-3(text @click="showQRDialog = false" :disabled="loading") {{ $t("закрыть") }}

</template>
<script>
import axios from 'axios'
import qrcode from 'qrcode.vue'
import { mapState, mapActions } from 'vuex'
import Pinpad from '../utils/PinpadDialog.vue'
import { getLocale } from '../../i18n'

export default {
  components: {
    qrcode,
    're-pinpad': Pinpad,
  },

  data: () => ({
    iin: null,
    iinError: null,
    phoneNumber: null,
    phoneNumberError: null,
    agentCode: null,
    agentCodeError: null,

    loading: false,
    grantApiAccessPinDialog: false,
    grantApiAccessPinErrorMessage: null,
    showQRDialog: false,
    posCredentials: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
    }),

    locale() {
      return getLocale()
    },
  },

  created() {
    if (this.cashRegister.status !== 'TRIAL') {
      this.iin = this.organization.businessId
    }
    this.phoneNumber = this.user.phone
  },

  methods: {
    ...mapActions({
      fetchApiAccess: 'cashRegisters/fetchApiAccess',
      grantApiAccess: 'cashRegisters/grantApiAccess',
      showSnackbar: 'tools/showSnackbar',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    submitApplication() {
      if (this.iin?.length !== 15) {
        this.iinError = this.$t('введите_иин')
        return
      }
      if (this.phoneNumber?.length !== 18) {
        this.phoneNumberError = this.$t('введите_номер_телефона')
        return
      }
      if (this.agentCode && this.agentCode.length !== 18) {
        this.agentCodeError = this.$t('введите_код_агента')
        return
      }
      this.$openLink(`https://overdraft.bankffin.kz/overdraft/start?iin=${this.iin.replace(/\D/g, '')}&phoneNum=${this.phoneNumber.replace(/\D/g, '')}&manager=rekassa${this.agentCode ? this.agentCode.replace(/\D/g, '') : ''}`, '_system')

      const url = 'https://api.sheetson.com/v2/sheets/freedomBox'
      const data = {
        iin: this.iin.replace(/\D/g, ''),
        phone: this.phoneNumber.replace(/\D/g, ''),
        agent: this.agentCode ? this.agentCode.replace(/\D/g, '') : '',
        date: this.$moment().format('DD-MM-YYYY HH:mm:ss'),
      }
      const config = {
        headers: {
          Authorization: 'Bearer WL6r-WEd2IPRoiBnA80pMaQYA1UwLPOtJlKx_hOYOGs7UOK2kgVd_v2ErE0',
          'X-Spreadsheet-Id': process.env.VUE_APP_TEST === 'true' ? '1iQLcY2kUUKADjhE2DBEsUE6H1qVIrbQdaNRf3VE9SKM' : '16axmlpleBzFfesU0r3BVJopn4lc1Fx5nZuz-gqVbe5E',
          'Content-Type': 'application/json',
        },
      }
      axios.post(url, data, config)
        .then((response) => {
          console.debug(response.data)
        })
        .catch((error) => {
          console.error(error)
        })

      this.$router.push('/')
      this.analyticsLogEvent({ eventName: 're_freedom_box_form_submitted' })
    },

    showQR() {
      const ps = localStorage.getItem(`rekassa.kz-cashRegister-${this.cashRegister.id}-auth-ffb-pos-credentials`)
      if (ps) {
        this.posCredentials = ps
        this.showQRDialog = true
      } else {
        this.grantApiAccessPinDialog = true
      }
    },

    grant(pincode) {
      this.loading = true
      this.grantApiAccess({
        partner: 'FFTERMINAL',
        cashRegister: this.cashRegister,
        password: pincode,
      }).then((result) => {
        this.loading = false
        this.grantApiAccessPinDialog = false
        localStorage.setItem(`rekassa.kz-cashRegister-${this.cashRegister.id}-auth-ffb-pos-credentials`, JSON.stringify({ n: this.cashRegister.serialNumber, p: result.data.password }))
        this.showQR()
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.grantApiAccessPinErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else {
          this.showSnackbar({ message: this.$t('произошла_ошибка', { error }) })
          this.loading = false
          this.grantApiAccessPinDialog = false
        }
      })
    },

  },
}
</script>

  <style lang="stylus">
  </style>
