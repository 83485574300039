<template lang="pug">
v-app
  re-activation-abuse(v-if="cashRegister.status === 'TRIAL'")
  re-ofd-payment-notifier(v-if="cashRegister.status === 'REGISTERED'")
  v-app-bar(app dark dense flat v-if="!$vuetify.breakpoint.mdAndUp && cashRegister.status !== 'TRIAL'")
    v-spacer
    div.title.d-inline.text-truncate.cursor-pointer(@click="cashRegisterSwitch()") {{ cashRegister.name ? cashRegister.name : organization.businessName }}
    v-spacer

  v-app-bar(app dark flat v-if="$vuetify.breakpoint.mdAndUp")
    span.re-topbar-container
      img.re-topbar-left(src="/static/logo-black.png" width="90")

      span.re-app-bar-btn.re-topbar-center
        v-btn(id="mainNavBtn" to="/" @click="analyticsLogEvent({ eventName: 're_nav_main_button_click' })" text v-if="!hideDashboard")
          v-icon(left) mdi-home
          span {{ $t("главная") }}
        v-btn(id="shiftsNavBtn" :to="`/shifts/${this.cashRegister.id}`" @click="analyticsLogEvent({ eventName: 're_nav_sales_button_click' })" text)
          v-icon(left) mdi-finance
          span {{ $t("продажи") }}
        v-btn(id="kkmNavBtn" to="/kkm" @click="analyticsLogEvent({ eventName: 're_nav_cash_register_button_click' })" text)
          v-icon(left) mdi-calculator
          span {{ $t("касса") }}
        v-btn(id="messagesNavBtn" @click="showChat()" text)
          v-icon(left) mdi-chat
          span {{ $t("сообщения") }}
          v-badge(color="red" dot bordered offset-x="89" offset-y="-3" :value="helpCrunchUnreadCount > 0")
        v-btn(id="settingsNavBtn" to="/settings" @click="analyticsLogEvent({ eventName: 're_nav_settings_button_click' })" text)
          v-icon(left) mdi-cog-outline
          span {{ $t("настройки") }}

      span.re-topbar-right
        div.title.d-inline.cursor-pointer(@click="cashRegisterSwitch()") {{ cashRegister.name ? cashRegister.name : organization.businessName }}

  v-main
    v-progress-linear(:active="loading" :indeterminate="loading" absolute top :style="$vuetify.breakpoint.mdAndUp ? 'z-index: 5;' : 'top: -4px; z-index: 5;'")
    div(:class="{ curtain: cashRegister.status === 'DEREGISTERED' && ($route.path === '/' || $route.path === '/kkm')}")
    router-view

  v-bottom-navigation.re-nav(app dark color="#21BD98" v-if="!$vuetify.breakpoint.mdAndUp")
    v-btn(id="mainNavBtn" to="/" @click="analyticsLogEvent({ eventName: 're_nav_main_button_click' })" style="width: 75px;" v-if="!hideDashboard")
      span {{ $t("главная") }}
      v-icon mdi-home
    v-btn(id="shiftsNavBtn" :to="`/shifts/${this.cashRegister.id}`" @click="analyticsLogEvent({ eventName: 're_nav_sales_button_click' })" style="width: 75px;")
      span {{ $t("продажи") }}
      v-icon mdi-finance
    v-btn(id="kkmNavBtn" :class="{'rekassa-nav-btn' : !hideDashboard}" to="/kkm" @click="analyticsLogEvent({ eventName: 're_nav_cash_register_button_click' })" style="width: 75px;")
      span {{ $t("касса") }}
      v-icon mdi-calculator
    v-btn(id="messagesNavBtn" @click="showChat()" style="color: #C9C9C9; width: 75px;")
      span {{ $t("сообщения") }}
      v-icon mdi-chat
      v-badge(color="red" dot bordered offset-x="-5" offset-y="9" :value="helpCrunchUnreadCount > 0")
    v-btn(id="settingsNavBtn" to="/settings" @click="analyticsLogEvent({ eventName: 're_nav_settings_button_click' })" style="width: 75px;")
      span {{ $t("настройки") }}
      v-icon mdi-cog-outline

  re-cash-registers(@cashRegisterLoading="cashRegisterLoading")

  re-ofd-registration(@showHideLockDialog="showHideLockDialog")

  re-authentication-module

  v-dialog(v-model="showLockDialog" persistent width="100")
    v-container
      v-progress-circular(indeterminate color="white")

</template>
<script>
import { mapState, mapActions } from 'vuex'
import OFDPaymentNotifier from './utils/OFDPaymentNotifier.vue'
import ActivationAbuse from './utils/ActivationAbuse.vue'
import CashRegisters from './settings/CashRegisters.vue'
import OFDRegistration from './utils/OFDRegistration.vue'
import AuthenticationModule from './utils/AuthenticationModule.vue'
import devicePush from '../utils/devicePush'
import webPush from '../utils/webPush'

export default {
  components: {
    're-ofd-payment-notifier': OFDPaymentNotifier,
    're-activation-abuse': ActivationAbuse,
    're-cash-registers': CashRegisters,
    're-ofd-registration': OFDRegistration,
    're-authentication-module': AuthenticationModule,
  },

  data: () => ({
    loading: false,
    showLockDialog: false,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      helpCrunchUnreadCount: state => state.tools.helpCrunchUnreadCount,
      appInitialized: state => state.tools.appInitialized,
    }),

    // Если FreedomBox или Мой учет iframe то скрываем главную страницу
    hideDashboard() {
      return process.env.VUE_APP_FFB_POS_MODE === 'true' || window.location !== window.parent.location
    },
  },

  created() {
    this.loading = true
    this.selectCashRegister(this.cashRegister.id).then(() => {
      this.loading = false
    }).catch(() => {
      this.loading = false
    })

    // AppInitialize
    if (!this.appInitialized) {
      // HelpCrunch setUserId
      if (this.$isCordova()) {
        window.cordova.plugins.HelpCrunchPlugin.updateUser(() => {}, () => {}, {
          userId: this.user.phone,
        })
      } else {
        window.HelpCrunch('userAuth', { email: this.user.phone, user_id: this.user.phone })
      }

      // Analytics setUserId
      this.analyticsSetUserId(this.user.phone)

      // App rate
      if (!this.$isFFBMode() && this.$isCordova()) {
        const appLaunchCount = parseInt(localStorage.getItem('rekassa.kz-tools-appLaunchCount') || 0, 10)
        const appRated = (localStorage.getItem('rekassa.kz-tools-appRated') || 'false') === 'true'
        if (!appRated && appLaunchCount > 30) {
          setTimeout(() => {
            localStorage.setItem('rekassa.kz-tools-appRated', 'true')
            window.cordova.plugins.AppReview.requestReview()
          }, 2000)
        }
      }

      // Analytics setUserProperty fdo_type
      if (this.cashRegister.status === 'REGISTERED') {
        this.analyticsSetUserProperty({ key: 'fdo_type', value: this.cashRegister.fdo })
      }

      // Push notification subscribe
      if (this.$isCordova()) {
        devicePush.init()
      } else {
        webPush.init()
      }

      this.setAppInitialized(true)
    }
  },

  methods: {
    ...mapActions({
      selectCashRegister: 'cashRegisters/selectCashRegister',
      analyticsSetUserProperty: 'analytics/setUserProperty',
      analyticsSetUserId: 'analytics/setUserId',
      setAppInitialized: 'tools/setAppInitialized',
      showCashRegisterSwitch: 'tools/showCashRegisterSwitch',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    cashRegisterSwitch() {
      this.showCashRegisterSwitch(true)
      this.analyticsLogEvent({ eventName: 're_nav_cash_register_switch_clicked' })
    },

    cashRegisterLoading(status) {
      this.showLockDialog = status
    },

    showChat() {
      if (!this.$isCordova()) {
        window.HelpCrunch('showChatWidget')
        window.HelpCrunch('openChat')
      } else {
        window.cordova.plugins.HelpCrunchPlugin.showChatScreen(() => {}, () => {})
      }
      this.analyticsLogEvent({ eventName: 're_nav_helpcrunch_button_click' })
    },

    showHideLockDialog(value) {
      this.showLockDialog = value
    },
  },
}

</script>
<style lang="stylus">
.re-app-bar-btn
  > .v-btn
    text-transform capitalize !important
    font-weight 500 !important
    font-size 16px !important
    letter-spacing 0.1px !important
  .v-icon--left
    margin-right 3px !important
  .v-btn:before
    opacity 0 !important
  .v-btn--active
    color #21BD98 !important
.re-nav
  .v-btn > .v-btn__content .v-icon
    padding-bottom 3px !important
.re-nav.v-item-group.v-bottom-navigation .v-btn
  min-width 0 !important
  font-weight 500 !important
  font-size 12px !important
  letter-spacing 0.2px !important

.re-topbar-container
  position relative
  text-align center
  width 100%
.re-topbar-left
  position absolute
  left 0
  top 50%
  transform translateY(-50%)
.re-topbar-center
  display inline-block
.re-topbar-right
  position absolute
  right 0
  top 50%
  transform translateY(-50%)
  text-align right
  width calc((100vw - 558px) / 2 - 30px)
  white-space nowrap !important
  overflow hidden !important
  text-overflow ellipsis !important

.rekassa-nav-btn
  .v-btn__content
    height 90px !important
  > span
      position relative
      top -11px
  .v-icon
    background #035D56
    border-radius: 50%;
    width 50px
    height 50px
    position relative
    top -5px
  .v-ripple__container
    display none
</style>
