<template lang="pug">
v-bottom-sheet(v-model="miniAppBottomSheet" fullscreen)
  v-sheet
    iframe.re-fullscreen-iframe(ref="miniAppIframe" :src="miniAppIframeSrc")
</template>

<script>
import { mapActions, mapState } from 'vuex'
import $http from '../../utils/http'
import { getLocale } from '../../i18n'

export default {
  name: 'MiniApp',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
      required: true,
    },
  },

  data() {
    return {
      miniAppBottomSheet: false,
      miniAppIframeSrc: null,
      miniAppIAB: null,
      miniAppTimerId: null,
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
    }),
  },

  watch: {
    value(value) {
      if (value) {
        this.open()
      }
    },
  },

  methods: {
    ...mapActions({
      analyticsLogEvent: 'analytics/logEvent',
      copyToClipboard: 'tools/copyToClipboard',
      showSnackbar: 'tools/showSnackbar',
    }),

    open() {
      const url = new URL(this.url)
      url.searchParams.append('phone', this.user.phone)
      url.searchParams.append('lang', getLocale())
      url.searchParams.append('isApp', this.$isCordova())
      url.searchParams.append('iinBin', this.organization.businessId)
      url.searchParams.append('cashRegisterId', this.cashRegister.id)

      if (this.$isCordova()) {
        this.miniAppIAB = undefined
        this.miniAppIAB = window.cordova.InAppBrowser.open(url.toString(), '_blank', 'location=no,toolbar=no,hidenavigationbuttons=yes,hideurlbar=yes,zoom=no')
        this.miniAppIAB.addEventListener('message', this.onMessage)
        this.miniAppIAB.addEventListener('exit', this.onExit)
      } else {
        window.addEventListener('message', this.onMessage, false)
        this.miniAppIframeSrc = url.toString()
        this.miniAppBottomSheet = true
      }

      this.miniAppTimerId = setTimeout(() => {
        this.close()
        this.showSnackbar({ message: this.$t('произошла_ошибка') })
      }, 20000)
    },

    onMessage(event) {
      if (event.data && (this.$isCordova() || this.checkOrigin(event.origin, this.url))) {
        console.debug(event)

        const { id, method, param } = this.$isCordova() ? event.data : JSON.parse(event.data)

        try {
          // loaded
          if (method === 'loaded') {
            if (this.miniAppTimerId) clearTimeout(this.miniAppTimerId)
            this.postMessage(id)
          }

          // urlParams
          if (method === 'urlParams') {
            const urlParams = {
              phone: this.user.phone,
              lang: getLocale(),
              isApp: this.$isCordova(),
            }
            urlParams.iinBin = this.organization.businessId
            urlParams.cashRegisterId = this.cashRegister.id
            this.postMessage(id, urlParams)
          }

          // close
          if (method === 'close') {
            this.close()
            this.postMessage(id)
          }

          // customToken
          if (method === 'customToken') {
            $http.post(`/api/auth/app/login?app=${this.name}`).then((response) => {
              this.postMessage(id, response.data)
            }).catch((error) => {
              console.error(error)
            })
          }

          // barcode
          if (method === 'barcode') {
            const aspectRatio = parseInt(localStorage.getItem('rekassa.kz-ui-camera-aspectRatio') || 1, 10)
            window.cordova.plugins.rekassaBarcode.scan((result) => {
              this.postMessage(id, result)
            },
            () => {}, { aspectRatio })
            this.postMessage(id)
          }

          // analytics
          if (method === 'analytics') {
            this.analyticsLogEvent({ eventName: param })
            this.postMessage(id)
          }

          // href
          if (method === 'href') {
            this.$openLink(param, '_system')
            this.postMessage(id)
          }

          // share
          if (method === 'share') {
            if (navigator.share) {
              navigator.share({
                title: this.$t('отправить_ссылку'),
                text: param,
              })
            } else {
              this.copyToClipboard(param)
            }
            this.postMessage(id)
          }

          // clipboard
          if (method === 'clipboard') {
            this.copyToClipboard(param)
            this.postMessage(id)
          }

          // appInstalled
          if (method === 'appInstalled' && this.$isCordova()) {
          // TO-DO
          }

          // finish
          if (method === 'finish') {
            // TO-DO
          }
        } catch (error) {
          console.error(error)
          this.postMessage(id, error)
        }
      }
    },

    onExit() {
      this.miniAppIAB.removeEventListener('message', this.onMessage)
      this.miniAppIAB = undefined
      this.$emit('input', false)
    },

    close() {
      if (this.$isCordova()) {
        this.miniAppIAB.close()
      } else {
        this.miniAppBottomSheet = false
        this.miniAppIframeSrc = null
        window.removeEventListener('message', this.onMessage, false)
      }
      this.$emit('input', false)
    },

    checkOrigin(origin, url) {
      try {
        const parsedUrl = new URL(url)
        return parsedUrl.origin === origin
      } catch (e) {
        console.error('Invalid URL:', e)
        return false
      }
    },

    postMessage(id, result) {
      if (this.$isCordova()) {
        this.miniAppIAB.executeScript({ code: `window.onPostMessage('${id}', '${JSON.stringify(result)}');` })
      } else {
        this.$refs.miniAppIframe.contentWindow.postMessage(JSON.stringify({ id, result }), this.url)
      }
    },

  },

}
</script>

<style lang="stylus">
</style>
