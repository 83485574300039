<template lang="pug">
v-app
  v-app-bar(app dark dense flat)
    v-btn(icon @click="$router.push('/services')")
      v-icon mdi-arrow-left
    v-toolbar-title {{ $t("FREEDOM_CREDIT") }}

  v-main(flat)
    v-container.pt-0(style="max-width: 450px")
      v-row
        v-col.pa-0.pt-sm-3(align="center")
          v-img(:src="`/static/freedom-credit-banner-${locale}.png`" width="100%" eager)

      v-row
        v-col
          .display-1.my-2 {{ $t("FREEDOM_CREDIT") }}
          .subtitle-1.my-2 {{ $t("денежный_займ") }}

      v-row
        v-col.pt-0
          v-list.v-list--fulltext.gray-background-list.rounded-t-lg.rounded-b-lg
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-brightness-percent
              v-list-item-content
                v-list-item-title {{ $t("сумма_от_50000_до_5_млн_тенге") }}
            v-divider
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-calendar
              v-list-item-content
                v-list-item-title {{ $t("срок_от_6_до_36_месяцев") }}
            v-divider
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-credit-card-outline
              v-list-item-content
                v-list-item-title {{ $t("выплата_онлайн_на_карту") }}

      v-row
        v-col
          .subtitle-1.primary--text.text-uppercase(@click="$openLink(`https://link.rekassa.kz/freedom-mfo-about-${locale}`, '_system')") {{ $t("подробнее") }}...

      v-row
        v-col
          .body-2 {{ $t("после_оформления_у_вас_появится_личный_кабинет_freedom_finance_credit_где_вы_сможете_смотреть_всю_информацию_о_суммах_датах_оплаты_и_способах_погашения") }}
          .body-2.mt-4.mb-2 {{ $t("кредит_могут_оформить") }}
          ul
            li.body-2 {{ $t("физические_лица_являющиеся_гражданами_рк_достигшие_18_летнего_возраста") }}
            li.body-2 {{ $t("юридические_лица_зарегистрированные_и_осуществляющие_свою_деятельность_в_соответствии_с_законодательством_рк") }}

      v-row
        v-col
          v-form(ref="form")
            v-text-field(ref="iinInput" :label="$t('иин')" v-model="iin" @input="iinError = null" :error-messages="iinError" v-mask="'### ### ### ###'" placeholder="### ### ### ###" type="tel" clearable)
            v-text-field(:label="$t('номер_телефона')" v-model="phoneNumber" @input="phoneNumberError = null" :error-messages="phoneNumberError" v-mask="'+7 (###) ###-##-##'" placeholder="+7 (###) ###-##-##" type="tel" clearable)
            v-text-field(:label="$t('код_агента')" v-model="agentCode" @input="agentCodeError = null" :error-messages="agentCodeError" v-mask="'+7 (###) ###-##-##'" placeholder="+7 (###) ###-##-##" type="tel" persistent-hint :hint="$t('укажите_если_подключаетесь_через_агента')" clearable)

            v-btn.mt-4(color="primary" block @click="submitApplication()") {{ $t("продолжить") }}
</template>
<script>
import axios from 'axios'
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../../i18n'

export default {
  data: () => ({
    iin: null,
    iinError: null,
    phoneNumber: null,
    phoneNumberError: null,
    agentCode: null,
    agentCodeError: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
    }),

    locale() {
      return getLocale()
    },
  },

  created() {
    if (this.cashRegister.status !== 'TRIAL') {
      this.iin = this.organization.businessId
    }
    this.phoneNumber = this.user.phone
  },

  methods: {
    ...mapActions({
      showSnackbar: 'tools/showSnackbar',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    submitApplication() {
      if (this.iin?.length !== 15) {
        this.iinError = this.$t('введите_иин')
        return
      }
      if (this.phoneNumber?.length !== 18) {
        this.phoneNumberError = this.$t('введите_номер_телефона')
        return
      }
      if (this.agentCode && this.agentCode.length !== 18) {
        this.agentCodeError = this.$t('введите_код_агента')
        return
      }

      this.$openLink(`https://ffin.credit/apply/goods?partner_id=REKASSA&iin=${this.iin.replace(/\D/g, '')}&phone=${this.phoneNumber.replace(/\D/g, '')}`, '_system')

      const url = 'https://api.sheetson.com/v2/sheets/freedomCredit'
      const data = {
        iin: this.iin.replace(/\D/g, ''),
        phone: this.phoneNumber.replace(/\D/g, ''),
        agent: this.agentCode ? this.agentCode.replace(/\D/g, '') : '',
        date: this.$moment().format('DD-MM-YYYY HH:mm:ss'),
      }
      const config = {
        headers: {
          Authorization: 'Bearer WL6r-WEd2IPRoiBnA80pMaQYA1UwLPOtJlKx_hOYOGs7UOK2kgVd_v2ErE0',
          'X-Spreadsheet-Id': process.env.VUE_APP_TEST === 'true' ? '1RP2Xw0S0witi_PnBbOcg7YxQS1o4sbaYiNwQx3dpw9k' : '19a6g02_JzObf68vozNsYnD6q9xuAqmv1NeoO-k4ieyg',
          'Content-Type': 'application/json',
        },
      }
      axios.post(url, data, config)
        .then((response) => {
          console.debug(response.data)
        })
        .catch((error) => {
          console.error(error)
        })

      this.$router.push('/')
      this.analyticsLogEvent({ eventName: 're_freedom_credit_form_submitted' })
    },

  },
}
</script>

<style lang="stylus">
</style>
