<template lang="pug">
v-app
  v-app-bar(app dark dense flat)
    v-btn(icon @click="$router.push('/settings')")
      v-icon mdi-arrow-left
    v-toolbar-title {{ $t("настройки_интерфейса") }}

  v-main(flat)
    v-container(style="max-width: 450px")
      v-row
        v-col
          v-list.v-list--fulltext.gray-background-list
            v-subheader {{ $t("настройки") }}
            v-list-item(@click="openHomePageTypeDialog()")
              v-list-item-content
                v-list-item-title {{ $t("стартовая_страница") }}
              v-list-item-content.text-align-end
                v-list-item-action-text.subtitle-1  {{ $t("homePageType." + homePageType) }}
            v-list-item(@click="openLocaleTypeDialog()")
              v-list-item-content
                v-list-item-title {{ $t("язык") }}
              v-list-item-content.text-align-end
                v-list-item-action-text.subtitle-1 {{ dictionary.localeType[locale].title }}
            v-list-item(@click="openCashRegistersViewModeDialog()")
              v-list-item-content
                v-list-item-title {{ $t("отображать_кассы") }}
              v-list-item-content.text-align-end
                v-list-item-action-text.subtitle-1 {{ $t("сashRegistersViewMode." + сashRegistersViewMode) }}
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("темная_тема") }}
              v-list-item-action
                v-switch(color="teal" :input-value="darkTheme" @change="darkThemeChanged")

    v-dialog(v-model="localeTypeDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_язык") }}
        v-card-text
          v-container
            v-form.mt-3(ref="localeTypeForm" @submit.prevent="saveLocaleType()")
              v-radio-group(v-model="localeTypeRadio")
                v-radio(v-for="(item, $index) in localeTypes" :key="'l_' + $index" :label="item.title" :value="item.value")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="localeTypeDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveLocaleType()") {{ $t("сохранить") }}

    v-dialog(v-model="homePageTypeDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_стартовую_страницу") }}
        v-card-text
          v-container
            v-form.mt-3(ref="homePageTypeRadio" @submit.prevent="saveHomePageType()")
              v-radio-group(v-model="homePageTypeRadio")
                v-radio(:label="$t('homePageType.MAIN')" value="MAIN")
                v-radio(:label="$t('homePageType.CALCULATOR')" value="CALCULATOR")
                v-radio(:label="$t('homePageType.POSITIONS')" value="POSITIONS")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="homePageTypeDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveHomePageType()") {{ $t("сохранить") }}

    v-dialog(v-model="cashRegistersViewModeDialog" max-width="400px")
      v-card
        v-card-title.pb-0
          span.headline {{ $t("выберите_отображение_касс") }}
        v-card-text
          v-container
            v-form.mt-3(ref="сashRegistersViewModeRadio" @submit.prevent="saveCashRegistersViewMode()")
              v-radio-group(v-model="сashRegistersViewModeRadio")
                v-radio(:label="$t('сashRegistersViewMode.LIST')" value="LIST")
                v-radio(:label="$t('сashRegistersViewMode.TREE')" value="TREE")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text @click="cashRegistersViewModeDialog = false") {{ $t("отменить") }}
          v-btn(text color="primary" @click="saveCashRegistersViewMode()") {{ $t("сохранить") }}

</template>
<script>
import { mapState, mapActions } from 'vuex'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import { getLocale, changeLocale } from '../../i18n'

export default {
  mixins: [dictionaryMixin],

  data: () => ({
    darkTheme: true,

    localeTypeRadio: null,
    localeTypeDialog: false,

    сashRegistersViewMode: null,
    сashRegistersViewModeRadio: null,
    cashRegistersViewModeDialog: false,

    homePageType: null,
    homePageTypeRadio: null,
    homePageTypeDialog: false,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),

    locale() {
      return getLocale()
    },
  },

  created() {
    this.localeTypeRadio = this.locale
    this.darkTheme = localStorage.getItem('rekassa.kz-ui-darkTheme') === null || localStorage.getItem('rekassa.kz-ui-darkTheme') === 'true'
    this.homePageType = localStorage.getItem('rekassa.kz-ui-home-page') || 'MAIN'
    this.сashRegistersViewMode = localStorage.getItem('rekassa.kz-ui-сash-registers-view-mode') || 'LIST'
    if (this.$isCordova() && this.$isAndroid()) {
      this.fetchGoogleProvider()
    }
  },

  methods: {
    ...mapActions({
      showConfirm: 'tools/showConfirm',
      showSnackbar: 'tools/showSnackbar',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    darkThemeChanged(val) {
      this.$vuetify.theme.dark = val
      localStorage.setItem('rekassa.kz-ui-darkTheme', val)
      this.darkTheme = val
      if (this.darkTheme) {
        document.body.style.backgroundColor = '#33333D'
      } else {
        document.body.style.backgroundColor = '#F1F1F1'
      }
      this.analyticsLogEvent({ eventName: 're_cash_ui_theme_change', eventProperties: { theme: this.darkTheme ? 'dark' : 'white' } })
    },

    openLocaleTypeDialog() {
      this.localeTypeRadio = this.locale
      this.localeTypeDialog = true
    },
    saveLocaleType() {
      if (this.$refs.localeTypeForm.validate()) {
        this.localeTypeDialog = false
        changeLocale(this.localeTypeRadio)
        this.analyticsLogEvent({ eventName: 're_cash_ui_language_change', eventProperties: { language: this.localeTypeRadio } })
      }
    },

    openHomePageTypeDialog() {
      this.homePageTypeRadio = this.homePageType
      this.homePageTypeDialog = true
    },
    saveHomePageType() {
      this.homePageType = this.homePageTypeRadio
      localStorage.setItem('rekassa.kz-ui-home-page', this.homePageType)
      this.homePageTypeDialog = false
      this.analyticsLogEvent({ eventName: 're_cash_ui_home_page_change', eventProperties: { main_screen: this.homePageType.toLowerCase() } })
      window.location.href = `${window.location.origin}`
    },

    openCashRegistersViewModeDialog() {
      this.сashRegistersViewModeRadio = this.сashRegistersViewMode
      this.cashRegistersViewModeDialog = true
    },
    saveCashRegistersViewMode() {
      this.сashRegistersViewMode = this.сashRegistersViewModeRadio
      localStorage.setItem('rekassa.kz-ui-сash-registers-view-mode', this.сashRegistersViewMode)
      this.cashRegistersViewModeDialog = false
    },

  },
}
</script>
