<template lang="pug">
v-dialog(v-model="confirm.show" :persistent="confirm.persistent" :fullscreen="confirm.fullscreen" scrollable max-width="400")
  v-card
    template(v-if="confirm.fullscreen")
      v-card-title.pa-0
        v-toolbar(dense flat dark)
          v-row(row wrap)
            v-col(cols="1" align-self="center")
              v-btn(icon :disabled="confirm.resolveLoading || confirm.rejectLoading" @click.native="hideConfirm")
                v-icon mdi-close
            v-col(cols="10" align-self="center")
              .title.text-center(v-html="confirm.title")
            v-col(cols="1" align-self="center")
      v-card-text.fill-height
        v-container(fill-height)
          v-row
            v-col(align-self="center")
              v-row.mb-5(v-if="confirm.icon" justify="center")
                v-icon(size="60") {{ confirm.icon }}
              v-row
                v-col
                  .text-center.title(v-html="confirm.text")
    template(v-else)
      v-card-title.pb-0.pt-2
        v-row(row wrap)
          v-col(cols="10")
            .headline(v-html="confirm.title")
          v-col(cols="2")
            v-btn(icon :disabled="confirm.resolveLoading || confirm.rejectLoading" @click.native="hideConfirm")
              v-icon mdi-close
      v-card-text.py-3
        .subtitle-1(v-html="confirm.text")

    v-card-actions.mb-5
      v-spacer
      v-btn(:color="confirm.resolveButtonColor" :block="!confirm.rejectText" :loading="confirm.resolveLoading" :disabled="confirm.rejectLoading" @click.native="resolve" v-if="confirm.resolveText") {{ confirm.resolveText }}
      v-btn(:color="confirm.rejectButtonColor" text :loading="confirm.rejectLoading" :disabled="confirm.resolveLoading" @click.native="reject" v-if="confirm.rejectText") {{ confirm.rejectText }}
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Confirm',

  computed: {
    ...mapState({
      confirm: state => state.tools.confirm,
    }),
  },

  methods: {
    ...mapActions({
      hideConfirm: 'tools/hideConfirm',
      clearConfirm: 'tools/clearConfirm',
      setConfirmLoading: 'tools/setConfirmLoading',
    }),

    resolve() {
      if (this.confirm.resolveCallback) {
        this.setConfirmLoading({ type: 'resolveLoading', value: true })
        this.confirm.resolveCallback().then((payload) => {
          this.setConfirmLoading({ type: 'resolveLoading', value: false })
          if (payload.hide) {
            this.hideConfirm()
            this.$nextTick(() => {
              this.clearConfirm()
            })
          }
        })
      } else {
        this.confirm.deferred.resolve()
        this.hideConfirm()
        this.$nextTick(() => {
          this.clearConfirm()
        })
      }
    },

    reject() {
      if (this.confirm.rejectCallback) {
        this.setConfirmLoading({ type: 'rejectLoading', value: true })
        this.confirm.rejectCallback().then((payload) => {
          this.setConfirmLoading({ type: 'rejectLoading', value: false })
          if (payload.hide) {
            this.hideConfirm()
            this.$nextTick(() => {
              this.clearConfirm()
            })
          }
        })
      } else {
        this.confirm.deferred.reject()
        this.hideConfirm()
        this.$nextTick(() => {
          this.clearConfirm()
        })
      }
    },
  },

}
</script>

<style scoped lang="stylus">
.v-card__title
  word-break break-word
</style>
