<template lang="pug">
v-app
  v-app-bar(app dark dense flat)
    v-btn(icon @click="$router.push('/services')")
      v-icon mdi-arrow-left
    v-toolbar-title {{ $t("налоги") }}
    v-spacer
    template(v-if="user._links.createCashRegister && cashRegister.status === 'REGISTERED' && !moyUchetConnected")
      v-btn(:disabled="loading" text @click="preConnect()") {{ $t("подключить") }}
    v-progress-linear(:active="loading" :indeterminate="loading" absolute bottom)

  v-main(flat)
    v-container(style="max-width: 450px")
      v-row.my-4
        v-col(align="center")
          v-img(:src="$vuetify.theme.dark ? imageUrl.dark : imageUrl.light" width="50%" eager)

      v-row
        v-col
          v-list.v-list--fulltext.gray-background-list
            v-list-item
              v-list-item-icon(style="align-self: center; margin-left: 5px; margin-right: 20px")
                v-icon(size="30") mdi-brightness-percent
              v-list-item-content
                v-list-item-title {{ $t("мой_учет_kz_это_онлайн_бухгалтерия_для_предпринимателей_dotdotdot") }}
                a.text-uppercase(@click.prevent="$openLink(`https://link.rekassa.kz/moyuchet-manual-${locale}`, '_system')") {{ $t("подробнее") }}...
            v-list-item
              v-list-item-content.pt-1
                v-img.cursor-pointer(src="/static/moy-uchet-promo.png" @click="$openLink('https://link.rekassa.kz/moyuchet-promo', '_system')")
            v-list-item(v-if="user._links.createCashRegister && cashRegister.status === 'REGISTERED' && !moyUchetConnected")
              v-list-item-content.pt-1
                v-list-item-title {{ $t("подключите_к_мой_учет_kz_чтобы_чеки_сразу_dotdotdot", { name: `${organization.businessName} (${organization.businessId})` }) }}

      v-row(v-if="login")
        v-col
          v-list.gray-background-list
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("логин") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ login }}

            div.px-4
              v-btn.my-3(color="primary" block @click.prevent="$openLink(`https://moyuchet.kz/acc${login ? '?login=' + login : ''}`, '_system')") Войти в Мой учет.kz

      v-row(v-if="cashRegister.status === 'REGISTERED'")
        v-col
          v-list.gray-background-list
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("статус") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ moyUchetConnected ? $t("подключено") : $t("не_подключено") }}
            v-list-item(v-if="moyUchetConnected && connectDate")
              v-list-item-content
                v-list-item-title {{ $t("дата_подключения") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ connectDate | moment("DD-MM-YYYY HH:mm:ss") }}
            v-list-item(v-if="!moyUchetConnected && disconnectDate")
              v-list-item-content
                v-list-item-title {{ $t("дата_отключения") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ disconnectDate | moment("DD-MM-YYYY HH:mm:ss") }}

            div.px-4(v-if="user._links.createCashRegister")
              v-btn.my-3(v-if="!moyUchetConnected" color="primary" block :disabled="loading" @click="preConnect()") {{ $t("подключить") }}
              v-btn.my-3(v-else color="error" block :disabled="loading" @click="showDisconnectPinpad()") {{ $t("отключить") }}

  re-pinpad(v-model="connectDialog" :title="$t('подключение_name', { name: 'Мой учет'})" :subtitle="$t('введите_пин-код')" :text="$t('для_подключения_name', { name: 'Мой учет'})" :loading="loading" :errorText.sync="connectErrorMessage" @action="connect")
  re-pinpad(v-model="disconnectDialog" :title="$t('отключение_name', { name: 'Мой учет'})" :subtitle="$t('введите_пин-код')" :text="$t('для_отключения_name', { name: 'Мой учет'})" :loading="loading" :errorText.sync="disconnectErrorMessage" @action="disconnect")

  v-dialog(v-model="orgInfoDialog" scrollable persistent :fullscreen="$vuetify.breakpoint.xsOnly" :hide-overlay="$vuetify.breakpoint.xsOnly" width="500")
    v-card
      v-card-title.pa-0
        v-toolbar(dense flat dark v-if="$vuetify.breakpoint.xsOnly")
          span.headline {{ $t("информация") }}
      v-card-text
        v-form.mt-3(ref="orgInfoForm" @submit.prevent="showConnectPinpad()")
          v-row
            v-col.py-1.py-sm-2(cols="12")
              v-text-field(:label="$t('иин_бин')" v-model="businessId" :rules="[rules.required]" validate-on-blur autocomplete="off" disabled)
            v-col.py-1.py-sm-2(cols="12")
              v-textarea(:label="$t('наименование')" v-model="businessName" :rules="[rules.required]" rows="1" auto-grow validate-on-blur autocomplete="off")
            v-col.py-1.py-sm-2(cols="12")
              v-textarea(:label="$t('юридический_адрес')" v-model="address" :rules="[rules.required]" rows="1" auto-grow validate-on-blur autocomplete="off")
            v-col.py-1.py-sm-2(cols="12")
              v-textarea(:label="$t('фио_первого_руководителя')" v-model="fio" :rules="[rules.required]" rows="1" auto-grow validate-on-blur autocomplete="off")
            v-col.py-1.py-sm-2(cols="12")
              v-text-field(:label="$t('электронная_почта')" v-model="email" :rules="[rules.email]" validate-on-blur autocomplete="off")
          v-row(justify="center")
            v-col
              span.subtitle-1 {{ $t("продолжая_вы_соглашаетесь_с") }}
              a.subtitle-1.ml-1(@click="$openLink('https://link.rekassa.kz/moyuchet-oferta', '_system')") {{ $t("условиями_использования") }}
      v-divider.item-divider
      v-card-actions
        v-spacer
        v-btn(text @click="orgInfoDialog = false") {{ $t("отменить") }}
        v-btn(text color="primary" @click="showConnectPinpad()") {{ $t("подключить") }}

</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../../i18n/index'
import Pinpad from '../utils/PinpadDialog.vue'

export default {
  components: {
    're-pinpad': Pinpad,
  },

  data: () => ({
    imageUrl: {
      dark: '/static/moy-uchet-white.svg',
      light: '/static/moy-uchet-black.svg',
    },
    loading: false,

    orgInfoDialog: false,
    businessId: null,
    businessName: null,
    address: null,
    fio: null,
    email: null,
    newRegistration: true,

    connectDialog: false,
    connectErrorMessage: null,
    disconnectDialog: false,
    disconnectErrorMessage: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
    }),

    locale() {
      return getLocale()
    },

    moyUchetConnected() {
      return this.configuration.moyuchet && this.configuration.moyuchet.сonnected
    },

    connectDate() {
      return this.configuration.moyuchet && this.configuration.moyuchet.connectDate
    },

    disconnectDate() {
      return this.configuration.moyuchet && this.configuration.moyuchet.disconnectDate
    },

    login() {
      return this.configuration.moyuchet && this.configuration.moyuchet.login
    },
  },

  methods: {
    ...mapActions({
      fetchOrganization: 'moyUchet/fetchOrganization',
      connectCashRegister: 'moyUchet/connectCashRegister',
      disconnectCashRegister: 'moyUchet/disconnectCashRegister',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    checkShift() {
      if (this.cashRegister.shiftOpen) {
        this.showSnackbar({ message: this.$t('требуется_закрыть_смену_чтобы_поменять_данный_параметр') })
        return false
      }
      return true
    },

    showConnectPinpad() {
      if (this.$refs.orgInfoForm.validate()) {
        this.connectDialog = true
      }
    },

    showDisconnectPinpad() {
      if (this.checkShift()) {
        this.disconnectDialog = true
      }
    },

    preConnect() {
      if (this.checkShift()) {
        this.loading = true
        this.businessId = null
        this.businessName = null
        this.address = null
        this.fio = null
        this.email = null
        this.fetchOrganization({ cashRegisterId: this.cashRegister.id }).then((result) => {
          if (result && result.data) {
            // NOT REGISTERED at Мой учет.kz, NO Cash Register is connected
            if (result.data.cashRegisterRegistrationAllowed && result.data.organizationRegistrationAllowed) {
              this.newRegistration = true
              this.businessId = result.data.businessId
              this.businessName = result.data.businessName
              this.address = result.data.address
              this.fio = result.data.fio
              this.orgInfoDialog = true
            //  REGISTERED at Мой учет.kz already, but NO Cash Register is connected
            } else if (result.data.cashRegisterRegistrationAllowed && !result.data.organizationRegistrationAllowed) {
              this.newRegistration = false
              this.connectDialog = true
            // REGISTERED at Мой учет.kz already, but ANOTHER Cash Register is already connected
            } else if (!result.data.cashRegisterRegistrationAllowed && !result.data.organizationRegistrationAllowed && result.data.registeredSerialNumber !== this.cashRegister.serialNumber) {
              this.showSnackbar({ message: this.$t('другая_касса_уже_зарегистрирована_на', { registeredSerialNumber: result.data.registeredSerialNumber, organizationName: this.organization.businessName }) })
            }
          }
          this.loading = false
        }).catch((error) => {
          this.loading = false
          if (error && error.response && error.response.data && this.$t(`moyuchet/${error.response.data.code}`) !== `moyuchet/${error.response.data.code}`) {
            this.loading = false
            this.showSnackbar({ message: this.$t(`moyuchet/${error.response.data.code}`) })
          } else {
            this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
          }
        })
      }
    },

    connect(pincode) {
      this.loading = true
      this.orgInfoDialog = false
      this.connectCashRegister({
        cashRegisterId: this.cashRegister.id,
        data: {
          businessId: this.businessId,
          businessName: this.businessName,
          address: this.address,
          fio: this.fio,
          email: this.email,
        },
        password: pincode,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id).then(() => {
          this.connectDialog = false
          this.loading = false
          this.analyticsLogEvent({ eventName: 're_moy_uchet_connected', eventProperties: { date: this.$moment().format('DD-MM-YYYY') } })

          this.showConfirm({
            title: this.$t('успешно_подключен_name', { name: 'Мой учет' }),
            icon: 'mdi-email',
            persistent: true,
            text: this.newRegistration ? this.$t('на_указанный_электронный_адрес_было_выслано_письмо_со_ссылкой_для_подтверждения_регистрации', { email: this.email })
              : this.$t('касса_успешна_подключена_к_мой_учет', { serial: this.cashRegister.serialNumber }),
            resolveText: this.$t('ok'),
          }).then(() => {}).catch(() => {})
        })
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.connectErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else if (error && error.response && error.response.data && this.$t(`moyuchet/${error.response.data.code}`) !== `moyuchet/${error.response.data.code}`) {
          this.connectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t(`moyuchet/${error.response.data.code}`) })
        } else {
          this.connectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('произошла_ошибка') })
        }
      })
    },

    disconnect(pincode) {
      this.loading = true
      this.disconnectCashRegister({
        cashRegisterId: this.cashRegister.id,
        password: pincode,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id).then(() => {
          this.disconnectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('успешно_отключен_name', { name: 'Мой учет' }) })
          this.analyticsLogEvent({ eventName: 're_moy_uchet_disconnected', eventProperties: { date: this.$moment().format('DD-MM-YYYY') } })
        })
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.disconnectErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else if (error && error.response && error.response.data && this.$t(`moyuchet/${error.response.data.code}`) !== `moyuchet/${error.response.data.code}`) {
          this.disconnectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t(`moyuchet/${error.response.data.code}`) })
        } else {
          this.disconnectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('произошла_ошибка') })
        }
      })
    },
  },
}
</script>

<style lang="stylus">
</style>
