<template lang="pug">
v-container(style="max-width: 450px")
  v-list(flat).gray-background-list.pt-1

      v-list-item
        v-list-item-content
          h1.headline.font-weight-bold.text-center {{ organization.businessName }}
          h2.subtitle-2.text-center {{ $t("бин_иин") }}: {{ organization.businessId }} &bull;&nbsp;{{ $t("знм") }}: {{ cashRegister.serialNumber }}
          h2.subtitle-2.text-center(v-if="preferences && preferences.ofd && preferences.ofd.accountId") {{ $t("лицевой_счёт") }} ({{ $t(`fdo.${cashRegister.fdo}.title`) }}):
            span.subtitle-2.primary--text(@click="$router.push('/kkm/fdo/payment-methods')") &nbsp;{{ preferences.ofd.accountId }}
          h2.subtitle-2.text-center.pt-1(v-if="cashRegisterStatus !== 'ONLINE'") {{ $t("статус") }}:&nbsp;&nbsp;
            v-chip.rounded.text-uppercase.px-2(small :color="$t(`cashRegister.color.${cashRegisterStatus}`)") {{ $t(`cashRegister.status.${cashRegisterStatus}`) }}
              span(v-if="cashRegister.fdoMode === 'OFFLINE' && hoursLeftTillBlocked >= 0") &nbsp; ({{ hoursLeftTillBlocked }} {{ $t("час") }})
      v-list-item.justify-center(v-if="user._links.createCashRegister")
        v-list-item-content
          v-btn.rounded(color="primary" elevation="0" @click="showCashRegisterSwitch(true); analyticsLogEvent({ eventName: 're_cash_click_button' })") {{ $t("мои_кассы") }}

      div.my-1

      v-subheader {{ $t("настройки") }}

      v-list-item(@click="routeTo('/kkm/settings', 'kkmSettings')" v-if="cashRegister.status === 'REGISTERED' || cashRegister.status === 'DEREGISTERED'")
        v-list-item-icon
          v-icon mdi-calculator
        v-list-item-content
          v-list-item-title {{ $t("настройки_кассы") }}
          v-list-item-subtitle
        v-list-item-action
          v-icon(left style="font-size: 30px;") mdi-menu-right

      v-list-item(@click="routeTo('/receipt/settings', 'receiptSettings')")
        v-list-item-icon
          v-icon mdi-receipt
        v-list-item-content
          v-list-item-title {{ $t("настройки_чека") }}
        v-list-item-action
          v-icon(left style="font-size: 30px;") mdi-menu-right

      v-list-item(@click="routeTo('/printer/settings', 'printerSettings')")
        v-list-item-icon
          v-icon mdi-printer
        v-list-item-content
          v-list-item-title {{ $t("настройки_принтера") }}
          v-list-item-subtitle(v-if="printer.connectionType === 'bluetooth' && printer.bluetooth.name") {{ printer.bluetooth.name }}
          v-list-item-subtitle(v-if="printer.connectionType === 'wifi' && printer.wifi.name") {{ printer.wifi.name }}
        v-list-item-action
          v-icon(left style="font-size: 30px;") mdi-menu-right

      v-list-item(@click="routeTo('/ui/settings', 'uiSettings')")
        v-list-item-icon
          v-icon mdi-television-guide
        v-list-item-content
          v-list-item-title {{ $t("настройки_интерфейса") }}
        v-list-item-action
          v-icon(left style="font-size: 30px;") mdi-menu-right

      v-list-item(@click="routeTo('/kkm/positions', 'priceList')")
        v-list-item-icon
          v-icon mdi-tag-text-outline
        v-list-item-content
          v-list-item-title {{ $t("прайс_лист") }}
        v-list-item-action
          v-icon(left style="font-size: 30px;") mdi-menu-right

      v-list-item(@click="routeTo('/kkm/access-users', 'accessUsers')" v-if="!readonly")
        v-list-item-icon
          v-icon mdi-account-group
        v-list-item-content
          v-list-item-title {{ $t("кассиры_пользователи") }}
        v-list-item-action
          v-icon(left style="font-size: 30px;") mdi-menu-right

      v-list-item(@click="routeTo('/kkm/access-api', 'accessApi')" v-if="!readonly")
        v-list-item-icon
          v-icon mdi-apps-box
        v-list-item-content
          v-list-item-title {{ $t("сторонние_приложения") }}
        v-list-item-action
          v-icon(left style="font-size: 30px;") mdi-menu-right

      template(v-if="!$isFFBMode()")
        div.my-1

        v-subheader {{ $t("помощь") }}

        v-list-item(@click="showManual()")
          v-list-item-icon
            v-icon mdi-book-information-variant
          v-list-item-content
            v-list-item-title {{ $t("инструкции") }}
          v-list-item-action
            v-icon(left style="font-size: 30px;") mdi-menu-right

        v-list-item(@click="routeTo('/kkm/legal-and-regulatory', 'legalAndRegulatory')")
          v-list-item-icon
            v-icon mdi-note-text
          v-list-item-content
            v-list-item-title {{ $t("правовая_информация") }}
          v-list-item-action
            v-icon(left style="font-size: 30px;") mdi-menu-right

      div.my-1

      v-subheader {{ $t("аккаунт") }}

      v-list-item(@click="routeTo('/profile', 'profileSettings')" :disabled="!user._links.createCashRegister")
        v-list-item-icon
          v-icon mdi-account
        v-list-item-content
          v-list-item-title {{ $t("профиль") }}
          v-list-item-subtitle {{ user.name }} {{ user.phone | phone }}
        v-list-item-action
          v-icon(left style="font-size: 30px;") mdi-menu-right

      v-list-item(@click="signOutDialog = true")
        v-list-item-icon
          v-icon mdi-exit-to-app
        v-list-item-content
          v-list-item-title.red--text {{ $t("выйти") }}

  div.mt-6(align="center")
    v-btn(text icon @click="$openLink('https://link.rekassa.kz/instagram', '_system'); analyticsLogEvent({ eventName: 're_settings_social_clicked', eventProperties: { social_platform: 'instagram' } })")
      v-icon mdi-instagram
    v-btn(text icon @click="$openLink('https://link.rekassa.kz/youtube', '_system'); analyticsLogEvent({ eventName: 're_settings_social_clicked', eventProperties: { social_platform: 'youtube' } })")
      v-icon mdi-youtube
    v-btn(text icon @click="$openLink('https://link.rekassa.kz/telegram', '_system'); analyticsLogEvent({ eventName: 're_settings_social_clicked', eventProperties: { social_platform: 'telegram' } })")
      v-icon mdi-send
    v-btn(text icon @click="$openLink('https://link.rekassa.kz/facebook', '_system'); analyticsLogEvent({ eventName: 're_settings_social_clicked', eventProperties: { social_platform: 'facebook' } })")
      v-icon mdi-facebook

  div.mt-1.mb-8(align="center")
    span.caption &copy; COMRUN
    span.mx-2 &bull;
    a.caption.mb-3(@click="$openLink(`https://link.rekassa.kz/rekassa-website-${locale}`, '_system'); analyticsLogEvent({ eventName: 're_settings_social_clicked', eventProperties: { social_platform: 'website' } })") rekassa.kz
    div.caption {{cordovaBuildVersion ? `v.${cordovaBuildVersion}` : ''}}

  v-dialog(v-model="signOutDialog" persistent max-width="400")
    v-card
      v-card-title.pb-0.pt-2
        v-row(row wrap)
          v-col(cols="10")
            .headline {{ $t('вы_уверены_что_хотите_выйти_из_приложения') }}
          v-col(cols="2")
            v-btn(icon :disabled="signOutDialogLoading" @click.native="signOutDialog = false")
              v-icon mdi-close

      v-card-actions.py-5
        v-spacer
        v-btn(color="primary" :loading="signOutDialogLoading" @click.native="preSignOut()") {{ $t('выйти') }}
        v-btn(color="primary" text :disabled="signOutDialogLoading" @click.native="signOutDialog = false") {{ $t('отменить') }}

</template>
<script>
import { mapActions, mapState } from 'vuex'
import { getLocale } from '../../i18n/index'

export default {
  data: () => ({
    signOutDialog: false,
    signOutDialogLoading: false,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      roles: state => state.cashRegisters.cashRegister.roles,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      printer: state => state.printer.printer,
      cordovaBuildVersion: state => state.tools.cordovaBuildVersion,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
    }),

    locale() {
      return getLocale()
    },

    cashRegisterStatus() {
      if (this.cashRegister.status === 'TRIAL' || this.cashRegister.status === 'DEREGISTERED') {
        return this.cashRegister.status
      } if (this.cashRegister.status === 'REGISTERED') {
        return this.cashRegister.fdoMode
      } return null
    },

    hoursLeftTillBlocked() {
      return this.cashRegister && this.cashRegister.offlineExpireTime ? Math.floor(this.$moment.duration(this.$moment(this.cashRegister.offlineExpireTime).diff(new Date())).asHours()) : null
    },

    readonly() {
      return this.cashRegister.status === 'TRIAL' || this.cashRegister.status === 'DEREGISTERED' || !this.isOwner || this.user._links.createCashRegister === undefined
    },

    isOwner() {
      return this.roles.find(r => r === 'OWNER') !== undefined
    },
  },

  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      showCashRegisterSwitch: 'tools/showCashRegisterSwitch',
      analyticsLogEvent: 'analytics/logEvent',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
    }),

    routeTo(route, event) {
      this.$router.push(route)
      this.analyticsLogEvent({ eventName: 're_settings_click_button', eventProperties: { section: event } })
    },

    preSignOut() {
      this.signOutDialogLoading = true
      if (!this.$isCordova()) {
        window.HelpCrunch('hideChatWidget')
      }
      this.signOut()
    },

    showManual() {
      this.$openLink(`https://link.rekassa.kz/help-rekassa-${this.locale}`, '_system')
      this.analyticsLogEvent({ eventName: 're_settings_click_button', eventProperties: { section: 'manual' } })
    },
  },
}
</script>

<style lang="stylus">
</style>
