<template lang="pug">
div
  re-stories
  re-whats-new
  v-container.pt-0(style="max-width: 700px")
    v-row
      v-col(cols="12" sm="6")
        v-list.gray-background-list.rounded-lg.py-1(id="cashRegisterWidget" dense :style="$vuetify.theme.dark ? 'background: linear-gradient(140deg, #00ccbc14 0%, #85900017 103%);' : ''")
          v-list-item
            v-list-item-content.py-0.d-flex.rounded-lg.cursor-pointer(@click="toJournal()" )
              v-list-item-subtitle.d-flex.subtitle-1.mt-3.justify-center {{ shiftNumber ? `${$t("текущая_смена")} - №${shiftNumber} ( ${shiftTicketNumber} ${numWord(shiftTicketNumber)} )` : $t("смена_не_открыта") }}
              v-list-item-title.d-flex.mt-3.mb-3.justify-center(style="line-height: 45px; text-overflow: unset; font-size: 40px; font-family: 'Eczar', sans-serif !important;") {{ shiftRevenue | numeral('0,0.[00]') }} ₸
              v-icon(style="position: absolute; top: 25px; right: 8px; font-size: 50px;") mdi-menu-right

      v-col.py-0.px-3(cols="12" sm="6")
        v-row.my-1
          v-col.py-0(cols="12")
            v-row.px-1
              v-col.px-2.py-2(cols="4")
                span(@click="$router.push('/kkm'); analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: 'cashRegister' } })")
                  v-list.v-list--fulltext.rounded-lg.cursor-pointer(dense :style="$vuetify.theme.dark ? 'height: 106px; background: linear-gradient(140deg, #00ccbc14 0%, #85900017 103%);' : 'height: 106px; background: #F5F5F5;'")
                    v-list-item.justify-center.pt-2
                      v-icon(style="font-size: 28px;") mdi-calculator-variant
                    v-list-item.text-center.px-1
                      v-list-item-title.subtitle-1 {{ $t("касса") }}

              v-col.px-2.py-2(cols="4")
                span(@click="$router.push('/kkm/positions'); analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: 'priceList' } })")
                  v-list.v-list--fulltext.rounded-lg.cursor-pointer(dense :style="$vuetify.theme.dark ? 'height: 106px; background: linear-gradient(140deg, #00ccbc14 0%, #85900017 103%);' : 'height: 106px; background: #F5F5F5;'")
                    v-list-item.justify-center.pt-2
                      v-icon(style="font-size: 28px;") mdi-tag-text-outline
                    v-list-item.text-center.px-1
                      v-list-item-title.subtitle-1 {{ $t("прайс_лист") }}

              v-col.px-2.py-2(cols="4")
                span(@click="$openLink(`https://link.rekassa.kz/help-rekassa-${locale}`, '_system'); analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: 'manuals' } })")
                  v-list.v-list--fulltext.rounded-lg.cursor-pointer(dense :style="$vuetify.theme.dark ? 'height: 106px; background: linear-gradient(140deg, #00ccbc14 0%, #85900017 103%);' : 'height: 106px; background: #F5F5F5;'")
                    v-list-item.justify-center.pt-2
                      v-icon(style="font-size: 28px;") mdi-book-information-variant
                    v-list-item.text-center.px-1
                      v-list-item-title.subtitle-1 {{ $t("инструкции") }}

    v-row
      v-col.pb-0.pt-1(id="servicesWidget")
        .title {{ $t("сервисы") }}
        .body-2.grey--text {{ $t("подключайте_и_развивайте_свой_бизнес") }}

    v-row.px-1
      v-col.py-0(cols="12" sm="6")
        v-row
          v-col(cols="6").px-2
            span(@click="$router.push('/kkm/freedom/box'); analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: 'freedomBox' } })")
              v-list.re-nav-service-tile.gray-background-list.v-list--fulltext.rounded-lg.cursor-pointer(dark dense style="background: linear-gradient(97deg, #4996AE 0%, #2389A8 100%);")
                v-list-item
                  .headline.font-weight-bold(style="font-size: 21px !important") FREEDOM BOX
                v-list-item
                  v-list-item-subtitle.font-weight-medium {{ $t("терминал_для_приема_платежей_и_продажи_товаров_в_рассрочку") }}

          v-col(cols="6").px-2
            span(@click="$router.push('/kkm/freedom/credit'); analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: 'freedomCredit' } })")
              v-list.re-nav-service-tile.gray-background-list.v-list--fulltext.rounded-lg.cursor-pointer(dark dense style="background: linear-gradient(97deg, #FF6859 0%, #A2180B 100%);")
                v-list-item
                  .headline.font-weight-bold(style="font-size: 20px !important; line-height: 1.7rem;") FREEDOM CREDIT
                v-list-item
                  v-list-item-subtitle.font-weight-medium {{ $t("онлайн_займы_до_млн_тенге_на_карту") }}

      v-col.py-0(cols="12" sm="6")
        v-row
          v-col(cols="6").px-2
            span(@click="$router.push('/kkm/halyk/pos'); analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: 'halykPos' } })")
              v-list.re-nav-service-tile.gray-background-list.v-list--fulltext.rounded-lg.cursor-pointer(dark dense style="background: linear-gradient(97deg, #9752D8 0%, #8C2FE2 100%);")
                v-list-item
                  .headline.font-weight-bold(style="font-size: 25px !important") Halyk POS
                v-list-item
                  v-list-item-subtitle.font-weight-medium {{ $t("прием_платежей_картами_с_помощью_телефона") }}

          v-col(cols="6").px-2
            span(@click="$router.push('/kkm/yandex/business'); analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: 'yandexBusiness' } })")
              v-list.re-nav-service-tile.gray-background-list.v-list--fulltext.rounded-lg.cursor-pointer(dark dense style="background: #DA9B00;")
                v-list-item
                  .headline.font-weight-bold(style="font-size: 24px !important; line-height: 1.5rem;") Яндекс Бизнес
                v-list-item
                  v-list-item-subtitle.font-weight-medium {{ $t("увеличивайте_количество_клиентов_в_вашем_бизнесе") }}

      v-col.py-0(cols="12" sm="6")
        v-row
          v-col(cols="6").px-2
            span(@click="$router.push('/kkm/uchet/tcis'); analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: 'tcis' } })")
              v-list.re-nav-service-tile.gray-background-list.v-list--fulltext.rounded-lg.cursor-pointer(dark dense style="background: #71B665;")
                v-list-item
                  .headline.font-weight-bold(style="font-size: 24px !important; line-height: 1.5rem;") {{ $t("ТИС") }}
                v-list-item
                  v-list-item-subtitle.font-weight-medium {{ $t("поможет_увеличить_порог_по_ндс") }}

          v-col(cols="6").px-2
            span(@click="$router.push('/services'); analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: 'services' } })")
              v-list.re-nav-service-tile.gray-background-list.v-list--fulltext.rounded-lg.cursor-pointer(dark dense style="background: linear-gradient(94deg, #3D3D47 0%, #3D3D47 96.91%);")
                v-list-item
                  .headline.font-weight-bold(style="font-size: 23px !important") {{ $t("все_сервисы") }}
                v-list-item
                  img(src="/static/all-services.png" style="width: 100%; margin-top: 14px;")

      v-col.py-0(cols="12" sm="6" v-if="NAEnabled")
        v-row
          v-col(cols="6").px-2
            span(@click="showMiniAppNA = true; analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: '910_form_beta' } })")
              v-list.re-nav-service-tile.gray-background-list.v-list--fulltext.rounded-lg.cursor-pointer(dark dense style="background: #3984F1;")
                v-list-item
                  .headline.font-weight-bold(style="font-size: 23px !important") 910 Форма
                v-list-item
                  v-list-item-subtitle.font-weight-medium BETA

    v-row
      v-col.pb-0.pt-4(id="marketplacesWidget")
        .title {{ $t("маркетплейсы") }}
        .body-2.grey--text {{ $t("автоматическая_фискализация_продаж") }}

    v-row.px-1
      v-col.py-0(cols="12" sm="6")
        v-row
          v-col(cols="6").px-2
            span(@click="$router.push('/kkm/kaspi/shop'); analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: 'kaspiShop' } })")
              v-list.re-nav-service-tile.gray-background-list.v-list--fulltext.rounded-lg.cursor-pointer(dark dense style="background: linear-gradient(97deg, rgb(255, 74, 56) 0%, #f21b05 100%)")
                v-list-item
                  .headline.font-weight-bold(style="font-size: 21px !important") Каспи Магазин
                v-list-item
                  v-list-item-subtitle.font-weight-medium {{ $t("фискализация_продаж_прямо_из_маркетплейса") }}

          v-col(cols="6").px-2
            span(@click="$router.push('/kkm/marketplace-request'); analyticsLogEvent({ eventName: 're_dash_tile_clicked', eventProperties: { tile_name: 'marketplaceRequest' } })")
              v-list.re-nav-service-tile.gray-background-list.v-list--fulltext.rounded-lg.cursor-pointer(dark dense style="background: linear-gradient(97deg, rgb(46, 143, 233) 0%, #0057a7 100%);")
                v-list-item
                  .headline.font-weight-bold(style="font-size: 20px !important; line-height: 1.7rem;") {{ $t("подайте_заявку") }}
                v-list-item
                  v-list-item-subtitle.font-weight-medium {{ $t("подключи_свой_маркетплейс") }}
    v-row
      v-col.pb-0.pt-4(id="promotionsWidget")
        .title {{ $t("акции") }}
        .body-2.grey--text {{ $t("следи_за_самыми_выгодными_предложениями") }}

    v-row.px-1
      v-col.px-2
        re-promotions

  re-mini-app(v-model="showMiniAppNA" :url="NAUrl" :name="NAName")

</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../i18n/index'
import helpUrls from '../utils/helpUrls'
import billsAndCoinsMixin from '../mixins/billsAndCoinsMixin'
import Stories from './utils/Stories.vue'
import Promotions from './utils/Promotions.vue'
import WhatsNew from './utils/WhatsNew.vue'
import MiniApp from './utils/MiniApp.vue'

export default {
  components: {
    're-stories': Stories,
    're-promotions': Promotions,
    're-whats-new': WhatsNew,
    're-mini-app': MiniApp,
  },

  mixins: [billsAndCoinsMixin],

  data: () => ({
    NAEnabled: false,
    showMiniAppNA: false,
    NAUrl: process.env.VUE_APP_MINIAPP_NA_URL,
    NAName: process.env.VUE_APP_MINIAPP_NA_NAME,
  }),

  beforeRouteEnter(to, from, next) {
    // Если FreedomBox или Мой учет iframe то перенаправляем на /kkm
    if (process.env.VUE_APP_FFB_POS_MODE === 'true' || window.location !== window.parent.location) {
      next('/kkm')
    } else {
      next()
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
    }),

    locale() {
      return getLocale()
    },

    shift() {
      return this.cashRegister ? this.cashRegister.shift : null
    },

    shiftNumber() {
      if (this.shift && this.shift.open && this.shift.shiftNumber) {
        return this.shift.shiftNumber
      }
      return null
    },

    shiftRevenue() {
      if (this.shift && this.shift.open && this.shift.data.revenue) {
        return this.getNumberFromBillsAndCoins(this.shift.data.revenue.sum) * (this.shift.data.revenue.isNegative ? -1 : 1)
      }
      return null
    },

    shiftTicketNumber() {
      if (this.cashRegister && this.shift && this.shift.open && this.shift.shiftTicketNumber > 0) {
        return this.shift.shiftTicketNumber
      }
      return 0
    },
  },

  watch: {
    $route(to) {
      if (to.fullPath === '/list') {
        this.showCashRegisterSwitch(true)
        this.$router.push('/')
      }
    },
  },

  mounted() {
    this.checkNAAllow()

    if (this.$route.path === '/list') {
      setTimeout(() => {
        this.showCashRegisterSwitch(true)
      }, 1000)
      this.$router.push('/')
    }

    this.analyticsLogEvent({ eventName: 're_dash_opened' })
  },

  methods: {
    ...mapActions({
      firebaseRemoteConfigGetString: 'tools/firebaseRemoteConfigGetString',
      showCashRegisterSwitch: 'tools/showCashRegisterSwitch',
      showOfdRegIframe: 'tools/showOfdRegIframe',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
      showSnackbar: 'tools/showSnackbar',
    }),

    async checkNAAllow() {
      const NAAllowedPhoneNumbers = await this.firebaseRemoteConfigGetString('NA_ALLOWED_PHONE_NUMBERS')
      const phoneNumber = this.user.phone.replace(/[^0-9]/g, '')

      if (NAAllowedPhoneNumbers && NAAllowedPhoneNumbers.indexOf(phoneNumber) !== -1) {
        this.NAEnabled = true
      }
    },

    toJournal() {
      if (this.shiftNumber) {
        this.$router.push(`/shifts/${this.cashRegister.id}/${this.shiftNumber}`)
      } else {
        this.showSnackbar({ message: this.$t('смена_не_открыта') })
      }
    },

    showError() {
      this.showConfirm({
        title: this.$t('ошибка'),
        text: this.cashRegister.data.fdo.result.text,
        rejectText: this.$t('подробнее'),
      }).then(() => {}).catch(() => {
        this.$openLink(helpUrls[this.cashRegister.data.fdo.result.code])
      })
    },

    numWord(value) {
      const words = this.locale === 'ru' ? ['чек', 'чека', 'чеков'] : ['чек', 'чек', 'чек']
      value = Math.abs(value) % 100
      const num = value % 10
      if (value > 10 && value < 20) return words[2]
      if (num > 1 && num < 5) return words[1]
      if (num === 1) return words[0]
      return words[2]
    },
  },
}
</script>
<style lang="stylus">
.re-nav-service-tile
  height 116px
  .v-list-item
    padding 0px 12px!important
</style>
