<template lang="pug">
v-app

  v-overlay(:value="signInWithTokenLoading")
    v-progress-circular(indeterminate size="64")

  v-main.area-inset(flat)
    v-container(fill-height style="max-width: 350px;")
      v-container.pa-0(:class="{'height-100' : isSmallScreen}" :style="$isCordova() ? 'display: grid' : 'min-height'" v-if="step === 'CHOOSE_SIGNIN_TYPE'")
        v-row.mb-2(align="center" style="align-self: start")
          v-col(cols="4" align="left")
            img(:src="$vuetify.theme.dark ? imageUrl.dark : imageUrl.light" width="100")
          v-col.pt-0(cols="8" align="right")
            v-btn.phone-btn.rounded-lg.mr-4(small elevation="0" color="secondary" @click="callCenter()")
              v-icon(size="20") mdi-phone
            v-btn.kk-i18n-btn(small elevation="0" color="secondary" @click="switchLocale('kk')" :disabled="locale === 'kk'") Қаз
            div.i18n-divider
            v-btn.ru-i18n-btn(small elevation="0" color="secondary" @click="switchLocale('ru')" :disabled="locale === 'ru'") Рус

          v-col.mb-4(cols="12" align="center")
            img(ref="loginPhoneFrame" src="/static/login-phone-frame.png" style="width: 150px;")
          v-col(cols="12" align="left")
            h1(style="line-height: 33px") {{ $t("добро_пожаловать_в_rekassa") }}
          v-col.pt-2.pb-0(cols="12" align="left" style="line-height: 20px")
            .subtitle {{ $t("наше_приложение") }}:
            ul
              li.subtitle {{ $t("бесплатное") }}
              li.subtitle {{ $t("простое_в_использовании") }}
              li.subtitle {{ $t("соотвествует_всем_требованиям_налоговой_рк") }}

        v-row(style="align-self: end")
          v-col.px-0.pb-1(cols="12" v-if="googleEnabled")
            v-btn.black--text.rounded(block elevation="0" color="white" :loading="signInWithGoogleLoading" @click="signInWithGoogle()")
              v-icon(small left color="red")  mdi-google
              | {{ $t("войти_через_type", { type: 'Google' }) }}

          v-col.px-0.pb-1(cols="12" v-if="faceTouchIdEnabled")
            v-btn.white--text.rounded(block elevation="0" color="black" :loading="signInWithFaceTouchIdLoading" @click="signInWithFaceTouchId()")
              v-icon(small left) {{ faceTouchIdType === 'Face ID' ? 'mdi-face-recognition' : 'mdi-fingerprint' }}
              | {{ $t("войти_через_type", { type: faceTouchIdType }) }}

          v-col.px-0.pb-1(cols="12")
            v-btn.rounded(block elevation="0" color="primary" :disabled="signInWithFaceTouchIdLoading || signInWithGoogleLoading" :loading="!grecaptchaReady" @click="stepSignInWithPhoneNumber()")
              | {{ $t("войти") }}

      v-container.pa-0(:class="isSmallScreen ? 'height-100' : 'min-height'" v-if="step === 'ENTER_PHONE_NUMBER'")
        v-row.mb-6
          v-col.pl-0.pb-2(cols="12" align="left")
            v-btn(icon large :disabled="signInWithPhoneNumberLoading" @click="step = 'CHOOSE_SIGNIN_TYPE'")
              v-icon mdi-arrow-left

          v-col(cols="12" align="left")
            h2.font-weight-medium {{ $t("введите_номер_телефона") }}

          v-col(cols="12" align="center")
            v-form(@submit.prevent="sendCode()")
              v-text-field.mb-8(
                ref="phoneNumber"
                v-model="phoneNumber"
                v-mask="'+7 (###) ###-##-##'"
                type="tel"
                :label="$t('номер_телефона')"
                placeholder="+7 (###) ###-##-##"
                :disabled="signInWithPhoneNumberLoading"
                autocomplete="off"
                @input="phoneNumberError = null"
                :error-messages="phoneNumberError"
                :hint="$t('отправим_код_подтверждения')"
                persistent-hint
                filled)
              div.mb-4.px-4
                .caption(style="line-height: 10px") {{ $t("нажимая_кнопку_продолжить_вы_принимаете_условия") }} &nbsp;
                  a.caption(@click="$openLink('https://link.rekassa.kz/app-agreement', '_system')") {{ $t("пользовательского_соглашения") }}
              v-btn.rounded(:disabled="!phoneNumber || phoneNumber.length !== 18" block elevation="0" color="primary" type="submit" :loading="signInWithPhoneNumberLoading" @click.prevent="sendCode()") {{ $t("продолжить") }}

      v-container.pa-0(:class="isSmallScreen ? 'height-100' : 'min-height'" v-if="step === 'ENTER_CONFIRMATION_CODE'")
        v-row.mb-6
          v-col.pl-0.pb-2(cols="12" align="left")
            v-btn(icon large :disabled="signInLoading" @click="step = 'ENTER_PHONE_NUMBER'")
              v-icon mdi-arrow-left

          v-col.pb-0(cols="12" align="left")
            h2.font-weight-medium {{ $t("мы_отправили_код_в_sms") }}
            div.mt-1
              .subtitle-1 {{ $t("значный_код_отправлен_по_номеру") }}
              .subtitle-1 {{ phoneNumber }}

          v-col(cols="12")
            re-otp-input.px-3.my-5(v-model="confirmationCode" :error.sync="confirmationCodeError" :disabled="signInLoading" @action="verifyCode")
            v-col.py-1(cols="12" align="center")
              v-btn.caption.font-weight-bold.crutch(text color="#1976D2" :disabled="confirmationCodeCounter > 0"  @click.prevent="signInWithAlternativesBottomSheet = true; analyticsLogEvent({ eventName: 're_auth_user_requested_alternative_confimation' })") {{ $t("не_пришел_код") }} ({{ confirmationCodeTimeLeft }})
            v-btn.rounded(:disabled="!(confirmationCode && confirmationCode.length === 6)" block elevation="0" color="primary" type="submit" :loading="signInLoading" @click.prevent="verifyCode()") {{ $t("продолжить") }}

  v-bottom-sheet(v-model="signInWithAlternativesBottomSheet" inset width="400")
    v-sheet
      v-card
        v-card-title.pb-0
          v-row
            v-col.pr-0.py-0(cols="10")
              span.h1 {{ $t("попробуйте_войти_другим_способом") }}
            v-col.pl-0.py-0(cols="2" align="right")
              v-btn(icon @click="signInWithAlternativesBottomSheet = false")
                v-icon mdi-close
        v-card-text.px-0
          v-container.px-0
            v-col(cols="12")
              v-btn.white--text(block color="#00A9E2" @click="signInWithAlternativesBottomSheet = false; signInWithTelegram()")
                v-icon(small left) mdi-send
                | {{ $t("войти_через_telegram") }}

  re-connection-warning
</template>
<script>
import { mapState, mapActions } from 'vuex'
import i18n, { getLocale, changeLocale } from '../../i18n'
import ConnectionWarning from '../utils/ConnectionWarning.vue'
import OTPInput from '../utils/OTPInput.vue'

export default {
  components: {
    're-connection-warning': ConnectionWarning,
    're-otp-input': OTPInput,
  },

  data: () => ({
    imageUrl: {
      light: '/static/logo-white.png',
      dark: '/static/logo-black.png',
    },

    step: 'CHOOSE_SIGNIN_TYPE',

    phoneNumber: null,
    phoneNumberError: null,

    otpPhone: null,
    otpHash: null,

    confirmationCode: null,
    confirmationCodeTimeLeft: null,
    confirmationCodeCounter: null,
    confirmationCodeTimer: null,
    confirmationCodeError: null,

    signInWithTokenLoading: false,

    signInWithPhoneNumberLoading: false,
    signInLoading: false,

    grecaptchaReady: false,

    googleEnabled: false,
    signInWithGoogleLoading: false,

    faceTouchIdEnabled: false,
    faceTouchIdType: null,
    signInWithFaceTouchIdLoading: false,

    signInWithAlternativesBottomSheet: false,

    rules: {
      required: v => !!v || this.$t('требуется_заполнить'),
    },
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      helpCrunchUnreadCount: state => state.tools.helpCrunchUnreadCount,
    }),

    locale() {
      return getLocale()
    },

    isSmallScreen() {
      return window.innerWidth < 1000
    },
  },

  watch: {
    '$route.query.token': {
      handler(token) {
        if (token) {
          this.signInWithTokenLoading = true
          this.signInWithCustomToken({ token }).then((tokenResult) => {
            this.setUser(tokenResult.user).then(() => {
              this.init().then(() => {
                this.analyticsLogEvent({ eventName: 'login', eventProperties: { type: 'Token' } })
                this.$router.push('/')
                localStorage.setItem('rekassa.kz-auth-fresh-signin', 'true')
                localStorage.setItem('rekassa.kz-auth-face-touch-id-enabled', 'false')
                localStorage.setItem('rekassa.kz-auth-google-enabled', 'false')
              })
            })
          }).catch(() => {
            this.signInWithTokenLoading = false
            this.showSnackbar({ message: i18n.t('не_удалось_войти_по_токену') })
            this.signInLoading = false
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.windowResize()
  },

  created() {
    if (this.$route.query.landing !== undefined) {
      this.$vuetify.theme.dark = false
      document.body.style.backgroundColor = '#F1F1F1'
    }

    if (this.user) {
      this.$router.push('/')
    }

    // iOS Face Touch ID
    if (this.$isCordova() && !this.$isAndroid()) {
      this.faceTouchIdEnabled = (localStorage.getItem('rekassa.kz-auth-face-touch-id-enabled') || 'false') === 'true'
      this.faceTouchIdType = localStorage.getItem('rekassa.kz-auth-face-touch-id-type')
    }

    // Google
    if (this.$isCordova() && this.$isAndroid()) {
      this.googleEnabled = (localStorage.getItem('rekassa.kz-auth-google-enabled') || 'false') === 'true'
    }

    window.grecaptcha.ready(() => {
      this.grecaptchaReady = true
    })
  },

  beforeRouteEnter(to, from, next) {
    // Костыль для Мой учет.kz(rekassa as iframe)
    if (to.query.theme) {
      localStorage.setItem('rekassa.kz-ui-darkTheme', to.query.theme === 'light' ? 'false' : 'true')
    }

    next()
  },

  beforeDestroy() {
    // Восстановить тему
    const darkTheme = localStorage.getItem('rekassa.kz-ui-darkTheme') === null || localStorage.getItem('rekassa.kz-ui-darkTheme') === 'true'
    document.body.style.backgroundColor = darkTheme ? '#33333D' : '#F1F1F1'
    this.$vuetify.theme.dark = darkTheme
  },

  methods: {
    ...mapActions({
      sendOtp: 'auth/sendOtp',
      verifyOtp: 'auth/verifyOtp',
      signInWithCustomToken: 'auth/signInWithCustomToken',
      setUser: 'auth/setUser',
      init: 'cashRegisters/init',
      setConnectionWarning: 'tools/setConnectionWarning',
      showConfirm: 'tools/showConfirm',
      showSnackbar: 'tools/showSnackbar',
      firebaseRemoteConfigGetString: 'tools/firebaseRemoteConfigGetString',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    callCenter() {
      document.location.href = 'tel:+77777779449'
      this.analyticsLogEvent({ eventName: 're_auth_call_button_click' })
    },

    signInWithGoogle() {
      if (this.signInWithGoogleLoading) return
      this.signInWithGoogleLoading = true
      window.FirebasePlugin.authenticateUserWithGoogle(process.env.VUE_APP_GOOGLE_PROVIDE_ID, (credential) => {
        this.cordovaSignInWithCredential(credential)
      }, (error) => {
        console.debug(error)
        this.signInWithGoogleLoading = false
        this.showSnackbar({ message: this.$t('не_удалось_войти_через_type', { type: 'Google' }) })
      })
    },

    signInWithFaceTouchId() {
      this.signInWithFaceTouchIdLoading = true
      setTimeout(() => {
        window.Fingerprint.loadBiometricSecret({
          description: this.$t('вход_в_систему'),
          disableBackup: true,
        }, (result) => {
          this.cordovaSignInUserWithEmailAndPassword(result.split(':')[0], result.split(':')[1])
        }, (error) => {
          console.debug(error)
          this.signInWithFaceTouchIdLoading = false
          this.showSnackbar({ message: this.$t('не_удалось_войти_через_type', { type: this.faceTouchIdType }) })
        })
      }, 500)
    },

    signInWithTelegram() {
      this.$openLink(`https://t.me/${process.env.VUE_APP_TELEGRAM_BOT_NAME}`, '_system')
      this.analyticsLogEvent({ eventName: 're_auth_auth_telegram_button_click' })
    },

    stepSignInWithPhoneNumber() {
      this.analyticsLogEvent({ eventName: 're_auth_auth_button_click' })

      this.step = 'ENTER_PHONE_NUMBER'
      this.phoneNumber = localStorage.getItem('rekassa.kz-auth-last-phone-number')
      if (this.confirmationCodeTimer) {
        clearInterval(this.confirmationCodeTimer)
      }
      this.$nextTick(() => {
        this.$refs.phoneNumber.focus()
      })
    },

    sendCode() {
      this.analyticsLogEvent({ eventName: 're_auth_user_filled_phone_number' })

      this.signInWithPhoneNumberLoading = true
      this.otpPhone = `+${this.phoneNumber.replace(/[^\d]/g, '')}`

      window.grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY, { action: 'signin' })
        .then((token) => {
          this.sendOtp({ data: { phone: this.otpPhone, token } }).then(result => {
            this.otpHash = result.hash
            this.step = 'ENTER_CONFIRMATION_CODE'
            this.signInWithPhoneNumberLoading = false

            // Countdown
            this.confirmationCodeTimeLeft = '0:25'
            this.confirmationCodeCounter = 25
            this.confirmationCodeTimer = setInterval(() => {
              if (this.confirmationCodeCounter <= 0) {
                clearInterval(this.confirmationCodeTimer)
              } else {
                this.confirmationCodeCounter -= 1
                this.confirmationCodeTimeLeft = `0:${this.$options.filters.numeral(this.confirmationCodeCounter, '00')}`
              }
            }, 1000)
          }).catch((error) => {
            if (error.response.data && error.response.data.code) {
              this.phoneNumberError = this.$t(`sms/${error.response.data.code}`)
            } else {
              this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
            }
            this.signInWithPhoneNumberLoading = false
          })
        }).catch((error) => {
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
    },

    verifyCode() {
      this.analyticsLogEvent({ eventName: 're_auth_user_entered_confirmation_code' })
      this.signInLoading = true
      this.verifyOtp({ data: { phone: this.otpPhone, hash: this.otpHash, otp: this.confirmationCode } }).then(result => {
        this.signInWithCustomToken({ token: result.token }).then((tokenResult) => {
          this.setUser(tokenResult.user).then(() => {
            this.init().then(() => {
              this.analyticsLogEvent({ eventName: 'login', eventProperties: { type: 'Kazinfotech' } })
              if (this.$route.query.from && !this.$route.query.from.includes('signin')) {
                this.$router.push(this.$route.query.from, () => {})
              } else {
                this.$router.push('/', () => {})
              }
              localStorage.setItem('rekassa.kz-auth-last-phone-number', this.phoneNumber)
              localStorage.setItem('rekassa.kz-auth-fresh-signin', 'true')
              localStorage.setItem('rekassa.kz-auth-face-touch-id-enabled', 'false')
              localStorage.setItem('rekassa.kz-auth-google-enabled', 'false')
            })
          })
        }).catch((error) => {
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
          this.signInLoading = false
        })
      }).catch((error) => {
        if (error.response.data && error.response.data.code) {
          this.confirmationCodeError = this.$t(`sms/${error.response.data.code}`)
        } else {
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        }
        this.signInLoading = false
      })
    },

    cordovaSignInWithCredential(credential) {
      window.FirebasePlugin.signInWithCredential(credential, () => {
        window.FirebasePlugin.getCurrentUser((user) => {
          // Signing with Google
          if (this.signInWithGoogleLoading) {
            // Allow only users that have phoneNumbers
            if (user.phoneNumber) {
              this.setUser(user).then(() => {
                this.init().then(() => {
                  this.analyticsLogEvent({ eventName: 'login', eventProperties: { type: 'Google' } })
                  this.$router.push('/', () => {})
                })
              })
            } else {
            // Else logout and show warning
              window.FirebasePlugin.signOutUser()
              this.signInWithGoogleLoading = false
              this.step = 'ENTER_PHONE_NUMBER'
              this.showSnackbar({ message: this.$t('данная_учетная_запись_google_не_привязана_к_rekassa_выполните_вход_через_номер_телефона') })
            }
          // SMS auth
          } else {
            this.setUser(user).then(() => {
              this.init().then(() => {
                this.analyticsLogEvent({ eventName: 'login', eventProperties: { type: 'Sms' } })
                this.$router.push('/', () => {})
                localStorage.setItem('rekassa.kz-auth-fresh-signin', 'true')
                localStorage.setItem('rekassa.kz-auth-face-touch-id-enabled', 'false')
                localStorage.setItem('rekassa.kz-auth-google-enabled', 'false')
              })
            })
          }
        }, (error) => {
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      }, (error) => {
        this.signInWithPhoneNumberLoading = false
        this.signInLoading = false
        if (error) {
          if (error.includes('ERROR_INVALID_VERIFICATION_CODE')) {
            this.confirmationCodeError = this.$t('введен_некорректный_код_подтверждения')
          } else if (error.includes('ERROR_SESSION_EXPIRED')) {
            this.confirmationCodeError = this.$t('срок_кода_подтверждения_уже_истек')
          } else this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        }
      })
    },

    cordovaSignInUserWithEmailAndPassword(email, password) {
      window.FirebasePlugin.signInUserWithEmailAndPassword(email, password, () => {
        window.FirebasePlugin.getCurrentUser((user) => {
          this.setUser(user).then(() => {
            this.init().then(() => {
              this.analyticsLogEvent({ eventName: 'login', eventProperties: { type: 'FaceId/TouchId' } })
              this.$router.push('/', () => {})
            })
          })
        }, (error) => {
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      }, (error) => {
        this.signInWithFaceTouchIdLoading = false
        this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      })
    },

    switchLocale(locale) {
      changeLocale(locale)
    },

    windowResize() {
      // eslint-disable-next-line no-mixed-operators
      let width = (window.innerHeight - 667) * (230 - 150) / (844 - 667) + 150
      width = Math.min(width, 230)
      width = Math.max(width, 150)
      this.$refs.loginPhoneFrame.style.width = `${width}px`
    },
  },
}
</script>
<style lang="stylus" scoped>
html, body
  overscroll-behavior-y none
.v-application
  user-select none!important
  .caption
  .v-application
  .subtitle-1
  .subtitle-2
    font-family Roboto, sans-serif!important
    line-height 1.5
  >>> .v-btn__content
    letter-spacing 0.00892857em!important
.height-100
  height 100%!important
.min-height
  min-height 680px!important
.area-inset
  padding-bottom env(safe-area-inset-bottom)!important /* iOS 11.2 */
  padding-bottom constant(safe-area-inset-bottom)!important /* iOS 11.0 */
.phone-btn
  height 36px!important
  width 36px!important
  min-width 36px!important
.ru-i18n-btn, .kk-i18n-btn
  height 40px!important
  width 40px!important
  min-width 40px!important
  font-size 14px!important
  font-weight 500!important
  text-transform capitalize!important
  width 56px!important
  height 36px!important
  &.theme--light.v-btn.v-btn--disabled.v-btn--has-bg
    background-color #D6EBE0!important
  &.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg
    background-color #045d56!important
.kk-i18n-btn
  border-top-left-radius 8px
  border-bottom-left-radius 8px
.ru-i18n-btn
  border-top-right-radius 8px
  border-bottom-right-radius 8px
.i18n-divider
  display inline-flex
  vertical-align middle
  width 1px
  height 36px
.theme--light .i18n-divider
  background-color #9FADA7
.theme--dark .i18n-divider
  background-color #5D8E89
.theme--light.v-application
  background-color #F1F1F1!important
  .primary
    background-color #1EBD98!important
    border-color #1EBD98!important
    color #151515!important
  .secondary
    background-color #D6EBE0!important
    border-color #D6EBE0!important
    color #151515!important
  >>> .primary--text,a
    color #1EBD98!important
    caret-color #1EBD98!important
.theme--light.v-btn.v-btn--disabled.crutch
  color #b6d8fb!important
</style>
