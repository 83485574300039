<template lang="pug">
v-app
  v-app-bar(app dark dense flat)
    v-btn(icon @click="$router.push('/services')")
      v-icon mdi-arrow-left
    v-toolbar-title {{ $t("HALYK_POS") }}
    v-spacer
    template(v-if="user._links.createCashRegister && cashRegister.status === 'REGISTERED' && hasNFC && hasAndroid8AndAbove && !halykPosConnected")
      v-btn(:disabled="loading" @click="showConnectPinpad()" text) {{ $t("подключить") }}

  v-main(flat)
    v-container(style="max-width: 450px")
      v-row.my-4
        v-col(align="center")
          v-img(:src="$vuetify.theme.dark ? imageUrl.dark : imageUrl.light" width="60%" eager)

      v-row
        v-col
          v-list.v-list--fulltext.gray-background-list
            v-list-item
              v-list-item-icon(style="align-self: center; margin-left: 5px; margin-right: 20px")
                v-icon(size="30") mdi-contactless-payment
              v-list-item-content
                v-list-item-title {{ $t("вместе_с_halyk_pos_rekassa_30_позволяет_dotdotdot") }}.
                a.text-uppercase(@click.prevent="$openLink(`https://link.rekassa.kz/halykpos-manual-${locale}`, '_system')") {{ $t("подробнее") }}...
            v-list-item
              v-list-item-content.pt-1
                v-img.cursor-pointer(src="/static/halyk-promo.png" @click="$openLink('https://link.rekassa.kz/halyk-pos-promo', '_system')")
            v-list-item.pt-3(v-if="halykPosConnected && (!hasNFC || !hasAndroid8AndAbove)")
              v-alert(color="warning" outlined)
                .subtitle {{ $t("для_того_чтобы_начать_принимать_бесконтактные_платежи_требуется_dotdotdot") }}.

      v-row(v-if="cashRegister.status === 'REGISTERED'")
        v-col
          v-list.gray-background-list
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("статус") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ halykPosConnected ? $t("подключено") : $t("не_подключено") }}

            div.px-4(v-if="user._links.createCashRegister")
              v-btn.my-3(v-if="!halykPosConnected" color="primary" block :disabled="loading" @click="showConnectPinpad()") {{ $t("подключить") }}
              v-btn.my-3(v-else color="error" block :disabled="loading" @click="showDisconnectPinpad()") {{ $t("отключить") }}

  re-pinpad(v-model="connectDialog" :title="$t('подключение_name', { name: 'Halyk Pos'})" :subtitle="$t('введите_пин-код')" :text="$t('для_подключения_name', { name: 'Halyk Pos'})" :loading="loading" :errorText.sync="connectErrorMessage" @action="connect")
  re-pinpad(v-model="disconnectDialog" :title="$t('отключение_name', { name: 'Halyk Pos'})" :subtitle="$t('введите_пин-код')" :text="$t('для_отключения_name', { name: 'Halyk Pos'})" :loading="loading" :errorText.sync="disconnectErrorMessage" @action="disconnect")

</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../../i18n/index'
import Pinpad from '../utils/PinpadDialog.vue'

export default {
  components: {
    're-pinpad': Pinpad,
  },

  data: () => ({
    imageUrl: {
      dark: '/static/halyk-pos-white.svg',
      light: '/static/halyk-pos-black.svg',
    },
    loading: false,
    connectDialog: false,
    connectErrorMessage: null,
    disconnectDialog: false,
    disconnectErrorMessage: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      hasNFC: state => state.m4bank.hasNFC,
      hasAndroid8AndAbove: state => state.m4bank.hasAndroid8AndAbove,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
    }),

    locale() {
      return getLocale()
    },

    halykPosConnected() {
      return this.configuration.halyk && this.configuration.halyk.halykPosConnected
    },
  },

  methods: {
    ...mapActions({
      hasM4bank: 'm4bank/hasM4bank',
      saveConfiguration: 'cashRegisters/saveConfiguration',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      showSnackbar: 'tools/showSnackbar',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    checkShift() {
      if (this.cashRegister.shiftOpen) {
        this.showSnackbar({ message: this.$t('требуется_закрыть_смену_чтобы_поменять_данный_параметр') })
        return false
      }
      return true
    },

    showConnectPinpad() {
      if (this.$isCordova() && this.$isAndroid()) {
        this.hasM4bank({ packageName: 'ru.m4bank.softpos.halyk' }).then((has) => {
          if (has) {
            if (this.checkShift()) {
              this.connectDialog = true
            }
          } else {
            this.showSnackbar({ message: this.$t('перед_подключением_установите_приложение', { name: 'Halyk Pos' }), url: 'https://halykbank.kz/business/payment/halyk-pos' })
          }
        })
      } else {
        this.showSnackbar({ message: this.$t('перед_подключением_установите_приложение', { name: 'Halyk Pos' }), url: 'https://halykbank.kz/business/payment/halyk-pos' })
      }
    },

    showDisconnectPinpad() {
      if (this.checkShift()) {
        this.disconnectDialog = true
      }
    },

    getModifiedConfiguration(halykPosConnected) {
      return {
        ...this.configuration,
        halyk: {
          halykPosConnected,
        },
      }
    },

    connect(pincode) {
      this.loading = true
      this.saveConfiguration({
        cashRegister: this.cashRegister,
        configuration: this.getModifiedConfiguration(true),
        password: pincode,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id).then(() => {
          this.connectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('успешно_подключен_name', { name: 'Halyk Pos' }) })
          this.analyticsLogEvent({ eventName: 're_halyk_pos_connected', eventProperties: { date: this.$moment().format('DD-MM-YYYY') } })
        })
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.connectErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else {
          this.connectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('произошла_ошибка') })
        }
      })
    },

    disconnect(pincode) {
      this.loading = true
      this.saveConfiguration({
        cashRegister: this.cashRegister,
        configuration: this.getModifiedConfiguration(false),
        password: pincode,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id).then(() => {
          this.disconnectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('успешно_отключен_name', { name: 'Halyk Pos' }) })
          this.analyticsLogEvent({ eventName: 're_halyk_pos_disconnected', eventProperties: { date: this.$moment().format('DD-MM-YYYY') } })
        })
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.disconnectErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else {
          this.disconnectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('произошла_ошибка') })
        }
      })
    },
  },
}
</script>

<style lang="stylus">
</style>
