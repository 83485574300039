<template lang="pug">
v-system-bar(v-if="cashRegisterPaymentMessage" app :color="cashRegisterPaymentMessage.titleColor" height="33" @click="showCashRegisterPaymentMessage()")
  .container.text-center.text-body-1.white--text.cursor-pointer
    v-icon.mb-1(small) mdi-alert
    | {{ cashRegisterPaymentMessage.title }}

v-system-bar(v-else-if="cashRegisterWarningMessage" app :color="cashRegisterWarningMessage.titleColor" height="33" @click="showCashRegisterWarningMessage()")
  .container.text-center.text-body-1.white--text.cursor-pointer
    v-icon.mb-1(small) mdi-alert
    | {{ cashRegisterWarningMessage.title }}
</template>

<script>
import { mapState, mapActions } from 'vuex'
import helpUrls from '../../utils/helpUrls'

export default {
  data: () => ({
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
    }),

    cashRegisterPaymentMessage() {
      if (this.cashRegister.status === 'REGISTERED' && (this.cashRegister.fdoMode === 'OFFLINE' || this.cashRegister.fdoMode === 'BLOCKED') && this.cashRegister.data.fdo?.result.text) {
        const regexList = ['Списание тарифа с ЛС: \\+7(\\d+)\\b',
          'Списывается абонентная плата по лицевому счету: \\+7(\\d+)\\b',
          'Списание тарифа с ЛС: (-?\\d+)\\b',
          'Списывается абонентная плата по лицевому счету: (-?\\d+)\\b']
        const isPayment = regexList.some(rx => this.cashRegister.data.fdo.result.text.match(rx))
        if (isPayment) {
          const isBlocked = this.cashRegister.fdoMode === 'BLOCKED'
          const alert = {
            titleColor: isBlocked ? 'error' : 'warning',
            title: this.$t('оплатите_услуги_офд'),
            text: this.$t('оплатите_услуги_офд_steps'),
            resolveText: this.$t('проверить_оплату_за_офд'),
          }
          if (!isBlocked && this.hoursLeftTillBlocked > 0) {
            alert.text = `${alert.text}${this.$t('не_беспокойтесь_без_оплаты_касса_работает_полноценно_до', { hours: this.hoursLeftTillBlocked ?? 0 })}`
          }

          return alert
        }
        return null
      }

      return null
    },

    cashRegisterWarningMessage() {
      if (this.cashRegisterPaymentMessage === null && this.cashRegister.status === 'REGISTERED' && (this.cashRegister.fdoMode === 'OFFLINE' || this.cashRegister.fdoMode === 'BLOCKED')) {
        const isBlocked = this.cashRegister.fdoMode === 'BLOCKED'
        const alert = {
          titleColor: isBlocked ? 'error' : 'warning',
          title: isBlocked ? this.$t('касса_заблокирована') : this.$t('касса_в_автономном_режиме'),
          text: this.cashRegister.data.fdo && this.cashRegister.data.fdo.result.text ? this.cashRegister.data.fdo.result.text : null,
          resolveText: this.$t('подробнее'),
        }
        return alert
      }
      return null
    },
  },

  created() {
    window.showPaymentMethods = () => {
      this.hideConfirm()
      this.$router.push('/kkm/fdo/payment-methods').catch(() => {
        this.showSnackbar({ message: this.$t('у_вас_нет_доступа_в_меню_настройки') })
      })
    }
  },

  methods: {
    ...mapActions({
      selectCashRegister: 'cashRegisters/selectCashRegister',
      checkCashRegisterConnection: 'cashRegisters/checkCashRegisterConnection',
      analyticsLogEvent: 'analytics/logEvent',
      showConfirm: 'tools/showConfirm',
      hideConfirm: 'tools/hideConfirm',
      showSnackbar: 'tools/showSnackbar',
    }),

    showCashRegisterPaymentMessage() {
      this.analyticsLogEvent({ eventName: 're_show_register_payment_message' })
      this.showConfirm({
        persistent: true,
        title: this.cashRegisterPaymentMessage.title,
        text: this.cashRegisterPaymentMessage.text,
        resolveText: this.cashRegisterPaymentMessage.resolveText,
        resolveCallback: () => new Promise((resolve) => {
          this.checkCashRegisterConnection(this.cashRegister.id).then((cr) => {
            if (cr.data.fdoMode === 'ONLINE') {
              this.selectCashRegister(this.cashRegister.id).then(() => {
                resolve({ hide: true })
              }).catch((error) => {
                this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
                resolve({ hide: true })
              })
            } else {
              this.showSnackbar({ message: this.cashRegister.data.fdo?.result.text, timeout: 10000 })
              resolve({ hide: true })
            }
          }).catch((error) => {
            this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
            resolve({ hide: true })
          })
        }),
      })
    },

    showCashRegisterWarningMessage() {
      this.analyticsLogEvent({ eventName: 're_show_register_offline_blocked_message', eventProperties: { fdoMode: this.cashRegister.fdoMode } })
      this.showConfirm({
        persistent: true,
        title: this.cashRegisterWarningMessage.title,
        text: this.cashRegisterWarningMessage.text,
        resolveText: this.cashRegisterWarningMessage.resolveText,
        resolveCallback: () => new Promise((resolve) => {
          this.$openLink(helpUrls[this.cashRegister.fdoMode], '_system')
          resolve({ hide: true })
        }),
      })
    },
  },
}
</script>
<style lang="stylus">
</style>
