<template lang="pug">
v-app
  v-app-bar(app dark dense flat)
    v-btn(icon @click="$router.push(`/shifts/${cashRegister.id}`)")
      v-icon mdi-arrow-left
    v-toolbar-title {{ $t("фискальный_отчёт") }}
    v-progress-linear(:active="loading" :indeterminate="loading" absolute bottom)

  v-main(flat :class="rollWidthClass")
    v-container(style="max-width: 450px")
      v-form.d-print-none(ref="form")
        v-subheader.pl-0 {{ $t("период_отчёта") }}
        v-row
          v-col.pb-0(cols="6" sm="5")
            v-menu(v-model="calendarFromMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" :disabled="loading")
              template(v-slot:activator="{ on, attrs }")
                v-text-field(v-model="fromDate" :label="$t('начало')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" :rules="[rules.required]")
              v-date-picker(v-model="fromDate" @input="calendarFromMenu = false" :locale="locale")
          v-col.pb-0(cols="6" sm="5")
            v-menu(v-model="calendarToMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" :disabled="loading")
              template(v-slot:activator="{ on, attrs }")
                v-text-field(v-model="toDate" :label="$t('конец')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" :rules="[rules.required]")
              v-date-picker(v-model="toDate" @input="calendarToMenu = false" :locale="locale")
          v-col.py-0(cols="12" sm="2" align-self="center")
            v-btn(small color="primary" @click="fetch()" block :disabled="loading") {{ $t("ок") }}
        v-row
          v-col.py-0
            v-checkbox(v-model="showNonNullableSums" :label="$t('отобразить_необнуляемые_суммы')")
        v-row(v-if="cashRegister.shiftOpen")
          v-col.pb-0
            v-alert(color="yellow darken-2" dark icon="mdi-alert" dense text outlined)
              .subtitle {{ $t("чеки_текущей_смены_не_включены_в_отчёт") }}

    v-container.skeleton(v-if="loading" style="max-width: 400px")
      v-skeleton-loader.mt-5.mx-auto(type="text" boilerplate style="width: 150px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate style="width: 200px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate style="width: 200px;")

      v-skeleton-loader.mt-5.mx-auto(type="text" boilerplate)
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)

      v-skeleton-loader.mt-7.mx-auto(type="text" boilerplate style="width: 80px;")

      v-skeleton-loader.mt-7.mx-auto(type="text" boilerplate)
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)

      v-divider.my-3

      v-skeleton-loader.mt-5(type="text" boilerplate style="width: 300px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)

      v-divider.my-3

      v-skeleton-loader.mt-5(type="text" boilerplate style="width: 100px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)

      v-divider.my-3

      v-skeleton-loader.mt-5(type="text" boilerplate style="width: 300px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)

      v-divider.my-3

      v-skeleton-loader.mt-5.mx-auto(type="text" boilerplate style="width: 200px;")

    v-container.receipt(v-if="report" style="max-width: 400px")
      v-row
        v-col
          h1.headline.font-weight-bold.text-center {{ organization.businessName }}
          h2.subtitle-1.text-center {{ $t("бин_иин") }}: {{ organization.businessId }}
          h2.subtitle-2.text-center {{ cashRegister.pos.address }}

      v-row.mt-4
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("рнм") }}:
            div {{ cashRegister.registrationNumber }}
      v-row
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("знм") }}:
            div {{ cashRegister.serialNumber }}
      v-row
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("ккм") }}:
            div {{ cashRegister.model }}

      v-row.my-4
        v-col
          h2.title.text-center {{ $t("фискальный_отчёт") }}

      v-row
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("дата_с") }}:
            div {{ $moment(`${report.dateFrom.day}-${report.dateFrom.month}-${report.dateFrom.year}`, 'D-M-YYYY').format('DD-MM-YYYY') }}
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("смена_с") }}:
            div №{{ report.shiftNumberFrom }}
      v-row
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("дата_по") }}:
            div {{ $moment(`${report.dateTo.day}-${report.dateTo.month}-${report.dateTo.year}`, 'D-M-YYYY').format('DD-MM-YYYY') }}
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("смена_по") }}:
            div №{{ report.shiftNumberTo }}

      v-divider.my-3(v-if="showNonNullableSums")

      v-row(v-if="showNonNullableSums")
        v-col
          .text-uppercase {{ $t("необнуляемая_сумма_на_начало_№_смены", { number: report.shiftNumberFrom }) }}
          v-list.mt-1
            v-list-item(v-for="(item, $index) in startShiftNonNullableSums" :key="'s_' + $index")
              v-list-item-content
                v-list-item-title {{ $t('operationType.' + item.operation) }}
              v-list-item-action
                v-list-item-action-text.body-1 {{ item.sum.value | numeral('0,0.00') | beautiful-minus }} ₸

      v-divider(v-if="ticketOperations.length > 0").my-3

      v-row.mt-2.mb-2(v-for="(ticketOperation, $index) in ticketOperations" :key="'t_' + $index")
        v-col
          .text-uppercase {{ $t('operationType.' + ticketOperation.operation) }}
          v-list
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("количество_чеков") }}
              v-list-item-action.flex-row-reverse
                v-list-item-action-text.body-1 {{ ticketOperation.ticketsCount | numeral('0,0') }}
            v-list-item(v-for="(payment, $index) in ticketOperation.payments" :key="'p_' + $index")
              v-list-item-content
                v-list-item-title {{ $t("paymentType." + payment.payment) }}
              v-list-item-action
                v-list-item-action-text.body-1 {{ payment.sum.value | numeral('0,0.00') | beautiful-minus }} ₸
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("сумма") }}
              v-list-item-action
                v-list-item-action-text.body-1 {{ ticketOperation.ticketsSum.value | numeral('0,0.00') | beautiful-minus }} ₸
            template(v-for="(tax, $index) in taxes")
              v-list-item(v-for="(operation, $index) in tax.operations" :key="tax.percent + '_o_' + $index" v-if="operation.operation === ticketOperation.operation && operation.sum.value > 0")
                v-list-item-content
                  v-list-item-title {{ $t("в_т_ч_ндс") }} {{ tax.percent / 1000 }}%
                v-list-item-action
                  v-list-item-action-text.body-1 {{ operation.sum.value | numeral('0,0.00') | beautiful-minus }} ₸

      v-row.mt-2.mb-2(v-for="(moneyPlacement, $index) in moneyPlacements" :key="'m_' + $index" v-if="moneyPlacement.operationsCount > 0")
        v-col
          .text-uppercase {{ $t('operationType.' + moneyPlacement.operation) }}
          v-list
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("количество_чеков") }}
              v-list-item-action.flex-row-reverse
                v-list-item-action-text.body-1 {{ moneyPlacement.operationsCount | numeral('0,0') }}
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("сумма") }}
              v-list-item-action
                v-list-item-action-text.body-1 {{ moneyPlacement.operationsSum.value | numeral('0,0.00') | beautiful-minus }} ₸

      v-divider.my-3(v-if="showNonNullableSums")

      v-row(v-if="showNonNullableSums")
        v-col
          .text-uppercase {{ $t("необнуляемая_сумма_на_конец_№_смены", { number: report.shiftNumberTo }) }}
          v-list.mt-1
            v-list-item(v-for="(item, $index) in nonNullableSums" :key="'s_' + $index")
              v-list-item-content
                v-list-item-title {{ $t('operationType.' + item.operation) }}
              v-list-item-action
                v-list-item-action-text.body-1 {{ item.sum.value | numeral('0,0.00') | beautiful-minus }} ₸

      v-divider.my-3

      v-row
        v-col
          .text-uppercase {{ $t("наличных_в_кассе") }}
          v-list.mt-1
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("сумма") }}
              v-list-item-action
                v-list-item-action-text.body-1 {{ cashSum | numeral('0,0.00') | beautiful-minus }} ₸

      v-divider.my-3

      v-row
        v-col.text-center
          div {{ $t(`fdo.${cashRegister.fdo}.title`) }}
          div
            span {{ $t(`fdo.${cashRegister.fdo}.url`) }}

  v-bottom-navigation(app dark)
    v-btn(icon @click="downloadReceipt()" :loading="downloadLoading" :disabled="loading || !report")
      v-icon mdi-download

    v-btn(icon @click="print()" :loading="printingLoading" :disabled="loading || !report")
      v-icon mdi-printer
</template>

<script>
import { mapState, mapActions } from 'vuex'
import html2canvas from 'html2canvas'
import download from 'downloadjs'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import billsAndCoinsMixin from '../../mixins/billsAndCoinsMixin'
import { getLocale } from '../../i18n'

export default {

  mixins: [dictionaryMixin, billsAndCoinsMixin],

  data: () => ({
    loading: false,
    downloadLoading: false,
    printingLoading: false,

    calendarFromMenu: false,
    calendarToMenu: false,
    fromDate: null,
    toDate: null,
    showNonNullableSums: false,

    report: null,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      printerSettings: state => state.printer.settings,
    }),

    startShiftNonNullableSums() {
      return this.report.startShiftNonNullableSums.map((item) => {
        item.sum.value = this.getNumberFromBillsAndCoins(item.sum)
        return item
      }).sort(((a, b) => this.dictionary.operationType[a.operation].order - this.dictionary.operationType[b.operation].order))
    },

    nonNullableSums() {
      return this.report.nonNullableSums.map((item) => {
        item.sum.value = this.getNumberFromBillsAndCoins(item.sum)
        return item
      }).sort(((a, b) => this.dictionary.operationType[a.operation].order - this.dictionary.operationType[b.operation].order))
    },

    ticketOperations() {
      if (this.report.ticketOperations) {
        return this.report.ticketOperations.reduce((items, item) => {
          if (item.ticketsCount > 0) {
            item.ticketsSum.value = this.getNumberFromBillsAndCoins(item.ticketsSum)
            if (item.payments) {
              item.payments = item.payments.map((payment) => {
                payment.sum.value = this.getNumberFromBillsAndCoins(payment.sum)
                return payment
              }).sort(((a, b) => this.dictionary.paymentType[a.payment].order - this.dictionary.paymentType[b.payment].order))
            }
            items.push(item)
          }
          return items
        }, []).sort(((a, b) => this.dictionary.operationType[a.operation].order - this.dictionary.operationType[b.operation].order))
      }
      return []
    },

    moneyPlacements() {
      if (this.report.moneyPlacements) {
        return this.report.moneyPlacements.map((item) => {
          item.operationsSum.value = this.getNumberFromBillsAndCoins(item.operationsSum)
          return item
        }).sort(((a, b) => this.dictionary.operationType[a.operation].order - this.dictionary.operationType[b.operation].order))
      }
      return []
    },

    taxes() {
      if (this.report.taxes) {
        return this.report.taxes.map((tax) => {
          tax.operations.map((operation) => {
            operation.sum.value = this.getNumberFromBillsAndCoins(operation.sum)
            return operation
          })
          return tax
        }).sort(((a, b) => a.percent - b.percent))
      }
      return []
    },

    cashSum() {
      return this.getNumberFromBillsAndCoins(this.report.cashSum)
    },

    rollWidthClass() {
      return this.printerSettings.rollWidth === 58 ? 'roll-width-58mm' : 'roll-width-80mm'
    },

    locale() {
      return getLocale()
    },
  },

  created() {
    this.fromDate = this.$moment().startOf('month').format('YYYY-MM-DD')
    this.toDate = this.$moment().format('YYYY-MM-DD')
  },

  methods: {
    ...mapActions({
      fetchFiscalReport: 'cashRegisters/fetchFiscalReport',
      printReceipt: 'printer/printReceipt',
      showSnackbar: 'tools/showSnackbar',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    fetch() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.fetchFiscalReport({
          cashRegister: this.cashRegister,
          fromDate: this.fromDate,
          toDate: this.toDate,
        }).then((result) => {
          this.loading = false
          this.report = result.data
          if (localStorage.getItem('rekassa.kz-ui-ticketAutoScroll') === 'true') {
            setTimeout(() => {
              this.$vuetify.goTo(document.getElementsByClassName('receipt')[0].offsetHeight)
            }, 500)
          }
          if (result && result.status === 204) {
            this.showSnackbar({ message: this.$t('ничего_не_найдено') })
          }
        }).catch(() => {
          this.loading = false
        })

        this.analyticsLogEvent({ eventName: 're_reports_ran_fiscal_report' })
      }
    },

    downloadReceipt() {
      this.downloadLoading = true
      setTimeout(() => {
        const receipt = document.getElementsByClassName('receipt')[0]
        html2canvas(receipt, {
          backgroundColor: '#ffffff',
          y: receipt.offsetTop + 40,
          scale: 2,
        }).then(canvas => {
          if (this.$isCordova()) {
            window.canvas2ImagePlugin.saveImageDataToLibrary(
              () => {
                this.showSnackbar({ message: this.$t('чек_успешно_сохранен_в_галерее') })
                this.downloadLoading = false
              },
              (error) => {
                this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
                this.downloadLoading = false
              },
              canvas,
              `${this.$t('фискальный_отчёт')} ${this.$moment(`${this.report.dateFrom.day}-${this.report.dateFrom.month}-${this.report.dateFrom.year}`, 'D-M-YYYY').format('DD-MM-YYYY')} ${this.$moment(`${this.report.dateTo.day}-${this.report.dateTo.month}-${this.report.dateTo.year}`, 'D-M-YYYY').format('DD-MM-YYYY')}`,
              80,
              `reKassa_${this.cashRegister.serialNumber}`,
            )
          } else {
            download(canvas.toDataURL('image/png'), `${this.$t('фискальный_отчёт')} ${this.$moment(`${this.report.dateFrom.day}-${this.report.dateFrom.month}-${this.report.dateFrom.year}`, 'D-M-YYYY').format('DD-MM-YYYY')} ${this.$moment(`${this.report.dateTo.day}-${this.report.dateTo.month}-${this.report.dateTo.year}`, 'D-M-YYYY').format('DD-MM-YYYY')}`)
            this.downloadLoading = false
          }
        }).catch((error) => {
          this.showSnackbar({ message: `${this.$t('не_удалось_cкачать_чек')}: ${error}` })
          this.downloadLoading = false
        })
      }, 0)
    },

    print() {
      if (this.$isCordova()) {
        this.printCordova()
      } else {
        this.printWeb()
      }
    },

    printWeb() {
      window.print()
    },

    printCordova() {
      this.printingLoading = true

      const printCmds = []
      printCmds.push('#align_center#')
      printCmds.push(this.organization.businessName)
      printCmds.push('#new_line#')

      printCmds.push(`${this.$t('бин_иин')} ${this.organization.businessId}`)
      printCmds.push('#new_line#')

      printCmds.push(this.cashRegister.pos.address)
      printCmds.push('#new_line#')

      printCmds.push('#align_left#')

      printCmds.push(this.$t('рнм'))
      printCmds.push('#full_width#')
      printCmds.push(this.cashRegister.registrationNumber)
      printCmds.push('#new_line#')

      printCmds.push(this.$t('знм'))
      printCmds.push('#full_width#')
      printCmds.push(this.cashRegister.serialNumber)
      printCmds.push('#new_line#')

      printCmds.push(this.$t('ккм'))
      printCmds.push('#full_width#')
      printCmds.push(this.cashRegister.model)
      printCmds.push('#new_line#')

      printCmds.push('#align_center#')
      printCmds.push(this.$t('фискальный_отчёт'))
      printCmds.push('#new_line#')

      printCmds.push('#align_left#')

      printCmds.push(`${this.printerSettings.rollWidth === 58 ? `${this.$t('дата_с_short')} ` : `${this.$t('дата_с')} `}`)
      printCmds.push('#half_line#')
      printCmds.push(this.$moment(`${this.report.dateFrom.day}-${this.report.dateFrom.month}-${this.report.dateFrom.year}`, 'D-M-YYYY').format('DD-MM-YYYY'))
      printCmds.push(`${this.printerSettings.rollWidth === 58 ? `${this.$t('смена_с_short')} ` : `${this.$t('смена_с')} `}`)
      printCmds.push('#half_line#')
      printCmds.push(`#${this.report.shiftNumberFrom}`)
      printCmds.push('#new_line#')

      printCmds.push(`${this.printerSettings.rollWidth === 58 ? `${this.$t('дата_по_short')} ` : `${this.$t('дата_по')} `}`)
      printCmds.push('#half_line#')
      printCmds.push(this.$moment(`${this.report.dateTo.day}-${this.report.dateTo.month}-${this.report.dateTo.year}`, 'D-M-YYYY').format('DD-MM-YYYY'))
      printCmds.push(`${this.printerSettings.rollWidth === 58 ? `${this.$t('смена_по_short')} ` : `${this.$t('смена_по')} `}`)
      printCmds.push('#half_line#')
      printCmds.push(`#${this.report.shiftNumberTo}`)
      printCmds.push('#new_line#')

      if (this.showNonNullableSums) {
        printCmds.push('#line#')

        printCmds.push(this.$t('необнуляемая_сумма_на_начало_№_смены_print', { number: this.report.shiftNumberFrom }).toUpperCase())
        printCmds.push('#new_line#')

        for (let index = 0; index < this.startShiftNonNullableSums.length; index += 1) {
          const item = this.startShiftNonNullableSums[index]
          printCmds.push(this.$t(`operationType.${item.operation}`))
          printCmds.push('#full_width#')
          printCmds.push(`${this.$options.filters.numeral(item.sum.value, '0,0.00')}₸`)
          printCmds.push('#new_line#')
        }
      }

      printCmds.push('#line#')

      for (let index = 0; index < this.ticketOperations.length; index += 1) {
        const ticketOperation = this.ticketOperations[index]
        printCmds.push(this.$t(`operationType.${ticketOperation.operation}`).toUpperCase())
        printCmds.push('#new_line#')

        printCmds.push(this.$t('кол-во_чеков'))
        printCmds.push('#full_width#')
        printCmds.push(this.$options.filters.numeral(ticketOperation.ticketsCount, '0,0'))
        printCmds.push('#new_line#')

        for (let index2 = 0; index2 < ticketOperation.payments.length; index2 += 1) {
          const payment = ticketOperation.payments[index2]
          printCmds.push(this.$t(`paymentType.${payment.payment}`))
          printCmds.push('#full_width#')
          printCmds.push(`${this.$options.filters.numeral(payment.sum.value, '0,0.00')}₸`)
          printCmds.push('#new_line#')
        }

        printCmds.push(this.$t('сумма'))
        printCmds.push('#full_width#')
        printCmds.push(`${this.$options.filters.numeral(ticketOperation.ticketsSum.value, '0,0.00')}₸`)
        printCmds.push('#new_line#')

        for (let index2 = 0; index2 < this.taxes.length; index2 += 1) {
          const tax = this.taxes[index2]
          for (let index3 = 0; index3 < tax.operations.length; index3 += 1) {
            const operation = tax.operations[index3]
            if (operation.operation === ticketOperation.operation && operation.sum.value > 0) {
              printCmds.push(`${this.$t('в_т_ч_ндс')} ${tax.percent / 1000}%`)
              printCmds.push('#full_width#')
              printCmds.push(`${this.$options.filters.numeral(operation.sum.value, '0,0.00')}₸`)
              printCmds.push('#new_line#')
            }
          }
        }
      }

      for (let index = 0; index < this.moneyPlacements.length; index += 1) {
        const moneyPlacement = this.moneyPlacements[index]
        if (moneyPlacement.operationsCount > 0) {
          printCmds.push(this.$t(`operationType.${moneyPlacement.operation}`).toUpperCase())
          printCmds.push('#new_line#')

          printCmds.push(this.$t('сумма'))
          printCmds.push('#full_width#')
          printCmds.push(`${this.$options.filters.numeral(moneyPlacement.operationsSum.value, '0,0.00')}₸`)
          printCmds.push('#new_line#')
        }
      }

      if (this.showNonNullableSums) {
        printCmds.push('#line#')

        printCmds.push(this.$t('необнуляемая_сумма_на_конец_№_смены_print', { number: this.report.shiftNumberTo }).toUpperCase())
        printCmds.push('#new_line#')

        for (let index = 0; index < this.nonNullableSums.length; index += 1) {
          const item = this.nonNullableSums[index]
          printCmds.push(this.$t(`operationType.${item.operation}`))
          printCmds.push('#full_width#')
          printCmds.push(`${this.$options.filters.numeral(item.sum.value, '0,0.00')}₸`)
          printCmds.push('#new_line#')
        }
      }

      printCmds.push('#line#')

      printCmds.push(this.$t('наличных_в_кассе').toUpperCase())
      printCmds.push('#new_line#')

      printCmds.push(this.$t('сумма'))
      printCmds.push('#full_width#')
      printCmds.push(`${this.$options.filters.numeral(this.cashSum, '0,0.00')}₸`)
      printCmds.push('#new_line#')

      printCmds.push('#line#')
      printCmds.push('#align_center#')

      printCmds.push(this.$t(`fdo.${this.cashRegister.fdo}.title`))
      printCmds.push('#new_line#')
      printCmds.push(this.$t(`fdo.${this.cashRegister.fdo}.url`))
      printCmds.push('#new_line#')

      const payload = {
        cmds: printCmds,
        qrCode: null,
      }

      this.printReceipt(payload).then(() => {
        setTimeout(() => { this.printingLoading = false }, 500)
      }, () => {
        setTimeout(() => { this.printingLoading = false }, 500)
      })
    },
  },
}
</script>

<style lang="stylus">

</style>
