<template lang="pug">
v-container(style="max-width: 600px")
  v-progress-linear(:active="loading" :indeterminate="loading" absolute top :style="$vuetify.breakpoint.mdAndUp ? 'z-index: 5;' : 'top: -4px; z-index: 5;'")

  div.px-4(v-if="loading")
    v-skeleton-loader.mt-6(type="text" boilerplate style="width: 100px;")
    v-skeleton-loader.mt-7(type="heading" boilerplate style="width: 300px;")
    v-skeleton-loader.mt-5.mb-3(type="text" boilerplate style="width: 300px;")

  v-card(outlined v-if="lastShift && !loading")
    v-list-item(three-line)
      v-list-item-content
        .h1 {{ $t("оборот_по_кассе") }}
        v-list-item-title.my-2.display-2.font-weight-medium {{ lastShift.overall | numeral('0,0.[00]') | beautiful-minus }} ₸
        v-list-item-subtitle
          template(v-if="lastShift.data.nonNullableSums")
            span(v-for="(nonNullableSum, index) in lastShift.data.nonNullableSums")
              | {{ index !== 0 ? '&nbsp;&bull;&nbsp;' : ''}} {{ $t('operationType.' + nonNullableSum.operation) }}: {{ getNumberFromBillsAndCoins(nonNullableSum.sum) | numeral('0,0.[00]') | beautiful-minus }} ₸
        v-list-item-subtitle
          div.mt-1 {{ $t("наличных_в_кассе") }}: {{ getNumberFromBillsAndCoins(lastShift.data.cashSum) | numeral('0,0.[00]') | beautiful-minus }} ₸

    v-btn.ml-4.mt-2.mb-5.rounded(elevation="0" to="/reports/fiscal") {{ $t("фискальный_отчёт") }}

  v-progress-linear(height="1" color="primary" value="100")

  div.px-4(v-if="loading")
    v-skeleton-loader.mt-6(type="text" boilerplate style="width: 100px;")
    v-skeleton-loader.mt-6(type="list-item-two-line" boilerplate)

    v-skeleton-loader.mt-10(type="text" boilerplate style="width: 100px;")
    v-skeleton-loader.mt-6(type="list-item-two-line" boilerplate)
    v-skeleton-loader.mt-6(type="list-item-two-line" boilerplate)
    v-skeleton-loader.mt-6(type="list-item-two-line" boilerplate)
    v-skeleton-loader.mt-6(type="list-item-two-line" boilerplate)
    v-skeleton-loader.mt-6(type="list-item-two-line" boilerplate)
    v-skeleton-loader.mt-6(type="list-item-two-line" boilerplate)
    v-skeleton-loader.mt-6(type="list-item-two-line" boilerplate)
    v-skeleton-loader.mt-6(type="list-item-two-line" boilerplate)
    v-skeleton-loader.mt-6(type="list-item-two-line" boilerplate)
    v-skeleton-loader.mt-6(type="list-item-two-line" boilerplate)

  v-list(two-line subheader v-if="lastShift && lastShift.open && !loading")
    .subtitle-1.py-3.px-4 {{ $t("текущая_смена") }}
    v-list-item-group

      v-list-item(@click="goToShift(lastShift.shiftNumber)")
        v-list-item-content
          v-list-item-title {{ lastShift.revenue | numeral('0,0.[00]') | beautiful-minus }} ₸
          v-list-item-subtitle №{{ lastShift.shiftNumber }} &bull; {{ $t("чеков") }}: {{ lastShift.shiftTicketNumber }}
        v-list-item-action
          v-list-item-action-text {{ $t("открыта") }}
          v-icon mdi-progress-check

  v-list(subheader v-if="(lastShift === null || !lastShift.open) && !loading && cashRegister.status !== 'DEREGISTERED'")
    .subtitle-1.py-3.px-4 {{ $t("текущая_смена") }}
    v-list-item-group
      v-list-item
        v-list-item-content
          v-list-item-title.grey--text {{ $t("смена_не_открыта") }}

  v-progress-linear(height="1" color="primary" value="100")

  v-list(two-line subheader v-if="shiftList && shiftList.length > 0")
    .subtitle-1.py-3.px-4 {{ $t("закрытые_смены") }}
    v-list-item-group

      v-list-item(v-for="shift in shiftList" @click="goToShift(shift.shiftNumber)" :key="shift.shiftNumber")
        v-list-item-content
          v-list-item-title {{ shift.revenue | numeral('0,0.[00]') | beautiful-minus }} ₸
          v-list-item-subtitle №{{ shift.shiftNumber }} &bull; {{ $t("чеков") }}: {{ shift.shiftTicketNumber }}
        v-list-item-action
          v-list-item-action-text
            v-icon.mr-1(size="12" v-if="shift.offline") mdi-cloud-off-outline
            | {{ shift.closeTime | moment("DD.MM.YYYY HH:mm") }}
          v-icon(v-if="shift.status === 'OPEN'") mdi-progress-check
          v-icon(color="success" v-if="shift.status === 'OK'") mdi-check-all
          v-icon(color="success" v-if="shift.status === 'SAVED'") mdi-check
          v-icon(color="error" v-if="shift.status === 'ERROR'") mdi-alert-circle-outline

  div.my-4.text-center.mb-6(v-if="totalPages > 1")
    v-pagination(v-model="shiftPage.current" :length="totalPages" :total-visible="5")

</template>
<script>
import { Decimal } from 'decimal.js'
import { mapActions, mapState } from 'vuex'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import billsAndCoinsMixin from '../../mixins/billsAndCoinsMixin'

export default {
  mixins: [dictionaryMixin, billsAndCoinsMixin],

  data: () => ({
    shiftList: [],
    loading: false,
    totalPages: 0,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      shiftPage: state => state.cashRegisters.shiftPage,
    }),

    today() {
      return new Date()
    },

    lastShift() {
      if (this.cashRegister.shift) {
        const lastShift = this.cashRegister.shift
        if (this.cashRegister.shift.data && this.cashRegister.shift.data.revenue) {
          lastShift.revenue = (lastShift.data.revenue.isNegative ? -1 : 1) * this.getNumberFromBillsAndCoins(lastShift.data.revenue.sum)
        } else {
          lastShift.revenue = 0
        }
        lastShift.data.nonNullableSums = lastShift.data.nonNullableSums.sort(((a, b) => this.dictionary.operationType[a.operation].order - this.dictionary.operationType[b.operation].order))
        lastShift.overall = lastShift.data.nonNullableSums ? this.collectNonNullableSums(lastShift.data.nonNullableSums) : 0
        return this.cashRegister.shift
      }
      return null
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    'shiftPage.current': function (value) {
      this.fetch()
      window.history.replaceState(null, '', `?page=${value}`)
    },

    // eslint-disable-next-line func-names
    'cashRegister.id': function () {
      this.init()
    },
  },

  created() {
    this.init()
  },

  methods: {
    ...mapActions({
      fetchShiftList: 'cashRegisters/fetchShiftList',
      selectCashRegisterForShift: 'cashRegisters/selectCashRegisterForShift',
    }),

    init() {
      this.selectCashRegisterForShift(this.cashRegister.id).then(() => {
        if (this.$route.query.page) {
          this.shiftPage.current = parseInt(this.$route.query.page, 10)
        }
        this.fetch()
      })
    },

    goToShift(shiftNumber) {
      this.$router.push({ name: 'Shift', params: { shiftNumber } })
    },

    nonNullableSumToNumber(item) {
      return (item.operation === 'OPERATION_SELL' ? 1 : -1) * this.getNumberFromBillsAndCoins(item.sum)
    },

    collectNonNullableSums(nonNullableSums) {
      return nonNullableSums
        .map((item) => this.nonNullableSumToNumber(item))
        .reduce((a, b) => new Decimal(a).plus(b).toNumber(), 0)
    },

    fetch() {
      this.loading = true
      this.shiftList = []

      this.fetchShiftList({ cashRegister: this.cashRegister }).then((result) => {
        this.totalPages = result.data && result.data.page ? result.data.page.totalPages : 0
        const shifts = result.data && result.data._embedded ? result.data._embedded.shifts : []
        this.shiftList = shifts
          .sort((a, b) => b.shiftNumber - a.shiftNumber)
          .map((shift) => {
            if (shift.data && shift.data.revenue) {
              return { ...shift, revenue: (shift.data.revenue.isNegative ? -1 : 1) * this.getNumberFromBillsAndCoins(shift.data.revenue.sum) }
            }
            return { ...shift, revenue: 0 }
          })
        this.loading = false
      })
    },
  },
}
</script>
