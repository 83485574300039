<template lang="pug">
div
  v-system-bar(app height="auto" color="primary")
    v-row.px-3(align="center" style="height: 50px; max-width: 400px; margin: 0 auto;")
      span
        v-icon.mb-1.mr-2 mdi-alert-circle
        .subtitle-1.white--text.d-inline {{ $t("зарегистрируйте_кассу") }}
      v-spacer
      v-btn(small elevation="0" color="warning" @click="showActivation2()") {{ $t("начать") }}

  v-dialog(v-model="dialog" content-class="small-dialog" max-width="400px")
    v-card(style="background-color: #009788 !important;")
      v-card-title.pb-0
        v-row
          v-col.pl-5.py-0(cols="10")
            .headline.grey--white {{ $t("необходимо_активировать_кассу_в_налоговой") }}
          v-col.py-0(cols="2")
            v-btn(icon @click="dialog = false")
              v-icon.grey--white mdi-close
      v-card-text.pt-0.pl-0.pb-2.pb-md-5
        v-row
          v-col.pl-0.pr-0(cols="5")
            v-img(src="/static/tax-agent-activate.png" width="100%")
          v-col(cols="7")
            v-row
              v-col.pl-0.pb-0
                ul
                  li.subtitle-1.grey--white(style="line-height: 1.3rem;") {{ $t("чтобы_перейти_из_демо_режима_в_рабочий_вам_нужно_поставить_кассу_на_учет") }}
                  li.subtitle-1.mt-2.grey--white(style="line-height: 1.3rem;") {{ $t("процесс_занимает_3_минуты") }}
              v-col.pl-0.pb-0
                v-btn.mt-1.ml-5(color="warning" elevation="0" @click="showActivation()") {{ $t("поставить_на_учет") }}

</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    dialog: false,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
    }),
  },

  created() {
    if (this.cashRegister.status === 'TRIAL' && (localStorage.getItem('rekassa.kz-activation-abuse-showed') || 'false') === 'false') {
      this.dialog = true
      localStorage.setItem('rekassa.kz-activation-abuse-showed', 'true')
      this.analyticsLogEvent({ eventName: 're_cash_activate_pop_up_impression' })
    }
  },

  methods: {
    ...mapActions({
      analyticsLogEvent: 'analytics/logEvent',
      showConfirm: 'tools/showConfirm',
    }),

    showActivation() {
      this.dialog = false
      this.$router.push('/ofdreg')
      this.analyticsLogEvent({ eventName: 're_cash_activate_pop_up_click_button' })
    },

    showActivation2() {
      this.$router.push('/ofdreg')
      this.analyticsLogEvent({ eventName: 're_cash_activate_block_click_button' })
    },

    showChat() {
      if (!this.$isCordova()) {
        window.HelpCrunch('showChatWidget')
        window.HelpCrunch('openChat')
      } else {
        window.cordova.plugins.HelpCrunchPlugin.showChatScreen(() => {}, () => {})
      }
    },
  },
}
</script>
<style lang="stylus">
@media(max-width 599px)
  .small-dialog
    position absolute
    top 0
    margin 0
    border-radius 0px 0 20px 20px
@media(min-width 600px)
  .small-dialog
    border-radius 10px 10px 10px 10px
</style>
