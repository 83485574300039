<template lang="pug">
v-app
  v-app-bar(app dense flat dark)
    v-btn(icon @click="$goBack()")
      v-icon mdi-arrow-left
    v-toolbar-title(v-if="$vuetify.breakpoint.smAndUp") {{ $t("смена") }} №{{ shiftNumber }}
      span(v-if="shift !== null") {{ shift.open ? `: ${$t('x-отчёт')}` : `: ${$t('z-отчёт')}` }}
    v-progress-linear(:active="loading && !closeShiftPinpadDialog" :indeterminate="loading" absolute bottom)
    v-spacer
    v-btn(v-if="shift && shift.open" text :disabled="loading" @click="showPinpad()") {{ $t("закрыть_смену") }}

  v-main(flat :class="rollWidthClass")
    v-container.skeleton(v-if="shift === null" style="max-width: 400px")
      v-skeleton-loader.mt-5.mx-auto(type="text" boilerplate style="width: 150px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate style="width: 200px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate style="width: 200px;")

      v-skeleton-loader.mt-5.mx-auto(type="text" boilerplate)
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)

      v-skeleton-loader.mt-7.mx-auto(type="text" boilerplate style="width: 80px;")

      v-skeleton-loader.mt-7.mx-auto(type="text" boilerplate)
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)

      v-divider.my-3

      v-skeleton-loader.mt-5(type="text" boilerplate style="width: 300px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)

      v-divider.my-3

      v-skeleton-loader.mt-5(type="text" boilerplate style="width: 100px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)

      v-divider.my-3

      v-skeleton-loader.mt-5(type="text" boilerplate style="width: 300px;")
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)
      v-skeleton-loader.mt-3.mx-auto(type="text" boilerplate)

      v-divider.my-3

      v-skeleton-loader.mt-5.mx-auto(type="text" boilerplate style="width: 200px;")

    v-container.receipt(v-if="shift" style="max-width: 400px")
      v-row
        v-col
          h1.headline.font-weight-bold.text-center {{ organization.businessName }}
          h2.subtitle-1.text-center {{ $t("бин_иин") }}: {{ organization.businessId }}
          h2.subtitle-2.text-center {{ cashRegister.pos.address }}

      v-row.mt-4
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("рнм") }}:
            div {{ cashRegister.registrationNumber }}
      v-row
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("знм") }}:
            div {{ cashRegister.serialNumber }}
      v-row
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("ккм") }}:
            div {{ cashRegister.model }}

      v-row.my-4
        v-col
          h2.title.text-center {{ shift.open ? 'X' : 'Z' }}-{{ $t("отчёт") }}

      v-row(v-if="shift.offline")
        v-col.text-uppercase {{ $t("автономный") }}
      v-row
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("смена") }}:
            div №{{ shift.shiftNumber }}
        v-col(v-if="shift.operator && shift.operator.name" cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("кассир") }}:
            div {{ shift.operator.name }}
        v-col(v-else cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("кассир") }}:
            div {{ shift.operator ? shift.operator.code : '' }}
      v-row
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("начало") }}:
            div {{ shift.openTime | moment("DD-MM-YYYY") }}
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("время") }}:
            div {{ shift.openTime | moment("HH:mm:ss") }}
      v-row(v-if="shift.closeTime")
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("конец") }}:
            div {{ shift.closeTime | moment("DD-MM-YYYY") }}
        v-col(cols="6")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("время") }}:
            div {{ shift.closeTime | moment("HH:mm:ss") }}
      v-row(v-if="shift.shiftDocumentNumber")
        v-col(cols="12")
          div.d-flex.justify-space-between(style="flex: 1")
            div {{ $t("документ") }}:
            div {{ shift.shiftDocumentNumber }}

      v-divider.my-3

      v-row
        v-col
          .text-uppercase {{ $t("необнуляемая_сумма_на_начало_смены") }}
          v-list.mt-1
            v-list-item(v-for="(item, $index) in startShiftNonNullableSums" :key="'s_' + $index")
              v-list-item-content
                v-list-item-title {{ $t('operationType.' + item.operation) }}
              v-list-item-action
                v-list-item-action-text.body-1 {{ item.sum.value | numeral('0,0.00') | beautiful-minus }} ₸

      v-divider.my-3

      v-row.mt-2.mb-2(v-for="(ticketOperation, $index) in ticketOperations" :key="'t_' + $index")
        v-col
          .text-uppercase {{ $t('operationType.' + ticketOperation.operation) }}
          v-list
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("количество_чеков") }}
              v-list-item-action.flex-row-reverse
                v-list-item-action-text.body-1 {{ ticketOperation.ticketsCount | numeral('0,0') }}
            v-list-item(v-for="(payment, $index) in ticketOperation.payments" :key="'p_' + $index")
              v-list-item-content
                v-list-item-title {{ $t("paymentType." + payment.payment) }}
              v-list-item-action
                v-list-item-action-text.body-1 {{ payment.sum.value | numeral('0,0.00') | beautiful-minus }} ₸
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("сумма") }}
              v-list-item-action
                v-list-item-action-text.body-1 {{ ticketOperation.ticketsSum.value | numeral('0,0.00') | beautiful-minus }} ₸
            template(v-for="(tax, $index) in taxes")
              v-list-item(v-for="(operation, $index) in tax.operations" :key="'o_' + $index" v-if="operation.operation === ticketOperation.operation && operation.sum.value > 0")
                v-list-item-content
                  v-list-item-title {{ $t("в_т_ч_ндс") }} {{ tax.percent / 1000 }}%
                v-list-item-action
                  v-list-item-action-text.body-1 {{ operation.sum.value | numeral('0,0.00') | beautiful-minus }} ₸

      v-row.mt-2.mb-2(v-for="(moneyPlacement, $index) in moneyPlacements" :key="'m_' + $index" v-if="moneyPlacement.operationsCount > 0")
        v-col
          .text-uppercase {{ $t('operationType.' + moneyPlacement.operation) }}
          v-list
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("количество_чеков") }}
              v-list-item-action.flex-row-reverse
                v-list-item-action-text.body-1 {{ moneyPlacement.operationsCount | numeral('0,0') }}
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("сумма") }}
              v-list-item-action
                v-list-item-action-text.body-1 {{ moneyPlacement.operationsSum.value | numeral('0,0.00') | beautiful-minus }} ₸

      v-row
        v-col
          v-list
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("количество_чеков_за_смену") }}
              v-list-item-action.flex-row-reverse
                v-list-item-action-text.body-1 {{ shiftTotalTicketCount | numeral('0,0') }}

      v-divider.my-3

      v-row
        v-col
          .text-uppercase {{ $t("необнуляемая_сумма_на_конец_смены") }}
          v-list.mt-1
            v-list-item(v-for="(item, $index) in nonNullableSums" :key="'s_' + $index")
              v-list-item-content
                v-list-item-title {{ $t('operationType.' + item.operation) }}
              v-list-item-action
                v-list-item-action-text.body-1 {{ item.sum.value | numeral('0,0.00') | beautiful-minus }} ₸

      v-row.mt-2
        v-col
          .text-uppercase {{ $t("наличных_в_кассе") }}
          v-list.mt-1
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("сумма") }}
              v-list-item-action
                v-list-item-action-text.body-1 {{ cashSum | numeral('0,0.00') | beautiful-minus }} ₸

      v-divider.my-3

      v-row
        v-col.text-center
          div {{ $t(`fdo.${cashRegister.fdo}.title`) }}
          div
            span {{ $t(`fdo.${cashRegister.fdo}.url`) }}

  v-bottom-navigation(app dark)
    v-btn(v-if="$isCordova()" icon @click="shareSystem()" :loading="shareLoading" :disabled="loading")
      v-icon mdi-share-variant

    v-btn(icon @click="downloadReceipt()" :loading="downloadLoading" :disabled="loading")
      v-icon mdi-download

    v-btn(icon @click="print()" :loading="printingLoading" :disabled="loading")
      v-icon mdi-printer

  re-pinpad(v-model="closeShiftPinpadDialog" :title="$t('закрытие_смены')" :subtitle="$t('введите_пин-код')" :text="$t('для_закрытия_смены')" :loading="loading" :errorText.sync="closeShiftErrorMessage" @action="close")

</template>
<script>
import { mapState, mapActions } from 'vuex'
import html2canvas from 'html2canvas'
import download from 'downloadjs'
import i18n from '../../i18n/index'
import store from '../../store/index'
import Pinpad from '../utils/PinpadDialog.vue'
import dictionaryMixin from '../../mixins/dictionaryMixin'
import billsAndCoinsMixin from '../../mixins/billsAndCoinsMixin'

export default {

  components: {
    're-pinpad': Pinpad,
  },

  mixins: [dictionaryMixin, billsAndCoinsMixin],

  data: () => ({
    printingDialog: false,

    shareLoading: false,
    printingLoading: false,
    downloadLoading: false,

    shift: null,
    loading: false,

    closeShiftPinpadDialog: false,
    closeShiftErrorMessage: null,

    shiftNumber: null,
  }),

  // Change cashRegister if id not equal to to.params.cashRegisterId
  beforeRouteEnter(to, from, next) {
    if (store.state.cashRegisters.cashRegister.cashRegister.id !== Number(to.params.cashRegisterId)) {
      store.dispatch('cashRegisters/selectCashRegister', to.params.cashRegisterId).then(() => {
        next()
        store.dispatch('tools/showSnackbar', { message: `${i18n.t('вы_переключились_на_кассу')}: ${store.state.cashRegisters.cashRegister.organization.businessName} (${store.state.cashRegisters.cashRegister.cashRegister.serialNumber})`, timeout: 10000 })
      })
    } else {
      next()
    }
  },

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      printerSettings: state => state.printer.settings,
    }),

    report() {
      return this.shift.data
    },

    startShiftNonNullableSums() {
      return this.report.startShiftNonNullableSums.map((item) => {
        item.sum.value = this.getNumberFromBillsAndCoins(item.sum)
        return item
      }).sort(((a, b) => this.dictionary.operationType[a.operation].order - this.dictionary.operationType[b.operation].order))
    },

    nonNullableSums() {
      return this.report.nonNullableSums.map((item) => {
        item.sum.value = this.getNumberFromBillsAndCoins(item.sum)
        return item
      }).sort(((a, b) => this.dictionary.operationType[a.operation].order - this.dictionary.operationType[b.operation].order))
    },

    ticketOperations() {
      if (this.report.ticketOperations) {
        return this.report.ticketOperations.reduce((items, item) => {
          if (item.ticketsCount > 0) {
            item.ticketsSum.value = this.getNumberFromBillsAndCoins(item.ticketsSum)
            if (item.payments) {
              item.payments = item.payments.map((payment) => {
                payment.sum.value = this.getNumberFromBillsAndCoins(payment.sum)
                return payment
              }).sort(((a, b) => this.dictionary.paymentType[a.payment].order - this.dictionary.paymentType[b.payment].order))
            }
            items.push(item)
          }
          return items
        }, []).sort(((a, b) => this.dictionary.operationType[a.operation].order - this.dictionary.operationType[b.operation].order))
      }
      return []
    },

    moneyPlacements() {
      if (this.report.moneyPlacements) {
        return this.report.moneyPlacements.map((item) => {
          item.operationsSum.value = this.getNumberFromBillsAndCoins(item.operationsSum)
          return item
        }).sort(((a, b) => this.dictionary.operationType[a.operation].order - this.dictionary.operationType[b.operation].order))
      }
      return []
    },

    shiftTotalTicketCount() {
      const tickets = this.report.ticketOperations ? this.report.ticketOperations.reduce((sum, current) => sum + current.ticketsCount, 0) : 0
      const moneyPlacements = this.report.moneyPlacements ? this.report.moneyPlacements.reduce((sum, current) => sum + current.operationsCount, 0) : 0
      return tickets + moneyPlacements
    },

    taxes() {
      if (this.report.taxes) {
        return this.report.taxes.map((tax) => {
          tax.operations.map((operation) => {
            operation.sum.value = this.getNumberFromBillsAndCoins(operation.sum)
            return operation
          })
          return tax
        }).sort(((a, b) => a.percent - b.percent))
      }
      return []
    },

    cashSum() {
      return this.getNumberFromBillsAndCoins(this.report.cashSum)
    },

    rollWidthClass() {
      return this.printerSettings.rollWidth === 58 ? 'roll-width-58mm' : 'roll-width-80mm'
    },
  },

  created() {
    this.shiftNumber = this.$route.params.shiftNumber
    this.fetchReport()
  },

  methods: {
    ...mapActions({
      fetchXReport: 'cashRegisters/fetchXReport',
      fetchShiftByNumber: 'cashRegisters/fetchShiftByNumber',
      closeShift: 'cashRegisters/closeShift',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      printReceipt: 'printer/printReceipt',
      showSnackbar: 'tools/showSnackbar',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    localStorageGetItem(name) {
      return localStorage.getItem(name)
    },

    fetchReport() {
      this.loading = true
      if (this.shiftNumber === String(this.cashRegister.shiftNumber) && this.cashRegister.shiftOpen) {
        this.fetchXReport({ cashRegister: this.cashRegister, shiftNumber: this.shiftNumber }).then((result) => {
          this.shift = result.data
          this.shift.open = true
          this.shift.openTime = this.cashRegister.shift.openTime
          this.shift.ticketCount = this.cashRegister.shift.ticketCount
          this.loading = false
          if (localStorage.getItem('rekassa.kz-ui-ticketAutoScroll') === 'true') {
            setTimeout(() => {
              this.$vuetify.goTo(document.getElementsByClassName('receipt')[0].offsetHeight)
            }, 500)
          }
          this.analyticsLogEvent({ eventName: 're_shift_ran_x_report' })
        }).catch((error) => {
          if (error && error.response && error.response.status === 404) {
            this.showSnackbar({ message: this.$t('смена_не_существует') })
          }
          this.$router.push('/')
        })
      } else {
        this.fetchShiftByNumber({ cashRegister: this.cashRegister, shiftNumber: this.shiftNumber }).then((result) => {
          this.shift = result.data
          this.loading = false
          if (localStorage.getItem('rekassa.kz-ui-ticketAutoScroll') === 'true') {
            setTimeout(() => {
              this.$vuetify.goTo(document.getElementsByClassName('receipt')[0].offsetHeight)
            }, 500)
          }
          this.analyticsLogEvent({ eventName: 're_shift_ran_z_report' })
        }).catch((error) => {
          if (error && error.response && error.response.status === 404) {
            this.showSnackbar({ message: this.$t('смена_не_существует') })
          }
          this.$router.push('/')
        })
      }
    },

    showPinpad() {
      if (this.cashRegister.status !== 'TRIAL') {
        this.closeShiftPinpadDialog = true
      } else {
        this.close(null)
      }
    },

    close(pincode) {
      this.loading = true
      this.closeShift({ cashRegister: this.cashRegister, shiftNumber: this.shiftNumber, password: pincode }).then((result) => {
        this.selectCashRegister(this.cashRegister.id).then(() => {
          this.shift = result.data
          this.closeShiftPinpadDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('смена_№_успешно_закрыта', { number: this.shift.shiftNumber }) })
        }).catch(() => {
          this.closeShiftPinpadDialog = false
          this.loading = false
        })
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.closeShiftErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else {
          this.closeShiftPinpadDialog = false
        }
        this.loading = false
      })
    },

    shareSystem() {
      this.shareLoading = true
      setTimeout(() => {
        const receipt = document.getElementsByClassName('receipt')[0]
        html2canvas(receipt, {
          backgroundColor: '#ffffff',
          y: 50,
          scale: 2,
        }).then(canvas => {
          window.canvas2ImagePlugin.shareImageData(
            () => {
              this.shareLoading = false
            },
            (error) => {
              this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
              this.shareLoading = false
            },
            canvas,
            80,
          )
        }, 0)
      })
    },

    downloadReceipt() {
      this.downloadLoading = true
      setTimeout(() => {
        const receipt = document.getElementsByClassName('receipt')[0]
        html2canvas(receipt, {
          backgroundColor: '#ffffff',
          y: 50,
          scale: 2,
        }).then(canvas => {
          if (this.$isCordova()) {
            window.canvas2ImagePlugin.saveImageDataToLibrary(
              () => {
                this.showSnackbar({ message: this.$t('чек_успешно_сохранен_в_галерее') })
                this.downloadLoading = false
              },
              (error) => {
                this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
                this.downloadLoading = false
              },
              canvas,
              `${this.$t('смена')} №${this.shift.shiftNumber} ${this.shift.open ? 'X' : 'Z'}-${this.$t('отчёт')}`,
              80,
              `reKassa_${this.cashRegister.serialNumber}`,
            )
          } else {
            download(canvas.toDataURL('image/png'), `${this.cashRegister.serialNumber} ${this.$t('смена')} №${this.shift.shiftNumber} ${this.shift.open ? 'X' : 'Z'}-${this.$t('отчёт')}`)
            this.downloadLoading = false
          }
        }).catch((error) => {
          this.showSnackbar({ message: `${this.$t('не_удалось_cкачать_чек')}: ${error}` })
          this.downloadLoading = false
        })
      }, 0)
    },

    print() {
      if (this.$isCordova()) {
        this.printCordova()
      } else {
        this.printWeb()
      }
    },

    printWeb() {
      window.print()
    },

    printCordova() {
      this.printingLoading = true

      const printCmds = []
      printCmds.push('#align_center#')
      printCmds.push(this.organization.businessName)
      printCmds.push('#new_line#')

      printCmds.push(`${this.$t('бин_иин')} ${this.organization.businessId}`)
      printCmds.push('#new_line#')

      printCmds.push(this.cashRegister.pos.address)
      printCmds.push('#new_line#')

      printCmds.push('#align_left#')

      printCmds.push(this.$t('рнм'))
      printCmds.push('#full_width#')
      printCmds.push(this.cashRegister.registrationNumber)
      printCmds.push('#new_line#')

      printCmds.push(this.$t('знм'))
      printCmds.push('#full_width#')
      printCmds.push(this.cashRegister.serialNumber)
      printCmds.push('#new_line#')

      printCmds.push(this.$t('ккм'))
      printCmds.push('#full_width#')
      printCmds.push(this.cashRegister.model)
      printCmds.push('#new_line#')

      printCmds.push('#align_center#')
      printCmds.push(`${this.shift.open ? 'X' : 'Z'}-${this.$t('отчёт')}`)
      printCmds.push('#new_line#')

      printCmds.push('#align_left#')

      if (this.shift.offline) {
        printCmds.push(this.$t('автономный').toUpperCase())
        printCmds.push('#new_line#')
      }

      if (this.shift.operator && this.shift.operator.name) {
        printCmds.push(`${this.$t('смена')} `)
        printCmds.push('#full_width#')
        printCmds.push(`#${this.shift.shiftNumber}`)
        printCmds.push('#new_line#')

        printCmds.push(`${this.$t('кассир')} `)
        printCmds.push('#full_width#')
        printCmds.push(this.shift.operator.name)
        printCmds.push('#new_line#')
      } else {
        printCmds.push(`${this.$t('смена')} `)
        printCmds.push('#half_line#')
        printCmds.push(`#${this.shift.shiftNumber}`)
        printCmds.push(`${this.$t('кассир')} `)
        printCmds.push('#half_line#')
        printCmds.push(this.shift.operator ? this.shift.operator.code : '')
        printCmds.push('#new_line#')
      }

      printCmds.push(`${this.printerSettings.rollWidth === 58 ? `${this.$t('начало_short')} ` : `${this.$t('начало')} `}`)
      printCmds.push('#half_line#')
      printCmds.push(this.$moment(this.shift.openTime).format('DD-MM-YYYY'))
      printCmds.push(`${this.$t('время')} `)
      printCmds.push('#half_line#')
      printCmds.push(this.$moment(this.shift.openTime).format('HH:mm:ss'))
      printCmds.push('#new_line#')

      if (this.shift.closeTime) {
        printCmds.push(`${this.printerSettings.rollWidth === 58 ? `${this.$t('конец_short')} ` : `${this.$t('конец')} `}`)
        printCmds.push('#half_line#')
        printCmds.push(this.$moment(this.shift.closeTime).format('DD-MM-YYYY'))
        printCmds.push(`${this.$t('время')} `)
        printCmds.push('#half_line#')
        printCmds.push(this.$moment(this.shift.closeTime).format('HH:mm:ss'))
        printCmds.push('#new_line#')
      }

      if (this.shift.shiftDocumentNumber) {
        printCmds.push(this.$t('документ'))
        printCmds.push('#full_width#')
        printCmds.push(this.shift.shiftDocumentNumber)
        printCmds.push('#new_line#')
      }

      printCmds.push('#line#')

      printCmds.push(this.$t('необнуляемая_сумма_на_начало_смены'))
      printCmds.push('#new_line#')

      for (let index = 0; index < this.startShiftNonNullableSums.length; index += 1) {
        const item = this.startShiftNonNullableSums[index]
        printCmds.push(this.$t(`operationType.${item.operation}`))
        printCmds.push('#full_width#')
        printCmds.push(`${this.$options.filters.numeral(item.sum.value, '0,0.00')}₸`)
        printCmds.push('#new_line#')
      }

      printCmds.push('#line#')

      for (let index = 0; index < this.ticketOperations.length; index += 1) {
        const ticketOperation = this.ticketOperations[index]
        printCmds.push(this.$t(`operationType.${ticketOperation.operation}`).toUpperCase())
        printCmds.push('#new_line#')

        printCmds.push(this.$t('кол-во_чеков'))
        printCmds.push('#full_width#')
        printCmds.push(this.$options.filters.numeral(ticketOperation.ticketsCount, '0,0'))
        printCmds.push('#new_line#')

        for (let index2 = 0; index2 < ticketOperation.payments.length; index2 += 1) {
          const payment = ticketOperation.payments[index2]
          printCmds.push(this.$t(`paymentType.${payment.payment}`))
          printCmds.push('#full_width#')
          printCmds.push(`${this.$options.filters.numeral(payment.sum.value, '0,0.00')}₸`)
          printCmds.push('#new_line#')
        }

        printCmds.push(this.$t('сумма'))
        printCmds.push('#full_width#')
        printCmds.push(`${this.$options.filters.numeral(ticketOperation.ticketsSum.value, '0,0.00')}₸`)
        printCmds.push('#new_line#')

        for (let index2 = 0; index2 < this.taxes.length; index2 += 1) {
          const tax = this.taxes[index2]
          for (let index3 = 0; index3 < tax.operations.length; index3 += 1) {
            const operation = tax.operations[index3]
            if (operation.operation === ticketOperation.operation && operation.sum.value > 0) {
              printCmds.push(`${this.$t('в_т_ч_ндс')} ${tax.percent / 1000}%`)
              printCmds.push('#full_width#')
              printCmds.push(`${this.$options.filters.numeral(operation.sum.value, '0,0.00')}₸`)
              printCmds.push('#new_line#')
            }
          }
        }
      }

      for (let index = 0; index < this.moneyPlacements.length; index += 1) {
        const moneyPlacement = this.moneyPlacements[index]
        if (moneyPlacement.operationsCount > 0) {
          printCmds.push(this.$t(`operationType.${moneyPlacement.operation}`).toUpperCase())
          printCmds.push('#new_line#')

          printCmds.push(this.$t('кол-во_чеков'))
          printCmds.push('#full_width#')
          printCmds.push(this.$options.filters.numeral(moneyPlacement.operationsCount, '0,0'))
          printCmds.push('#new_line#')

          printCmds.push(this.$t('сумма'))
          printCmds.push('#full_width#')
          printCmds.push(`${this.$options.filters.numeral(moneyPlacement.operationsSum.value, '0,0.00')}₸`)
          printCmds.push('#new_line#')
        }
      }

      printCmds.push(this.$t('количество_чеков_за_смену'))
      printCmds.push('#full_width#')
      printCmds.push(this.$options.filters.numeral(this.shiftTotalTicketCount, '0,0'))
      printCmds.push('#new_line#')

      printCmds.push('#line#')

      printCmds.push(this.$t('необнуляемая_сумма_на_конец_смены'))
      printCmds.push('#new_line#')

      for (let index = 0; index < this.nonNullableSums.length; index += 1) {
        const item = this.nonNullableSums[index]
        printCmds.push(this.$t(`operationType.${item.operation}`))
        printCmds.push('#full_width#')
        printCmds.push(`${this.$options.filters.numeral(item.sum.value, '0,0.00')}₸`)
        printCmds.push('#new_line#')
      }

      printCmds.push(this.$t('наличных_в_кассе').toUpperCase())
      printCmds.push('#new_line#')

      printCmds.push(this.$t('сумма'))
      printCmds.push('#full_width#')
      printCmds.push(`${this.$options.filters.numeral(this.cashSum, '0,0.00')}₸`)
      printCmds.push('#new_line#')

      printCmds.push('#line#')
      printCmds.push('#align_center#')

      printCmds.push(this.$t(`fdo.${this.cashRegister.fdo}.title`))
      printCmds.push('#new_line#')
      printCmds.push(this.$t(`fdo.${this.cashRegister.fdo}.url`))
      printCmds.push('#new_line#')

      const payload = {
        cmds: printCmds,
        qrCode: null,
      }

      this.printReceipt(payload).then(() => {
        setTimeout(() => { this.printingLoading = false }, 500)
      }, () => {
        setTimeout(() => { this.printingLoading = false }, 500)
      })
    },
  },
}
</script>

<style lang="stylus">
</style>
