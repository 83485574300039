import i18n from '../../i18n/index'
import $http from '../../utils/http'

const positions = {
  namespaced: true,

  state: {
    list: [],
    loading: false,
  },

  getters: {
  },

  mutations: {
    set(state, { type, value }) {
      state[type] = value
    },
  },

  actions: {
    async add({ }, payload) {
      return $http.post(`/inventory/crs/${payload.cashRegisterId}/positions`, payload.data)
    },

    async addAll({ }, payload) {
      return $http.post(`/inventory/crs/${payload.cashRegisterId}/positions/bulk`, payload.data)
    },

    async update({ }, payload) {
      return $http.put(`/inventory/crs/${payload.cashRegisterId}/positions/${payload.id}`, payload.data)
    },

    async delete({ }, payload) {
      return $http.delete(`/inventory/crs/${payload.cashRegisterId}/positions/${payload.id}`)
    },

    async deleteAll({ }, cashRegisterId) {
      return $http.delete(`/inventory/crs/${cashRegisterId}/positions`)
    },

    async fetch({ commit, dispatch }, cashRegisterId) {
      commit('set', { type: 'loading', value: true })
      return new Promise((resolve, reject) => {
        $http.get(`/inventory/crs/${cashRegisterId}/positions?page=0&size=${process.env.VUE_APP_POSITIONS_LIMIT}`).then((result) => {
          if (result.data && result.data._embedded && result.data._embedded.positions) {
            const list = result.data._embedded.positions.map((item) => ({
              id: item.id, name: item.data.name, price: item.data.price, unitType: item.data.unitType, barcode: item.data.barcode,
            })).sort((a, b) => {
              const nameA = a.name.toLowerCase()
              const nameB = b.name.toLowerCase()
              if (nameA < nameB) {
                return -1
              }
              if (nameA > nameB) {
                return 1
              }
              return 0
            })
            commit('set', { type: 'list', value: list })
          } else {
            commit('set', { type: 'list', value: [] })
          }
          commit('set', { type: 'loading', value: false })
          resolve()
        }).catch((error) => {
          dispatch('tools/showSnackbar', { message: `${i18n.t('произошла_ошибка')}: ${error}` }, { root: true })
          commit('set', { type: 'loading', value: false })
          reject()
        })
      })
    },

    clear({ commit }) {
      commit('set', { type: 'list', value: [] })
    },
  },
}

export default positions
