<template lang="pug">
v-app
  v-app-bar(app dark dense flat)
    v-btn(icon @click="$router.push('/services')")
      v-icon mdi-arrow-left
    v-toolbar-title {{ $t("FREEDOM_INSURANCE") }}

  v-main(flat)
    v-container.pt-0(style="max-width: 450px")
      v-row
        v-col.pa-0.pt-sm-3(align="center")
          v-img(:src="`/static/freedom-insurance-casco-banner-${locale}.png`" width="100%" eager)

      v-row
        v-col
          .display-1.my-2 {{ $t("FREEDOM_INSURANCE") }}

      v-row
        v-col.pt-0
          v-list.v-list--fulltext.gray-background-list.rounded-t-lg.rounded-b-lg
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-brightness-percent
              v-list-item-content
                v-list-item-title {{ $t("3_минуты") }}
                v-list-item-subtitle {{ $t("на_онлайн_оформление") }}
            v-divider
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-calendar
              v-list-item-content
                v-list-item-title {{ $t("ежемесячная_оплата") }}
                v-list-item-subtitle {{ $t("без_скрытых_комиссий_и_доплат") }}
            v-divider
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-credit-card-outline
              v-list-item-content
                v-list-item-title {{ $t("гибкие_тарифы") }}
                v-list-item-subtitle {{ $t("страховая_защита_на_всё_от_царапин_до_угона") }}

      v-row
        v-col
          .body-2 {{ $t("можно_не_платить_всю_сумму_сразу_оплачивайте_раз_в_месяц_по_подписке_или_как_обычно_раз_в_год") }}
          .body-2.mt-4 {{ $t("быстрые_выплаты_в_течение_5_рабочих_дней_с_момента_подачи_последнего_документа") }}
          .body-2.mt-4 {{ $t("оставьте_свои_данные_чтобы_узнать_сколько_будет_стоить_каско_на_ваше_авто") }}

      v-row
        v-col.pb-0.pt-4
          .subtitle-2 {{ $t("оставить_заявку") }}

      v-row
        v-col
          v-form(ref="form")
            v-text-field(ref="iinInput" :label="$t('иин')" v-model="iin" @input="iinError = null" :error-messages="iinError" v-mask="'### ### ### ###'" placeholder="### ### ### ###" type="tel" clearable)
            v-text-field(:label="$t('госномер')" v-model="stateNumber" @input="stateNumberError = null" :error-messages="stateNumberError" maxlength="10" clearable)
            v-text-field(:label="$t('номер_телефона')" v-model="phoneNumber" @input="phoneNumberError = null" :error-messages="phoneNumberError" v-mask="'+7 (###) ###-##-##'" placeholder="+7 (###) ###-##-##" type="tel" clearable)

            v-btn.mt-4(color="primary" block @click="submitApplication()") {{ $t("продолжить") }}
</template>
<script>
import axios from 'axios'
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../../i18n'

export default {
  data: () => ({
    iin: null,
    iinError: null,
    stateNumber: null,
    stateNumberError: null,
    phoneNumber: null,
    phoneNumberError: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
    }),

    locale() {
      return getLocale()
    },
  },

  created() {
    if (this.cashRegister.status !== 'TRIAL') {
      this.iin = this.organization.businessId
    }
    this.phoneNumber = this.user.phone
  },

  methods: {
    ...mapActions({
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    submitApplication() {
      if (this.iin?.length !== 15) {
        this.iinError = this.$t('введите_иин')
        return
      }
      if (this.stateNumber === null || this.stateNumber.length === 0) {
        this.stateNumberError = this.$t('введите_госномер')
        return
      }
      if (this.phoneNumber?.length !== 18) {
        this.phoneNumberError = this.$t('введите_номер_телефона')
        return
      }

      const url = 'https://api.sheetson.com/v2/sheets/freedomInsurance'
      const data = {
        iin: this.iin.replace(/\D/g, ''),
        stateNumber: this.stateNumber.replace(/\D/g, ''),
        phone: this.phoneNumber.replace(/\D/g, ''),
        date: this.$moment().format('DD-MM-YYYY HH:mm:ss'),
      }
      const config = {
        headers: {
          Authorization: 'Bearer WL6r-WEd2IPRoiBnA80pMaQYA1UwLPOtJlKx_hOYOGs7UOK2kgVd_v2ErE0',
          'X-Spreadsheet-Id': process.env.VUE_APP_TEST === 'true' ? '1DYEuceqRNrc9t1G85WpKBzWZLdJZ5ePXzmx5ow5gQPE' : '16LVwBilRakcpi6GLld7qrV5TJTMcqRjyS4fQoVQyyns',
          'Content-Type': 'application/json',
        },
      }
      axios.post(url, data, config)
        .then((response) => {
          console.debug(response.data)
        })
        .catch((error) => {
          console.error(error)
        })

      this.$router.push('/services')
      this.showConfirm({
        title: this.$t('ваша_заявка_успешна_принята'),
        resolveText: this.$t('ok'),
      }).then(() => {})
        .catch(() => {})
      this.analyticsLogEvent({ eventName: 're_freedom_insurance_casco_form_submitted' })
    },

  },
}
</script>

<style lang="stylus">
</style>
