<template lang="pug">
</template>
<script>
import { mapState } from 'vuex'
// import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'

export default {
  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
    }),
  },

  mounted() {
    // if (this.cashRegister.status === 'TRIAL') {
    //   localStorage.setItem('rekassa.kz-whats-new-viewed', 'true')
    // }

    // if (localStorage.getItem('rekassa.kz-whats-new-viewed') !== 'true') {
    //   const driverObj = driver({
    //     animate: false,
    //     showProgress: false,
    //     allowClose: false,
    //     allowKeyboardControl: false,
    //     showButtons: ['next'],
    //     nextBtnText: this.$t('далее').toUpperCase(),
    //     doneBtnText: 'ОК',
    //     popoverClass: 'driverjs-theme',
    //     steps: [
    //       { popover: { title: this.$t('') } },
    //       {
    //         element: '#cashRegisterWidget',
    //         popover: {
    //           description: this.$t(''), side: this.$vuetify.breakpoint.mdAndUp ? 'bottom' : 'top',
    //         },
    //       },
    //       {
    //         element: '#servicesWidget',
    //         popover: {
    //           description: this.$t(''), side: this.$vuetify.breakpoint.mdAndUp ? 'bottom' : 'top',
    //         },
    //       },
    //       {
    //         element: '#shiftsNavBtn',
    //         popover: {
    //           description: this.$t(''), side: this.$vuetify.breakpoint.mdAndUp ? 'bottom' : 'top',
    //         },
    //       },
    //       {
    //         element: '#kkmNavBtn',
    //         popover: {
    //           description: this.$t(''), side: this.$vuetify.breakpoint.mdAndUp ? 'bottom' : 'top',
    //         },
    //       },
    //       {
    //         element: '#messagesNavBtn',
    //         popover: {
    //           description: this.$t(''), side: this.$vuetify.breakpoint.mdAndUp ? 'bottom' : 'top',
    //         },
    //       },
    //       {
    //         element: '#settingsNavBtn',
    //         popover: {
    //           description: this.$t(''), side: this.$vuetify.breakpoint.mdAndUp ? 'bottom' : 'top',
    //         },
    //       },
    //     ],
    //   })
    //   driverObj.drive()
    //   localStorage.setItem('rekassa.kz-whats-new-viewed', 'true')
    // }
  },
}
</script>
<style lang="stylus">
.driver-popover.driverjs-theme {
  background-color: #ffffff;
  color: #000;
  max-width: 200px;
}

.driver-popover.driverjs-theme .driver-popover-title {
  font-size: 20px;
}

.driver-popover.driverjs-theme .driver-popover-title,
.driver-popover.driverjs-theme .driver-popover-description,
.driver-popover.driverjs-theme .driver-popover-progress-text {
  color: #000;
  font-family: "Roboto Condensed", sans-serif !important;
}

.driver-popover.driverjs-theme button {
  flex: 1;
  text-align: center;
  background-color: #20BD98;
  color: #ffffff;
  border: 2px solid #20BD98;
  text-shadow: none;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 8px;
  border-radius: 6px;
}

.driver-popover.driverjs-theme button:hover {
  background-color: #20BD98;
  color: #ffffff;
}

.driver-popover.driverjs-theme .driver-popover-navigation-btns {
  justify-content: space-between;
  gap: 3px;
}

.driver-popover.driverjs-theme .driver-popover-close-btn {
  color: #9b9b9b;
}

.driver-popover.driverjs-theme .driver-popover-close-btn:hover {
  color: #000;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: #ffffff;
  display: none;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: #ffffff;
  display: none;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: #ffffff;
  display: none;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: #ffffff;
  display: none;
}
</style>
