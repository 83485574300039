<template lang="pug">
  v-dialog(v-model="showDialog" transition="false" width="500")
    v-card(id="scandit" style="height: 100vw;")

</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data: () => ({
    showDialog: false,
    barcodeCapture: null,
    view: null,
    camera: null,
  }),

  computed: {
    ...mapState({
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
    }),
  },

  watch: {
    value(value) {
      if (value) {
        this.showDialog = true
        this.$nextTick(() => {
          this.init()
        })
      }
    },

    showDialog(value) {
      if (!value) {
        this.$emit('input', false)
        this.barcodeCapture.isEnabled = false
        this.camera.switchToDesiredState(window.Scandit.FrameSourceState.Off)
        this.view.detachFromElement()
      }
    },
  },

  methods: {
    ...mapActions({
      analyticsLogEvent: 'analytics/logEvent',
      showSnackbar: 'tools/showSnackbar',
    }),

    init() {
      const context = window.Scandit.DataCaptureContext.forLicenseKey(process.env.VUE_APP_SCANDIT_KEY)

      const cameraSettings = window.Scandit.BarcodeCapture.recommendedCameraSettings
      this.camera = window.Scandit.Camera.default
      if (this.camera) {
        this.camera.applySettings(cameraSettings)
      }

      context.setFrameSource(this.camera)

      const settings = new window.Scandit.BarcodeCaptureSettings()
      settings.enableSymbologies([
        window.Scandit.Symbology.DataMatrix,
      ])
      const symbologySettings = settings.settingsForSymbology(window.Scandit.Symbology.DataMatrix)
      symbologySettings.isColorInvertedEnabled = true

      this.barcodeCapture = window.Scandit.BarcodeCapture.forContext(context, settings)

      this.barcodeCapture.addListener({
        didScan: (barcodeCapture, session) => {
          const barcode = session.newlyRecognizedBarcodes[0]

          if (this.preferences.exciseStampType === 'CIGARETTES') {
            this.$emit('exciseStampScanned', `${barcode._data}`.substring(0, 21))
            this.analyticsLogEvent({ eventName: 're_datamatrix_scandit_scanned_cigarettes' })
          // SHOES, MEDICINE
          } else if (this.preferences.exciseStampType === 'GROUP_A') {
            this.$emit('exciseStampScanned', `${barcode._data}`.replaceAll('\u001d', '').substring(0, 31))
            this.analyticsLogEvent({ eventName: 're_datamatrix_scandit_scanned_group_a' })
          }

          barcodeCapture.isEnabled = false
          this.camera.switchToDesiredState(window.Scandit.FrameSourceState.Off)
          this.view.detachFromElement()
          this.showDialog = false
        },
      })

      this.view = window.Scandit.DataCaptureView.forContext(context)
      this.view.connectToElement(document.getElementById('scandit'))

      const overlay = window.Scandit.BarcodeCaptureOverlay
        .withBarcodeCaptureForViewWithStyle(this.barcodeCapture, this.view, window.Scandit.BarcodeCaptureOverlayStyle.Frame)
      overlay.viewfinder = new window.Scandit.RectangularViewfinder(
        window.Scandit.RectangularViewfinderStyle.Square,
        window.Scandit.RectangularViewfinderLineStyle.Light,
      )
      overlay.viewfinder.dimming = 0.2
      if (this.preferences.exciseStampType === 'CIGARETTES') {
        overlay.viewfinder.setWidthAndAspectRatio(new window.Scandit.NumberWithUnit(0.2, window.Scandit.MeasureUnit.Fraction), 1)
      }

      this.camera.switchToDesiredState(window.Scandit.FrameSourceState.On)
      this.barcodeCapture.isEnabled = true
    },
  },
}
</script>
<style lang="stylus">
</style>
